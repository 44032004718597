import { fetchXbAccount, getXborderpayMerchant } from '@/services/api';

export default {
  namespace: 'xbAccount',
  state: {
    data: [],
    total: 0,
    merchantList: [],
  },

  effects: {
    *getXbAccount({ payload }, { call, put }) {
      const response = yield call(fetchXbAccount, payload);
      yield put({
        type: 'saveXbAccount',
        payload: response,
      });
    },
    *getMerchantList({ payload }, { call, put }) {
      const response = yield call(getXborderpayMerchant, payload);
      yield put({
        type: 'saveMerchantList',
        payload: response,
      });
    },
  },
  reducers: {
    saveXbAccount(state, action) {
      const {
        data = [],
        meta: { total = 0 },
      } = action.payload;
      return {
        ...state,
        data,
        total,
      };
    },
    saveMerchantList(state, action) {
      return {
        ...state,
        merchantList: action.payload || [],
      };
    },
  },
};
