import { fetchWpAccount, getWorldpayMerchant } from '@/services/api';

export default {
  namespace: 'wpAccount',
  state: {
    data: [],
    total: 0,
    merchantList: [],
  },

  effects: {
    *getWpAccount({ payload }, { call, put }) {
      const response = yield call(fetchWpAccount, payload);
      yield put({
        type: 'saveWpAccount',
        payload: response,
      });
    },
    *getMerchantList({ payload }, { call, put }) {
      const response = yield call(getWorldpayMerchant, payload);
      yield put({
        type: 'saveMerchantList',
        payload: response,
      });
    },
  },
  reducers: {
    saveWpAccount(state, action) {
      const {
        data = [],
        meta: { total = 0 },
      } = action.payload;
      return {
        ...state,
        data,
        total,
      };
    },
    saveMerchantList(state, action) {
      return {
        ...state,
        merchantList: action.payload || [],
      };
    },
  },
};
