import { fetchStockProduct, fetchStockProductType } from '@/services/sku';

export default {
  nameSpace: 'stockProduct',
  state: {
    data: [],
    total: 0,
    productType: [],
  },
  effects: {
    *getStockProduct({ payload, callback }, { call, put }) {
      const response = yield call(fetchStockProduct, payload);
      yield put({
        type: 'saveStockProduct',
        payload: response,
      });
      if (callback) callback();
    },
    *getProductType({ payload, callback }, { call, put }) {
      const response = yield call(fetchStockProductType, payload);
      yield put({
        type: 'saveProductType',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveStockProduct(state, action) {
      const { data = [], meta = {} } = action?.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
      };
    },
    saveProductType(state, action) {
      return {
        ...state,
        productType: Array.isArray(action?.payload?.data) ? action?.payload?.data : [],
      };
    },
  },
};
