import { fetchCountries } from '@/services/api';
import { fetchCountrySales } from '@/services/country_sales';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'countrySales',
  state: {
    data: [],
    totalOrderCount: 0,
    totalSalesQuantity: 0,
    totalSalesUsd: 0,
    countries: [],
    summary: {},
  },
  effects: {
    *getCountrySales({ payload, additionPayload }, { call, put }) {
      const {
        'filter[is_department]': is_department,
        'filter[country_code]': country_code,
        'filter[continent]': continent,
      } = payload;
      const response = yield call(fetchCountrySales, {
        ...payload,
        'filter[country_code]': is_department == 0 ? undefined : country_code,
        'filter[continent]': is_department == 0 ? undefined : continent,
      });
      yield put({
        type: 'saveCountrySales',
        payload: { ...response, querys: payload },
        additionPayload,
      });
    },
    *getCountries(_, { call, put }) {
      const response = yield call(fetchCountries);
      yield put({
        type: 'saveCountry',
        payload: response,
      });
    },
  },
  reducers: {
    saveCountrySales(state, { payload, additionPayload }) {
      const { data, meta, querys } = payload;
      const {
        'filter[country_code]': country_code,
        'filter[continent]': continent,
        'filter[is_department]': is_department,
      } = querys;
      let clone_list = data;
      if (is_department == 0 && (country_code?.length || continent?.length)) {
        const filters = country_code ?? continent;
        clone_list = data?.filter(item => {
          if (filters?.split(',')?.includes(item.country_code ?? item.continent)) {
            return true;
          }
          const { countryCodeName, continentCodeName } = additionPayload;
          return (
            countryCodeName?.includes?.(item.country_sn) ||
            continentCodeName?.includes?.(item.continent_sn)
          );
        });
      }
      return {
        ...state,
        data: clone_list?.map(i => ({ ...i, uniqueId: generateUuid() })) || [],
        summary: meta,
      };
    },
    saveCountry(state, action) {
      return {
        ...state,
        countries: action.payload,
      };
    },
  },
};
