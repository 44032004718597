import { getProfitWithPart, refreshProfitData, fetchStatisticalExportJob } from '@/services/api';
import { sortByStr, generateUuid } from '@/utils/utils';

export default {
  namespace: 'getprofit',
  state: {
    totalData: [],
    data: [],
    exportData: [],
    testData: [],
  },

  effects: {
    *getProfit({ payload }, { call, put }) {
      const response = yield call(getProfitWithPart, payload);
      yield put({
        type: 'saveProfit',
        payload: response,
      });
    },
    *updateProfit({ payload, callback }, { call }) {
      const response = yield call(refreshProfitData, payload);
      if (callback) callback(response);
    },
    *getExportJob({ payload }, { call, put }) {
      const response = yield call(fetchStatisticalExportJob, payload);
      yield put({
        type: 'saveExportJob',
        payload: response,
      });
    },
  },
  reducers: {
    saveProfit(state, action) {
      const {
        payload: { total_data: totalData, data = [] },
      } = action;
      data?.sort(sortByStr('user_name')); //eslint-disable-line
      for (let i = 0; i < data?.length; i += 1) {
        data[i].id = generateUuid();
      }
      return {
        ...state,
        totalData: totalData ? [totalData] : [],
        testData: action?.payload?.test_data ? [{ ...action?.payload?.test_data, id: 1 }] : [],
        data: data || [],
      };
    },
    saveExportJob(state, action) {
      const { meta: { current_page: currentPage, total } = {}, data = [] } = action.payload || {};
      return {
        ...state,
        exportData: {
          meta: {
            currentPage,
            total,
          },
          data,
        },
      };
    },
  },
};
