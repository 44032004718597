import {
  addActDomain,
  deleteActDomain,
  fetchActDomain,
  fetchStore,
  updateActDomain,
  fetchBmAdmin,
  fetchPartPpList,
} from '@/services/api';

export default {
  namespace: 'management',

  state: {
    actDomainList: [],
    currentPage: 1,
    lastPage: 1,
    total: 1,
    vendors: [],
    domains: [],
    parts: [],
    usernames: [],
    bmAdmin: [],
    pplist: [],
  },

  effects: {
    *getActDomain({ payload, callback }, { call, put }) {
      const response = yield call(fetchActDomain, payload);
      yield put({
        type: 'saveActDomain',
        payload: response,
      });
      if (callback) callback();
    },
    *getStores({ payload, callback }, { call, put }) {
      const response = yield call(fetchStore, payload);
      yield put({
        type: 'saveStores',
        payload: response,
      });
      if (callback) callback();
    },
    *editActDomain({ payload, callback }, { call }) {
      const response = yield call(updateActDomain, payload);
      if (callback) callback(response);
    },
    *deleteActDomain({ payload, callback }, { call }) {
      const response = yield call(deleteActDomain, payload);
      if (callback) callback(response);
    },
    *addActDomain({ payload, callback }, { call }) {
      const response = yield call(addActDomain, payload);
      if (callback) callback(response);
    },
    *getBmAdmin({ payload }, { call, put }) {
      const response = yield call(fetchBmAdmin, payload);
      yield put({
        type: 'saveBmAdmin',
        payload: response,
      });
    },
    *fetchPartPpList(_, { call, put }) {
      const response = yield call(fetchPartPpList);
      yield put({
        type: 'savePpList',
        payload: response,
      });
    },
  },
  reducers: {
    saveActDomain(state, action) {
      const { data, current_page: currentPage, lastPage, total } = action.payload;
      return {
        ...state,
        actDomainList: data,
        currentPage,
        lastPage,
        total,
      };
    },
    saveStores(state, action) {
      const { vendors, domains, parts, usernames } = action.payload;
      return {
        ...state,
        vendors,
        domains,
        parts,
        usernames,
      };
    },
    saveBmAdmin(state, action) {
      return {
        ...state,
        bmAdmin: action?.payload || [],
      };
    },
    savePpList(state, { payload }) {
      return {
        ...state,
        pplist: payload,
      };
    },
  },
};
