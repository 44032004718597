import {
  fetchApproveReport,
  fetchAppealReport,
  fetchBmChange,
  fetchBmEmailApply,
  fetchAssetConsumption,
  fetchAssetConsumptionDetail,
} from '@/services/api';

export default {
  namespace: 'statistic',

  state: {
    approve: {
      total: [],
      meta: {},
    },
    appeal: {
      total: [],
      meta: {},
    },
    bmChange: [],
    bmEmailApply: [],
    consumption: {
      total: {},
      data: [],
    },
    consumptionDetail: [],
  },

  effects: {
    *getApproveReport({ payload, callback }, { call, put }) {
      const response = yield call(fetchApproveReport, payload);
      yield put({
        type: 'saveApproveReport',
        payload: response,
      });
      if (callback) callback();
    },
    *getAppealReport({ payload, callback }, { call, put }) {
      const response = yield call(fetchAppealReport, payload);
      yield put({
        type: 'saveAppealReport',
        payload: response,
      });
      if (callback) callback();
    },
    *getBmChange({ payload, callback }, { call, put }) {
      const response = yield call(fetchBmChange, payload);
      yield put({
        type: 'saveBmChange',
        payload: response,
      });
      if (callback) callback();
    },
    *getBmEmailApply({ payload, callback }, { call, put }) {
      const response = yield call(fetchBmEmailApply, payload);
      yield put({
        type: 'saveBmEmailApply',
        payload: response,
      });
      if (callback) callback();
    },
    *getAssetConsumption({ payload, callback }, { call, put }) {
      const response = yield call(fetchAssetConsumption, payload);
      yield put({
        type: 'saveAssetConsumption',
        payload: response,
      });
      if (callback) callback();
    },
    *getConsumptionDetail({ payload, callback }, { call, put }) {
      const response = yield call(fetchAssetConsumptionDetail, payload);
      yield put({
        type: 'saveConsumptionDetail',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveApproveReport(state, action) {
      const { data = [], total_data: total = {} } = action.payload || {};
      return {
        ...state,
        approve: {
          data,
          total,
        },
      };
    },
    saveAppealReport(state, action) {
      const { data = [], total_data: total = {} } = action.payload || {};
      return {
        ...state,
        appeal: {
          data,
          total,
        },
      };
    },
    saveBmChange(state, action) {
      const { data = [], total_data: total = {} } = action.payload || {};
      return {
        ...state,
        bmChange: {
          data,
          total,
        },
      };
    },
    saveBmEmailApply(state, action) {
      const { data = [], total_data: total = {} } = action.payload || {};
      return {
        ...state,
        bmEmailApply: {
          data,
          total,
        },
      };
    },
    saveAssetConsumption(state, action) {
      const { data = [], total = {} } = action.payload || {};
      return {
        ...state,
        consumption: {
          data,
          total,
        },
      };
    },
    saveConsumptionDetail(state, action) {
      return {
        ...state,
        consumptionDetail: action.payload || [],
      };
    },
    clearConsumptionDetail(state) {
      return {
        ...state,
        consumptionDetail: [],
      };
    },
  },
};
