import {
  fetchPropertyAssessment,
  fetchInventoryDetail,
  fetchInventoryCompleted,
  fetchInventories,
} from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'propertyAssessment',

  state: {
    data: [],
    total: 0,
    detail: [],
    detailTotal: 0,
    inventoryCompleted: false,
    completedDate: [],
    completedTotal: 0,
    hasCompletedCount: 0,
    hasNoCompletedCount: 0,
  },

  effects: {
    *getPropertyAssessment({ payload }, { call, put }) {
      const response = yield call(fetchPropertyAssessment, payload);
      yield put({
        type: 'savePropertyAssessment',
        payload: response,
      });
    },
    *getInventoryDetail({ payload }, { call, put }) {
      let response = {};
      const tempPayload = payload;
      const fetch = tempPayload?.operate === 'inventory' ? fetchInventoryDetail : fetchInventories;
      delete tempPayload.operate;

      response = yield call(fetch, tempPayload);

      yield put({
        type: 'saveInventoryDetail',
        payload: { response, inventoryType: payload?.inventory_type },
      });
    },
    *getInventoryCompleted({ payload }, { call, put }) {
      const response = yield call(fetchInventoryCompleted, payload);
      yield put({
        type: 'saveInventoryCompleted',
        payload: response,
      });
    },
  },
  reducers: {
    savePropertyAssessment(state, action) {
      const { data, meta } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
      };
    },
    saveInventoryDetail(state, action) {
      const {
        response: { data, meta, inventory_completed: inventoryCompleted },
        inventoryType,
      } = action.payload;
      return {
        ...state,
        detail: data.map(i => ({ ...i, inventoryTypeAndId: inventoryType + i.id })),
        detailTotal: meta?.total || 0,
        inventoryCompleted,
      };
    },
    saveInventoryCompleted(state, action) {
      const {
        data,
        meta,
        has_inventory_people_count: hasCompletedCount = 0,
        not_complete_inventory_people_count: hasNoCompletedCount = 0,
      } = action.payload;
      return {
        ...state,
        completedDate: data?.map(i => ({ ...i, uniqueId: generateUuid() })),
        completedTotal: meta?.total || 0,
        hasCompletedCount,
        hasNoCompletedCount,
      };
    },
  },
};
