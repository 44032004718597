import { fetchWshopAddCart } from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'wshopAddCart',

  state: {
    meta: {},
    data: [],
    checkAll: true,
    columnsSelected: [
      {
        key: 'store',
        selected: true,
      },
      {
        key: 'time',
        selected: true,
      },
      {
        key: 'session_id',
        selected: true,
      },
      {
        key: 'uv_products',
        selected: true,
      },
      {
        key: 'session_count',
        selected: true,
      },
      {
        key: 'ec_ck_count',
        selected: true,
      },
      {
        key: 'normal_ck_count',
        selected: true,
      },
      {
        key: 'ec_ck_succ_count',
        selected: true,
      },
      {
        key: 'normal_ck_succ_count',
        selected: true,
      },
      {
        key: 'address_step_count',
        selected: true,
      },
      {
        key: 'contact_count',
        selected: true,
      },
      {
        key: 'shipping_step_count',
        selected: true,
      },
      {
        key: 'shipping_count',
        selected: true,
      },
      {
        key: 'payment_step_count',
        selected: true,
      },
      {
        key: 'payment_count',
        selected: true,
      },
      {
        key: 'complete_step_count',
        selected: true,
      },
      {
        key: 'payed_step_count',
        selected: true,
      },
      {
        key: 'order_conversion',
        selected: true,
      },
      {
        key: 'add_cart_conversion',
        selected: true,
      },
    ],
  },

  effects: {
    *getWshopAddCart({ payload, callback }, { call, put }) {
      const response = yield call(fetchWshopAddCart, payload);
      yield put({
        type: 'saveWshopAddCart',
        payload: response,
      });
      if (callback) callback();
    },
  },

  reducers: {
    saveWshopAddCart(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        data: data.map(i => ({ ...i, uuid: generateUuid() })),
        meta,
      };
    },
    saveColumns(state, action) {
      const { columnsSelected = [] } = action.payload || {};
      return {
        ...state,
        columnsSelected,
      };
    },
    saveCheckAll(state, action) {
      const { checkAll = false } = action.payload || {};
      return {
        ...state,
        checkAll,
      };
    },
  },
};
