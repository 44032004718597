import { fetchShopTicket, fetchWorkOrderDomain, fetchSpecificStore } from '@/services/api';

export default {
  namespace: 'shopTicket',
  state: {
    data: [],
    stores: [],
    specificStore: {},
    total: 0,
  },

  effects: {
    *getShopTicket({ payload }, { call, put }) {
      const response = yield call(fetchShopTicket, payload);
      yield put({
        type: 'saveShopTicket',
        payload: response,
      });
    },
    *getShopTicketStores({ payload }, { call, put }) {
      const response = yield call(fetchWorkOrderDomain, payload);
      yield put({
        type: 'saveShopTicketStores',
        payload: response,
      });
    },
    *getSpecificStore({ payload, callback }, { call, put }) {
      const response = yield call(fetchSpecificStore, payload);
      yield put({
        type: 'saveSpecificStore',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveShopTicket(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
      };
    },
    saveShopTicketStores(state, action) {
      const { data = [] } = action.payload;
      return {
        ...state,
        stores: data,
      };
    },
    saveSpecificStore(state, action) {
      const { data = {} } = action.payload;
      return {
        ...state,
        specificStore: data,
      };
    },
  },
};
