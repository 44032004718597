import { fetchArticleList, fetchArticleSub, articleType } from '@/services/api';

export default {
  namespace: 'changeLog',
  state: {
    list: {},
    subType: [],
  },

  effects: {
    *getSub(_, { call, put }) {
      const response = yield call(fetchArticleSub, articleType.CHANGELOG);
      yield put({
        type: 'saveSub',
        payload: response,
      });
    },
    *getList({ payload }, { call, put }) {
      const response = yield call(fetchArticleList, { id: payload, type: articleType.CHANGELOG });
      yield put({
        type: 'saveList',
        payload: response,
      });
    },
  },
  reducers: {
    saveSub(state, action) {
      return {
        ...state,
        subType: action.payload || [],
      };
    },
    saveList(state, action) {
      return {
        ...state,
        list: action.payload || {},
      };
    },
  },
};
