import { fetchBatchList } from '@/services/api';

export default {
  namespace: 'batchEdit',

  state: {
    data: [],
    total: 0,
    operaterList: [],
    batchList: [],
  },

  effects: {
    *getBatchList({ payload }, { call, put }) {
      const response = yield call(fetchBatchList, payload);
      yield put({
        type: 'saveBatchList',
        payload: response,
      });
    },
  },
  reducers: {
    saveBatchList(state, { payload }) {
      const {
        data = [],
        meta: { total = 0 },
      } = payload;

      return {
        ...state,
        batchList: data,
        total,
      };
    },
  },
};
