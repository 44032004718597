import { fetchAllNotifications } from '@/services/api';

export default {
  namespace: 'messageBox',

  state: {
    parts: [],
    peoples: [],
    notifications: {
      data: [],
      currentPage: 1,
      pageSize: 30,
      total: 0,
    },
  },

  effects: {
    *getAllNotifications({ payload, callback }, { call, put }) {
      const response = yield call(fetchAllNotifications, payload);
      yield put({
        type: 'saveAllNotifications',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveAllNotifications(state, action) {
      const { data, total, current_page: currentPage } = action.payload;
      const pageSize = parseInt(action.payload.per_page, 10);
      return {
        ...state,
        notifications: { data, total, pageSize, currentPage },
      };
    },
  },
};
