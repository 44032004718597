import {
  addAutotoken,
  addPartners,
  addStore,
  addTag,
  fetchPP,
  fetchPartners,
  fetchPeople,
  fetchPeopleChangesMsg,
  fetchStore,
  fetchStoreInfo,
  fetchTag,
  fetchTimeZoneChangesMsg,
  removeTag,
  updateStoreInfo,
  fetchApplyUser,
  // fetchPeoples
  // fetchStoreDomainByKey,
  fetchThirdLists,
  fetchStoreTheme,
  fetchSelectedPP,
  fetchPPList,
} from '@/services/api';
import { fetchAssociationUsers } from '@/services/association-users';
import { sortByStr, memorizeFn } from '@/utils/utils';

const fetchStoreCached = memorizeFn(fetchStore, { expired_at: 60 * 5 });

export default {
  namespace: 'store',

  state: {
    domains: [],
    vendors: [],
    parts: [],
    usernames: [],
    tags: [],
    stores: {
      data: [],
      meta: {
        currentPage: 1,
        total: 0,
      },
    },
    partners: [],
    currencys: [],
    pps: [],
    ppc: [],
    storePPs: [],
    peopleWithId: [],
    peopleWithIdNew: [],
    applyUserWithId: [],
    TimeZoneMsg: [],
    PeopleMsg: [],
    thirdDomains: [],
    storeThemes: [],
    selectedPP: [],
    ppcr: [],
    pplist: [],
  },

  effects: {
    *getStores({ payload, callback }, { call, put }) {
      if (payload?.refresh) {
        delete payload.refresh;
        fetchStoreCached.refresh();
      }
      const response = yield call(fetchStoreCached, payload);
      yield put({
        type: 'saveStores',
        payload: response,
      });
      if (callback) callback(response);
    },

    *getTags(_, { call, put }) {
      const response = yield call(fetchTag);
      yield put({
        type: 'saveTags',
        payload: response,
      });
    },
    *addTags({ payload, callback }, { call }) {
      const response = yield call(addTag, payload);
      if (callback) callback(response);
    },
    *removeTags({ payload, callback }, { call }) {
      const response = yield call(removeTag, payload);
      if (callback) callback(response);
    },
    *editAutoToken({ payload, callback }, { call }) {
      const response = yield call(addAutotoken, payload);
      if (callback) callback(response);
    },
    *getStoreInfo({ payload, callback }, { call, put }) {
      const response = yield call(fetchStoreInfo, payload);
      yield put({
        type: 'saveStoreInfo',
        payload: response,
      });
      if (callback) callback(response);
    },
    *editStoreInfo({ payload, callback }, { call }) {
      const response = yield call(updateStoreInfo, payload);
      if (callback) callback(response);
    },
    *getPartners(_, { call, put }) {
      const response = yield call(fetchPartners);
      yield put({
        type: 'savePartners',
        payload: response,
      });
    },
    *editPartners({ payload, callback }, { call }) {
      const response = yield call(addPartners, payload);
      if (callback) callback(response);
    },
    *getPPs({ callback, payload }, { call, put }) {
      const response = yield call(fetchPP, payload);
      if (callback) callback(response);

      yield put({
        type: 'savePPs',
        payload: response,
      });
    },
    *getStorePPs({ callback, payload }, { call, put }) {
      const response = yield call(fetchPP, payload);
      if (callback) callback(response);
      yield put({
        type: 'saveStorePPs',
        payload: response,
      });
    },

    *getPPc({ payload }, { call, put }) {
      const response = yield call(fetchPP, payload);
      yield put({
        type: 'savePPc',
        payload: response,
      });
    },
    *getPPCR({ payload }, { call, put }) {
      const response = yield call(fetchPP, payload);
      yield put({
        type: 'savePPCR',
        payload: response,
      });
    },
    *addNewStore({ payload, callback }, { call }) {
      const response = yield call(addStore, payload);
      if (callback) callback(response);
    },
    *getPeopleWithId({ payload }, { call, put }) {
      const response = yield call(fetchPeople, payload);
      yield put({
        type: 'savePeopleWithId',
        payload: response,
      });
    },
    *getPeopleWithIdNew({ payload }, { call, put }) {
      const response = yield call(fetchAssociationUsers, payload);
      yield put({
        type: 'savePeopleWithIdNew',
        payload: response,
      });
    },
    *getApplyUserWithId({ payload }, { call, put }) {
      const response = yield call(fetchApplyUser, payload);
      yield put({
        type: 'saveApplyUserWithId',
        payload: response,
      });
    },
    *getTimeZoneChangesMsg({ payload }, { call, put }) {
      const response = yield call(fetchTimeZoneChangesMsg, payload);
      yield put({
        type: 'saveTimeZoneChangesMsg',
        payload: response,
      });
    },

    *getPeopleChangesMsg({ payload }, { call, put }) {
      const response = yield call(fetchPeopleChangesMsg, payload);
      yield put({
        type: 'savePeopleChangesMsg',
        payload: response,
      });
    },
    *getThirdLists({ payload, callback }, { call, put }) {
      const response = yield call(fetchThirdLists, payload);
      yield put({
        type: 'saveThirdLists',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getStoreTheme({ payload }, { call, put }) {
      const response = yield call(fetchStoreTheme, payload);
      yield put({
        type: 'saveStoreTheme',
        payload: response,
      });
    },
    *getSelectedPP({ payload }, { call, put }) {
      const response = yield call(fetchSelectedPP, payload);
      yield put({
        type: 'saveSelectedPP',
        payload: response,
      });
    },
    *getPpList({ payload }, { call, put }) {
      const response = yield call(fetchPPList, payload);
      yield put({
        type: 'savePpList',
        payload: response,
      });
    },
  },
  reducers: {
    saveStores(state, action) {
      const { vendors = [], domains = [], parts = [], usernames = [], currencys = [] } =
        action.payload || {};
      return {
        ...state,
        vendors,
        domains,
        parts,
        usernames,
        currencys,
      };
    },
    saveTags(state, action) {
      return {
        ...state,
        tags: action.payload,
      };
    },
    saveStoreInfo(state, action) {
      const {
        data,
        meta: { current_page: currentPage, total },
      } = action.payload;
      return {
        ...state,
        stores: {
          data,
          meta: {
            currentPage,
            total,
          },
        },
      };
    },
    savePartners(state, action) {
      return {
        ...state,
        partners: action.payload,
      };
    },
    savePPs(state, action) {
      return {
        ...state,
        pps: action.payload,
      };
    },
    saveStorePPs(state, action) {
      return {
        ...state,
        storePPs: action.payload,
      };
    },

    savePPc(state, action) {
      return {
        ...state,
        ppc: action.payload,
      };
    },
    savePPCR(state, action) {
      return {
        ...state,
        ppcr: action.payload,
      };
    },
    savePeopleWithId(state, action) {
      const data = action.payload || [];
      data?.sort(sortByStr('name')); //eslint-disable-line
      return {
        ...state,
        peopleWithId: data,
      };
    },
    savePeopleWithIdNew(state, action) {
      const data = action.payload || [];
      data?.sort(sortByStr('name')); //eslint-disable-line
      return {
        ...state,
        peopleWithIdNew: data,
      };
    },
    saveApplyUserWithId(state, action) {
      const data = action.payload || [];
      data?.sort(sortByStr('name')); //eslint-disable-line
      return {
        ...state,
        applyUserWithId: data,
      };
    },
    saveTimeZoneChangesMsg(state, action) {
      return {
        ...state,
        TimeZoneMsg: action.payload.data || [],
      };
    },
    savePeopleChangesMsg(state, action) {
      return {
        ...state,
        PeopleMsg: action.payload.data || [],
      };
    },
    saveThirdLists(state, action) {
      return {
        ...state,
        thirdDomains: action.payload || [],
      };
    },
    saveStoreTheme(state, action) {
      return {
        ...state,
        storeThemes: action.payload || [],
      };
    },
    saveSelectedPP(state, action) {
      return {
        ...state,
        selectedPP: action.payload || [],
      };
    },
    savePpList(state, { payload }) {
      return {
        ...state,
        pplist:
          payload?.data?.map(i => ({
            label: i.email,
            value: i.id,
          })) || [],
      };
    },
  },
};
