// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelAccount0 from '/src/models/account.js';
import ModelAccountUser1 from '/src/models/accountUser.js';
import ModelAdData2 from '/src/models/adData.js';
import ModelAdDisapprove3 from '/src/models/adDisapprove.js';
import ModelAdMaterial4 from '/src/models/adMaterial.js';
import ModelAdOffShelf5 from '/src/models/adOffShelf.js';
import ModelAdPolicy6 from '/src/models/adPolicy.js';
import ModelAdReview7 from '/src/models/adReview.js';
import ModelAdvertisingAccount8 from '/src/models/advertisingAccount.js';
import ModelAssetReview9 from '/src/models/assetReview.js';
import ModelBatchEdit10 from '/src/models/batchEdit.js';
import ModelBlockDomain11 from '/src/models/blockDomain.js';
import ModelBmToken12 from '/src/models/bmToken.js';
import ModelChangeLog13 from '/src/models/changeLog.js';
import ModelCollection14 from '/src/models/collection.js';
import ModelCommons15 from '/src/models/commons.js';
import ModelCompared16 from '/src/models/compared.js';
import ModelComplexSku17 from '/src/models/complexSku.js';
import ModelCountrySales18 from '/src/models/countrySales.jsx';
import ModelCpmAnalysis19 from '/src/models/cpmAnalysis.js';
import ModelCreditCard20 from '/src/models/creditCard.js';
import ModelCustomClassification21 from '/src/models/customClassification.js';
import ModelCustomize22 from '/src/models/customize.js';
import ModelCustomizedManage23 from '/src/models/customizedManage.js';
import ModelDaily24 from '/src/models/daily.js';
import ModelDailyCheck25 from '/src/models/dailyCheck.js';
import ModelDashboard26 from '/src/models/dashboard.js';
import ModelDetail27 from '/src/models/detail.js';
import ModelDispute28 from '/src/models/dispute.js';
import ModelDomainName29 from '/src/models/domainName.js';
import ModelDomainNamePayment30 from '/src/models/domainNamePayment.js';
import ModelErrormsg31 from '/src/models/errormsg.js';
import ModelFaq32 from '/src/models/faq.js';
import ModelFeedback33 from '/src/models/feedback.js';
import ModelGetprofit34 from '/src/models/getprofit.js';
import ModelGlobal35 from '/src/models/global.js';
import ModelGoogleAdSpend36 from '/src/models/googleAdSpend.js';
import ModelGoogleAuthorizeEmail37 from '/src/models/googleAuthorizeEmail.js';
import ModelGooleAds38 from '/src/models/gooleAds.js';
import ModelGrouproi39 from '/src/models/grouproi.js';
import ModelHermesUser40 from '/src/models/hermesUser.js';
import ModelImageView41 from '/src/models/imageView.js';
import ModelLogin42 from '/src/models/login.js';
import ModelLogisticsTracking43 from '/src/models/logisticsTracking.js';
import ModelManagement44 from '/src/models/management.js';
import ModelMaterialDispatch45 from '/src/models/materialDispatch.js';
import ModelMessage46 from '/src/models/message.js';
import ModelMessageBox47 from '/src/models/messageBox.js';
import ModelOrder48 from '/src/models/order.js';
import ModelOrderDetail49 from '/src/models/orderDetail.js';
import ModelOrderLogistics50 from '/src/models/orderLogistics.js';
import ModelOrderReport51 from '/src/models/orderReport.js';
import ModelPart52 from '/src/models/part.js';
import ModelPaypal53 from '/src/models/paypal.js';
import ModelPixel54 from '/src/models/pixel.js';
import ModelPixelManage55 from '/src/models/pixelManage.js';
import ModelPpAlert56 from '/src/models/ppAlert.js';
import ModelPpDomain57 from '/src/models/ppDomain.js';
import ModelPpFamily58 from '/src/models/ppFamily.js';
import ModelPpList59 from '/src/models/ppList.js';
import ModelProduct60 from '/src/models/product.js';
import ModelProductAfterSalesReview61 from '/src/models/productAfterSalesReview.js';
import ModelProductAfterSalesReviewNew62 from '/src/models/productAfterSalesReviewNew.js';
import ModelProductPool63 from '/src/models/productPool.js';
import ModelPropertyAssessment64 from '/src/models/propertyAssessment.js';
import ModelRank65 from '/src/models/rank.js';
import ModelRealTimeSpending66 from '/src/models/realTimeSpending.js';
import ModelRefundDetail67 from '/src/models/refundDetail.js';
import ModelSetting68 from '/src/models/setting.js';
import ModelShopTicket69 from '/src/models/shopTicket.js';
import ModelSku70 from '/src/models/sku.js';
import ModelSkucalc71 from '/src/models/skucalc.js';
import ModelSkusales72 from '/src/models/skusales.js';
import ModelSkuShippingStatus73 from '/src/models/skuShippingStatus.js';
import ModelSpend74 from '/src/models/spend.js';
import ModelStatistic75 from '/src/models/statistic.js';
import ModelStockProduct76 from '/src/models/stockProduct.js';
import ModelStore77 from '/src/models/store.js';
import ModelStoreChangeHistory78 from '/src/models/storeChangeHistory.js';
import ModelTrack79 from '/src/models/track.js';
import ModelTSkuCustomized80 from '/src/models/tSkuCustomized.js';
import ModelUnshipped81 from '/src/models/unshipped.js';
import ModelUser82 from '/src/models/user.js';
import ModelWpAccount83 from '/src/models/wpAccount.js';
import ModelWsAccount84 from '/src/models/wsAccount.js';
import ModelWshopAddCart85 from '/src/models/wshopAddCart.js';
import ModelXbAccount86 from '/src/models/xbAccount.js';
import ModelNotOrderReviewDataService87 from '/src/pages/Review/AfterSalesReview/components/NotOrderReview/models/NotOrderReviewDataService.js';
import ModelReviewDataService88 from '/src/pages/Review/AfterSalesReview/components/Review/models/ReviewDataService.js';
import ModelInSideReviewDataService89 from '/src/pages/Review/ProductAfterSalesReview/components/Review/models/inSideReviewDataService.js';
import ModelOutSideReviewDataService90 from '/src/pages/Review/ProductAfterSalesReview/components/Review/models/outSideReviewDataService.js';
import ModelInSideReviewDataServiceNew91 from '/src/pages/Review/ProductAfterSalesReviewNew/components/Review/models/inSideReviewDataServiceNew.js';
import ModelOutSideReviewDataServiceNew92 from '/src/pages/Review/ProductAfterSalesReviewNew/components/Review/models/outSideReviewDataServiceNew.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'account', ...ModelAccount0 });
app.model({ namespace: 'accountUser', ...ModelAccountUser1 });
app.model({ namespace: 'adData', ...ModelAdData2 });
app.model({ namespace: 'adDisapprove', ...ModelAdDisapprove3 });
app.model({ namespace: 'adMaterial', ...ModelAdMaterial4 });
app.model({ namespace: 'adOffShelf', ...ModelAdOffShelf5 });
app.model({ namespace: 'adPolicy', ...ModelAdPolicy6 });
app.model({ namespace: 'adReview', ...ModelAdReview7 });
app.model({ namespace: 'advertisingAccount', ...ModelAdvertisingAccount8 });
app.model({ namespace: 'assetReview', ...ModelAssetReview9 });
app.model({ namespace: 'batchEdit', ...ModelBatchEdit10 });
app.model({ namespace: 'blockDomain', ...ModelBlockDomain11 });
app.model({ namespace: 'bmToken', ...ModelBmToken12 });
app.model({ namespace: 'changeLog', ...ModelChangeLog13 });
app.model({ namespace: 'collection', ...ModelCollection14 });
app.model({ namespace: 'commons', ...ModelCommons15 });
app.model({ namespace: 'compared', ...ModelCompared16 });
app.model({ namespace: 'complexSku', ...ModelComplexSku17 });
app.model({ namespace: 'countrySales', ...ModelCountrySales18 });
app.model({ namespace: 'cpmAnalysis', ...ModelCpmAnalysis19 });
app.model({ namespace: 'creditCard', ...ModelCreditCard20 });
app.model({ namespace: 'customClassification', ...ModelCustomClassification21 });
app.model({ namespace: 'customize', ...ModelCustomize22 });
app.model({ namespace: 'customizedManage', ...ModelCustomizedManage23 });
app.model({ namespace: 'daily', ...ModelDaily24 });
app.model({ namespace: 'dailyCheck', ...ModelDailyCheck25 });
app.model({ namespace: 'dashboard', ...ModelDashboard26 });
app.model({ namespace: 'detail', ...ModelDetail27 });
app.model({ namespace: 'dispute', ...ModelDispute28 });
app.model({ namespace: 'domainName', ...ModelDomainName29 });
app.model({ namespace: 'domainNamePayment', ...ModelDomainNamePayment30 });
app.model({ namespace: 'errormsg', ...ModelErrormsg31 });
app.model({ namespace: 'faq', ...ModelFaq32 });
app.model({ namespace: 'feedback', ...ModelFeedback33 });
app.model({ namespace: 'getprofit', ...ModelGetprofit34 });
app.model({ namespace: 'global', ...ModelGlobal35 });
app.model({ namespace: 'googleAdSpend', ...ModelGoogleAdSpend36 });
app.model({ namespace: 'googleAuthorizeEmail', ...ModelGoogleAuthorizeEmail37 });
app.model({ namespace: 'gooleAds', ...ModelGooleAds38 });
app.model({ namespace: 'grouproi', ...ModelGrouproi39 });
app.model({ namespace: 'hermesUser', ...ModelHermesUser40 });
app.model({ namespace: 'imageView', ...ModelImageView41 });
app.model({ namespace: 'login', ...ModelLogin42 });
app.model({ namespace: 'logisticsTracking', ...ModelLogisticsTracking43 });
app.model({ namespace: 'management', ...ModelManagement44 });
app.model({ namespace: 'materialDispatch', ...ModelMaterialDispatch45 });
app.model({ namespace: 'message', ...ModelMessage46 });
app.model({ namespace: 'messageBox', ...ModelMessageBox47 });
app.model({ namespace: 'order', ...ModelOrder48 });
app.model({ namespace: 'orderDetail', ...ModelOrderDetail49 });
app.model({ namespace: 'orderLogistics', ...ModelOrderLogistics50 });
app.model({ namespace: 'orderReport', ...ModelOrderReport51 });
app.model({ namespace: 'part', ...ModelPart52 });
app.model({ namespace: 'paypal', ...ModelPaypal53 });
app.model({ namespace: 'pixel', ...ModelPixel54 });
app.model({ namespace: 'pixelManage', ...ModelPixelManage55 });
app.model({ namespace: 'ppAlert', ...ModelPpAlert56 });
app.model({ namespace: 'ppDomain', ...ModelPpDomain57 });
app.model({ namespace: 'ppFamily', ...ModelPpFamily58 });
app.model({ namespace: 'ppList', ...ModelPpList59 });
app.model({ namespace: 'product', ...ModelProduct60 });
app.model({ namespace: 'productAfterSalesReview', ...ModelProductAfterSalesReview61 });
app.model({ namespace: 'productAfterSalesReviewNew', ...ModelProductAfterSalesReviewNew62 });
app.model({ namespace: 'productPool', ...ModelProductPool63 });
app.model({ namespace: 'propertyAssessment', ...ModelPropertyAssessment64 });
app.model({ namespace: 'rank', ...ModelRank65 });
app.model({ namespace: 'realTimeSpending', ...ModelRealTimeSpending66 });
app.model({ namespace: 'refundDetail', ...ModelRefundDetail67 });
app.model({ namespace: 'setting', ...ModelSetting68 });
app.model({ namespace: 'shopTicket', ...ModelShopTicket69 });
app.model({ namespace: 'sku', ...ModelSku70 });
app.model({ namespace: 'skucalc', ...ModelSkucalc71 });
app.model({ namespace: 'skusales', ...ModelSkusales72 });
app.model({ namespace: 'skuShippingStatus', ...ModelSkuShippingStatus73 });
app.model({ namespace: 'spend', ...ModelSpend74 });
app.model({ namespace: 'statistic', ...ModelStatistic75 });
app.model({ namespace: 'stockProduct', ...ModelStockProduct76 });
app.model({ namespace: 'store', ...ModelStore77 });
app.model({ namespace: 'storeChangeHistory', ...ModelStoreChangeHistory78 });
app.model({ namespace: 'track', ...ModelTrack79 });
app.model({ namespace: 'tSkuCustomized', ...ModelTSkuCustomized80 });
app.model({ namespace: 'unshipped', ...ModelUnshipped81 });
app.model({ namespace: 'user', ...ModelUser82 });
app.model({ namespace: 'wpAccount', ...ModelWpAccount83 });
app.model({ namespace: 'wsAccount', ...ModelWsAccount84 });
app.model({ namespace: 'wshopAddCart', ...ModelWshopAddCart85 });
app.model({ namespace: 'xbAccount', ...ModelXbAccount86 });
app.model({ namespace: 'NotOrderReviewDataService', ...ModelNotOrderReviewDataService87 });
app.model({ namespace: 'ReviewDataService', ...ModelReviewDataService88 });
app.model({ namespace: 'inSideReviewDataService', ...ModelInSideReviewDataService89 });
app.model({ namespace: 'outSideReviewDataService', ...ModelOutSideReviewDataService90 });
app.model({ namespace: 'inSideReviewDataServiceNew', ...ModelInSideReviewDataServiceNew91 });
app.model({ namespace: 'outSideReviewDataServiceNew', ...ModelOutSideReviewDataServiceNew92 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
