import request from '@/utils/request';

//毛利订单明细异步导出
export async function fetchAssociationUsers(params) {
  return request(`/api/association-users`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
