import request from '@/utils/request';

// 获取国家销量
export async function fetchCountrySales(data) {
  return request('/api/country_sales', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
//国家销量-国家权限配置
export async function fetchUserCountries(params) {
  return request('/api/user-countries/search', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
// 国家销量-新增国家权限配置
export async function addUserCountries(data) {
  return request('/api/user-countries', {
    method: 'POST',
    data,
  });
}
// 国家销量-编辑国家权限配置
export async function updateUserCountries(id, data) {
  return request(`/api/user-countries/${id}`, {
    method: 'PATCH',
    data,
  });
}
//国家销量-国家筛选项
export async function fetchCountries(params) {
  return request('/api/countries/search', {
    method: 'GET',
    params,
  });
}
//国家销量-国家配置-人员筛选项
export async function fetchPeoples(params) {
  return request('/api/peoples', {
    method: 'GET',
    params,
  });
}
// 国家销量-删除国家权限配置
export async function deleteUserCountries(id) {
  return request(`/api/user-countries/${id}`, {
    method: 'DELETE',
  });
}
//国家销量-洲筛选项
export async function fetchContinents(params) {
  return request('/api/continents/search', {
    method: 'GET',
    params,
  });
}
