/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { message, notification } from 'antd';
import { getToken, setAuthorityByToken, setUser } from './authority';
import { removeBlankProperty } from '@/utils/utils';
import alidnsError from './alidnsError';
import { history } from 'umi';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
/**
 * 异常处理程序
 */
const errorHandler = async error => {
  const { response } = error;
  let body = '';
  if (response && response.status === 504) {
    notification.error({
      message: '请求错误',
      description: '请求超时，稍后再试',
    });
    return response;
  }
  if (response && response.json) {
    try {
      body = await response.clone().json();
    } catch (e) {
      if (response.status < 400) return response;
    }
  }
  if (body && body.status_code === 500 && body.message === 'This action is unauthorized.') {
    message.error('没有操作权限');
    return response;
  }
  if (body && body.status_code) {
    if (body.status_code === 401) {
      // 登录接口401不做跳转
      if (
        error?.request?.originUrl === '/api/oauth/token' &&
        error?.request?.options?.method === 'POST'
      ) {
        setUser({});
        setAuthorityByToken();
      } else window.location.replace(`${window.location.origin}/user/login`);
    }
    const errorText = body.message || codeMessage[body.status_code];
    if (body.errors) {
      const { errors } = body;
      Object.keys(errors).forEach(key => {
        if (Array.isArray(errors[key])) {
          errors[key].forEach(str => {
            message.error(str);
          });
        } else {
          message.error(errors[key]);
        }
      });
    } else {
      message.error(alidnsError.find(i => i.ErrorCode === errorText)?.Description || errorText);
    }
  } else if (response && response.status) {
    const { status, statusText = '' } = response;
    const errorText = codeMessage[status] || statusText;
    notification.error({
      message: '请求错误',
      description: errorText,
    });
  } else if (error && error.status) {
    const errorText = error.statusText || codeMessage[error.status];
    notification.error({
      message: '请求错误',
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  // 默认错误`处理
  errorHandler,
  credentials: 'include', // 默认请求是否带上cookie
});

/**
 * 配置request拦截器, 改变url 或 options.
 */
request.interceptors.request.use(async (url, options) => {
  const token = getToken();
  const optionsData =
    options?.data && options?.removeBlankProperty
      ? { data: removeBlankProperty(options?.data) }
      : {};
  const defaultHeaders = {
    Accept: 'application/json',
    ...options?.headers,
    params: options?.removeBlankProperty ? removeBlankProperty(options?.params) : options?.params,
    ...optionsData,
  };
  if (token && token.access_token && options.headers?.Authorization !== 'ignore') {
    defaultHeaders.Authorization = `Bearer ${token.access_token}`;
  }
  const newOptions = { ...options, headers: defaultHeaders };

  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }

  return {
    url,
    options: { ...newOptions },
  };
});

request.interceptors.response.use(async response => {
  if (
    response.headers.get('force_modify_password') == '1' &&
    !response.url.includes('/api/notifications')
  ) {
    history.push({
      pathname: '/personal/info',
      state: {
        update: true,
      },
    });
  }
  if (response.headers.get('jump_to_modify_password') == '1') {
    history.push({
      pathname: '/personal/info',
      state: {
        update: true,
        jump: true,
      },
    });
  }

  return response;
});
export default request;
