import {
  addMessage,
  addNotification,
  fetchMessage,
  fetchMessageDetails,
  fetchPart,
  // fetchPersonnels,
  fetchReviewMessages,
  removeMessage,
  updateMessage,
  fetchPeople,
} from '@/services/api';

export default {
  namespace: 'message',

  state: {
    parts: [],
    peoples: [],
    messages: {
      data: [],
      currentPage: 1,
      pageSize: 30,
      total: 0,
    },
    reviewMessages: {
      data: [],
      currentPage: 1,
      pageSize: 30,
      total: 0,
    },
    messageDetails: {
      notifications: [],
    },
  },

  effects: {
    *getPeoples({ payload, callback }, { call, put }) {
      const response = yield call(fetchPeople, payload);
      yield put({
        type: 'savePeoples',
        payload: response,
      });
      if (callback) callback();
    },
    *getParts({ payload, callback }, { call, put }) {
      const response = yield call(fetchPart, payload);
      yield put({
        type: 'saveParts',
        payload: response,
      });
      if (callback) callback();
    },
    *createMessage({ payload, callback }, { call }) {
      const response = yield call(addMessage, payload);
      if (callback) callback(response);
    },
    *getMessages({ payload, callback }, { call, put }) {
      const response = yield call(fetchMessage, payload);
      yield put({
        type: 'saveMessages',
        payload: response,
      });
      if (callback) callback();
    },
    *getReviewMessages({ payload, callback }, { call, put }) {
      const response = yield call(fetchReviewMessages, payload);
      yield put({
        type: 'saveReviewMessages',
        payload: response,
      });
      if (callback) callback();
    },
    *getMessageDetail({ payload, callback }, { call, put }) {
      const response = yield call(fetchMessageDetails, payload);
      yield put({
        type: 'saveMessageDetails',
        payload: response,
      });
      if (callback) callback();
    },
    *updateMessage({ payload, callback }, { call }) {
      const response = yield call(updateMessage, payload);
      if (callback) callback(response);
    },
    *deleteMessage({ payload, callback }, { call }) {
      const response = yield call(removeMessage, payload);
      if (callback) callback(response);
    },
    *sendNotification({ payload, callback }, { call }) {
      const response = yield call(addNotification, payload);
      if (callback) callback(response);
    },
  },
  reducers: {
    savePeoples(state, action) {
      return {
        ...state,
        peoples: action.payload,
      };
    },
    saveParts(state, action) {
      return {
        ...state,
        parts: action.payload,
      };
    },
    saveMessages(state, action) {
      const { data, total, current_page: currentPage } = action.payload;
      const pageSize = parseInt(action.payload.per_page, 10);
      return {
        ...state,
        messages: { data, total, pageSize, currentPage },
      };
    },
    saveReviewMessages(state, action) {
      const { data, total, current_page: currentPage } = action.payload;
      const pageSize = parseInt(action.payload.per_page, 10);
      return {
        ...state,
        reviewMessages: { data, total, pageSize, currentPage },
      };
    },
    saveMessageDetails(state, action) {
      return {
        ...state,
        messageDetails: action.payload,
      };
    },
  },
};
