import { fetchRank, getPeopleList, fetchPeople } from '@/services/api';
// import { countBonus } from '@/utils/utils';

export default {
  namespace: 'rank',

  state: {
    rankList: [],
    statistics: {},
    rankPeopleList: [],
    testStatistics: [],
  },

  effects: {
    *getRank({ payload, callback }, { call, put }) {
      const response = yield call(fetchRank, payload);
      yield put({
        type: 'saveRank',
        payload: response,
      });
      if (callback) callback();
    },
    *getRankPeopleList({ payload }, { call, put }) {
      let res = {};
      if (payload) {
        const response = yield call(fetchPeople);
        res = response.filter(i => i?.pivot?.people_id);
      } else {
        res = yield call(getPeopleList);
      }
      yield put({
        type: 'savePeopleList',
        payload: res,
      });
    },
  },
  reducers: {
    saveRank(state, { payload }) {
      const newList = payload.list.map(item => {
        return {
          ...item,
          yesterday_roi:
            Number(item.yesterday_order_amount) === 0 || Number(item.yesterday_spend) === 0
              ? 0
              : Number(item.yesterday_order_amount) / Number(item.yesterday_spend),
          month_roi:
            Number(item.month_order_amount) === 0 || Number(item.month_spend) === 0
              ? 0
              : Number(item.month_order_amount) / Number(item.month_spend),
        };
      });
      return {
        ...state,
        rankList: newList,
        statistics: payload.statistics,
        testStatistics: payload?.test_statistics ? [payload?.test_statistics] : [],
      };
    },
    savePeopleList(state, { payload }) {
      return {
        ...state,
        rankPeopleList: payload,
      };
    },
  },
};
