import { fetchDailyDetail } from '@/services/api';
import { fetchDaily } from '@/services/sku';

export default {
  namespace: 'daily',

  state: {
    data: [],
    total: 0,
    summary: {
      todayTotal: 0,
      yesterdayTotal: 0,
      weekdayTotal: 0,
      monthTotal: 0,
      monthFractionTotal: 0,
      TodayFractionTotal: 0,
      yesterdayFractionTotal: 0,
      weekFractionTotal: 0,
    },
    detailData: [],
    detailTotal: 0,
    detailSummary: {
      needReport: 0,
      hasSubmitCount: 0,
      notSubmitCount: 0,
      reportCount: 0,
    },
  },

  effects: {
    *getDaily({ payload }, { call, put }) {
      const response = yield call(fetchDaily, payload);
      yield put({
        type: 'saveDaily',
        payload: response,
      });
    },
    *getDailyDetail({ payload }, { call, put }) {
      const response = yield call(fetchDailyDetail, payload);
      yield put({
        type: 'saveDailyDetail',
        payload: response,
      });
    },
  },
  reducers: {
    saveDaily(state, action) {
      const {
        meta,
        data,
        today_total: todayTotal,
        yesterday_total: yesterdayTotal,
        weekday_total: weekdayTotal,
        month_total: monthTotal,
        month_fraction_total: monthFractionTotal,
        today_fraction_total: TodayFractionTotal,
        yesterday_fraction_total: yesterdayFractionTotal,
        weekday_fraction_total: weekFractionTotal,
      } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
        summary: {
          todayTotal,
          yesterdayTotal,
          weekdayTotal,
          monthTotal,
          monthFractionTotal,
          TodayFractionTotal,
          yesterdayFractionTotal,
          weekFractionTotal,
        },
      };
    },
    saveDailyDetail(state, action) {
      const {
        meta,
        data,
        need_report: needReport,
        has_submit_count: hasSubmitCount,
        not_submit_count: notSubmitCount,
        report_count: reportCount,
      } = action.payload;
      return {
        ...state,
        detailData: data,
        detailTotal: meta?.total || 0,
        detailSummary: {
          needReport,
          hasSubmitCount,
          notSubmitCount,
          reportCount,
        },
      };
    },
  },
};
