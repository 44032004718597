import { fetchPPDomainName } from '@/services/api';

export default {
  namespace: 'ppDomain',
  state: {
    data: [],
    meta: {
      total: 0,
      page: 1,
      pageSize: 50,
    },
  },

  effects: {
    *getDomainName({ payload }, { call, put }) {
      const response = yield call(fetchPPDomainName, payload);
      yield put({
        type: 'saveDomainName',
        payload: response,
      });
    },
  },
  reducers: {
    saveDomainName(state, action) {
      const {
        data = [],
        meta: { total = 0, per_page: pageSize = 10, current_page: page = 1 },
      } = action.payload;
      return {
        data,
        meta: {
          total,
          page,
          pageSize,
        },
      };
    },
  },
};
