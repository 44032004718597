import {
  fetchLogisticsTracking,
  fetchOrderConfiguration,
  fetchTracknum,
  fetchFulfillmentTracksCounter,
  fetchFulfillmentTracksNewCounter,
} from '@/services/api';

export default {
  namespace: 'logisticsTracking',
  state: {
    data: new Map(),
    countryList: [],
    channelList: [],
    labelList: [],
    trackinfo: [],
    trackCounter: [],
  },

  effects: {
    *getLogisticsTracking({ payload, callback }, { call, put }) {
      const response = yield call(fetchLogisticsTracking, payload);
      yield put({
        type: 'saveLogisticsTracking',
        payload: { query: payload, response },
      });
      if (callback) callback();
    },
    *getLogisticsTrackingCount({ payload }, { call, put }) {
      const response = yield call(fetchLogisticsTracking, payload);
      yield put({
        type: 'saveLogisticsTracking',
        payload: { query: payload, response },
      });
    },
    *getCountry({ payload, callback }, { call, put }) {
      const response = yield call(fetchOrderConfiguration, payload);
      yield put({
        type: 'saveCountry',
        payload: response,
      });
      if (callback) callback(response?.[0]?.config ?? []);
    },
    *getLabel({ payload }, { call, put }) {
      const response = yield call(fetchOrderConfiguration, payload);
      yield put({
        type: 'saveLabel',
        payload: response,
      });
    },
    *getChannel({ payload, callback }, { call, put }) {
      const response = yield call(fetchOrderConfiguration, payload);
      yield put({
        type: 'saveChannel',
        payload: response,
      });
      if (callback) callback(response?.[0]?.config ?? []);
    },
    *getTracknum({ payload }, { call, put }) {
      const response = yield call(fetchTracknum, payload);
      yield put({
        type: 'saveTracknum',
        payload: response,
      });
    },
    *getTrackCounter({ payload }, { call, put }) {
      const res = yield call(fetchFulfillmentTracksCounter, payload);
      return res.data;
      // yield put({
      //   type: 'saveTrackCounter',
      //   payload: res?.data || [],
      // });
    },
    *getTrackNewCounter({ payload }, { call, put }) {
      const res = yield call(fetchFulfillmentTracksNewCounter, payload);
      yield put({
        type: 'saveTrackCounter',
        payload: res?.data || [],
      });
    },
  },
  reducers: {
    saveLogisticsTracking(state, { payload }) {
      const { data } = state;
      data.set(payload.query.status[0], payload.response);
      return {
        ...state,
        data,
      };
    },
    saveCountry(state, action) {
      const { config = [] } = action.payload[0];
      return {
        ...state,
        countryList: config,
      };
    },
    saveLabel(state, action) {
      const { config = [] } = action.payload[0];
      return {
        ...state,
        labelList: config,
      };
    },
    saveChannel(state, action) {
      const { config = [] } = action.payload[0];
      return {
        ...state,
        channelList: config,
      };
    },
    saveTracknum(state, action) {
      return {
        ...state,
        trackinfo: action?.payload?.[0]?.source?.data?.origin_info?.trackinfo || [],
      };
    },
    saveTrackCounter(state, action) {
      return {
        ...state,
        trackCounter: action?.payload || [],
      };
    },
  },
};
