import { fetchDomainList } from '@/services/api';

export default {
  namespace: 'adDisapprove',
  state: {},

  effects: {
    *getDomainName({ payload }, { call, put }) {
      const response = yield call(fetchDomainList, payload);
      yield put({
        type: 'saveDomainName',
        payload: response,
      });
    },
  },
  reducers: {
    saveDomainName(state, action) {
      const { data = [], meta, not_exist: notExistDomain = [] } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
        notExistDomain,
      };
    },
  },
};
