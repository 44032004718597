import request from '@/utils/request';
import { stringify } from 'qs';

// 第三信用卡列表
export function fetchThirdCreditCardList(params) {
  return request('/api/payments/third-credit-card', {
    params,
    removeBlankProperty: true,
  });
}

// 创建第三信用卡配置
export function createThirdCreditCard(data) {
  return request('/api/payments/third-credit-card', {
    method: 'POST',
    data,
  });
}

// 删除第三信用卡配置
export function deleteThirdCreditCard(id) {
  return request(`/api/payments/third-credit-card/${id}`, {
    method: 'DELETE',
  });
}

// 修改第三信用卡配置
export function updateThirdCreditCard(id, data) {
  return request(`/api/payments/third-credit-card/${id}`, {
    method: 'PUT',
    data,
  });
}

// 验证账号是否被绑定
export function checkThirdCreditCard(params) {
  return request(`/api/payments/credit-card-using-check`, {
    method: 'GET',
    params,
  });
}

// 信用卡申请列表
export function fetchCreditCardApplicationList(params) {
  return request(`/api/payments/credit-card-application-lists`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}

//信用卡申请列表导入
export function importCreditCardApplication(data) {
  return request('/api/payments/import-credit-card-application-lists', {
    method: 'POST',
    data,
  });
}

// 域名列表
export function fetchCreditCardApplicationDomain(params) {
  return request('/api/payments/credit-card-application-lists/domains', {
    method: 'GET',
    params,
  });
}

// 校验是否存在相似域名
export function checkDomainSimilar(params) {
  return request('/api/payments/credit-card-application-lists/domain-validator', {
    method: 'GET',
    params,
  });
}

//信用卡申请列表编辑
export function updateCreditCardApplication(id, data) {
  return request(`/api/payments/credit-card-application-lists/${id}`, {
    method: 'PUT',
    data,
  });
}

//信用卡申请列表批量编辑
export function batchUpdateCreditCardApplication(data) {
  return request(`/api/payments/credit-card-application-lists/batch-update`, {
    method: 'POST',
    data,
  });
}

//信用卡申请列表批量删除
export function deleteCreditCardApplication(data) {
  return request(`/api/payments/credit-card-application-lists`, {
    method: 'DELETE',
    data,
  });
}

// 信用卡申请列表导入模板
export function fetchCreditCardApplicationTemplate(params) {
  return request('/api/payments/import-credit-card-application-lists-template', {
    method: 'GET',
    params,
  });
}

// 信用卡申请列表修改备注
export function updateCreditCardApplicationRemark(id, data) {
  return request(`/api/payments/credit-card-application-lists/${id}/remark`, {
    method: 'PUT',
    data,
  });
}

// 导出
export function exportCreditCardApplicationList(params, select) {
  return request(`/api/payments/credit-card-application-lists`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    headers: {
      export: 'xlsx',
      select,
    },
  });
}

// 第四信用卡列表
export function fetchFourthCreditCardList(params) {
  return request('/api/payments/fourth-credit-card', {
    params,
    removeBlankProperty: true,
  });
}

// 创建第四信用卡配置
export function createFourthCreditCard(data) {
  return request('/api/payments/fourth-credit-card', {
    method: 'POST',
    data,
  });
}

// 删除第四信用卡配置
export function deleteFourthCreditCard(id) {
  return request(`/api/payments/fourth-credit-card/${id}`, {
    method: 'DELETE',
  });
}

// 修改第四信用卡配置
export function updateFourthCreditCard(id, data) {
  return request(`/api/payments/fourth-credit-card/${id}`, {
    method: 'PUT',
    data,
  });
}

// 创建信用卡主体信息
export function createCreaditCardEntity(data) {
  return request('/api/payments/credit-card-entities', {
    method: 'POST',
    data,
  });
}

// 删除信用卡主体信息
export function deleteCreaditCardEntity(id) {
  return request(`/api/payments/credit-card-entities/${id}`, {
    method: 'DELETE',
  });
}

// 修改信用卡主体信息
export function updateCreaditCardEntity(id, data) {
  return request(`/api/payments/credit-card-entities/${id}`, {
    method: 'PUT',
    data,
  });
}

// 店铺绑定信用卡主体
export function bindCreaditCardEntityStore(data) {
  return request(`/api/payments/credit-card-entities/bind-stores`, {
    method: 'POST',
    data,
  });
}

// 主体信息列表
export function fetchCreaditCardEntity() {
  return request(`/api/payments/credit-card-entities`);
}

// 检查店铺主体信息
export function examineCreaditCardEntityStore(data) {
  return request(`/api/payments/credit-card-entities/store-entity-list`, {
    method: 'POST',
    data,
  });
}

// 添加ocean支付账号
export function createKlarnaOceanPayConfig(data) {
  return request(`/api/payments/klarna-ocean-pay-config`, {
    method: 'POST',
    data,
  });
}

// ocean支付账号列表
export function fetchKlarnaOceanPayConfigList(params) {
  return request('/api/payments/klarna-ocean-pay-config', {
    params,
    removeBlankProperty: true,
  });
}

//更新ocean支付账号
export function updateKlarnaOceanPayConfig(id, data) {
  return request(`/api/payments/klarna-ocean-pay-config/${id}`, {
    method: 'PUT',
    data,
  });
}

// 删除ocean支付账号
export function deleteKlarnaOceanPayConfig(id) {
  return request(`/api/payments/klarna-ocean-pay-config/${id}`, {
    method: 'DELETE',
  });
}

// 校验ocean支付账号是否已使用
export function checkKlarnaOceanPayConfigIsUsing(id) {
  return request(`/api/payments/klarna-ocean-pay-config-is-using/${id}`);
}

// 添加sprout支付账号
export function createKlarnaSproutPayConfig(data) {
  return request(`/api/payments/klarna-sprout-pay-config`, {
    method: 'POST',
    data,
  });
}

//sprout支付账号列表
export function fetchKlarnaSproutPayConfigList(params) {
  return request('/api/payments/klarna-sprout-pay-config', {
    params,
    removeBlankProperty: true,
  });
}

//更新sprout支付账号
export function updateKlarnaSproutPayConfig(id, data) {
  return request(`/api/payments/klarna-sprout-pay-config/${id}`, {
    method: 'PUT',
    data,
  });
}

// 删除sprout支付账号
export function deleteKlarnaSproutPayConfig(id) {
  return request(`/api/payments/klarna-sprout-pay-config/${id}`, {
    method: 'DELETE',
  });
}

// 校验sprout支付账号是否已使用
export function checkKlarnaSproutPayConfigIsUsing(id) {
  return request(`/api/payments/klarna-sprout-pay-config-is-using/${id}`);
}

// 钱包配置列表
export function fetchPaymentWallet(params) {
  return request(`/api/payments/wallets`, {
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 创建钱包配置
export function createPaymentWallet(data) {
  return request(`/api/payments/wallets`, {
    data,
    method: 'POST',
  });
}

// 更新钱包配置
export function updatePaymentWallet(id, data) {
  return request(`/api/payments/wallets/${id}`, {
    data,
    method: 'POST',
  });
}

// 删除钱包配置
export function deletePaymentWallet(id) {
  return request(`/api/payments/wallets/${id}`, {
    method: 'DELETE',
  });
}
