import { fetchDailyCheckReviewPeople } from '@/services/asset';

export default {
  namespace: 'dailyCheck',
  state: {
    reviewPeopleList: [],
  },

  effects: {
    *fetchDailyCheckReviewPeople(_, { call, put }) {
      const response = yield call(fetchDailyCheckReviewPeople);
      yield put({
        type: 'saveReviewPeopleList',
        payload: response,
      });
    },
  },
  reducers: {
    saveReviewPeopleList(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        reviewPeopleList: data.map(item => ({
          label: item.name,
          value: item.id,
        })),
      };
    },
  },
};
