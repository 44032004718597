/**
 * Class representing an enumeration.
 */
export default class Enum {
  /**
   * Create a new Enum.
   * @param {Object} object - The object to be converted to an enum.
   */
  constructor(object) {
    Object.entries(object).forEach(([key, value]) => {
      this[key] = value;
    });
    Object.freeze(this);
  }

  /**
   * Make the enum iterable.
   */
  *[Symbol.iterator]() {
    for (let key of Object.keys(this)) yield key;
  }

  /**
   * Get the name of this value in enum.
   * @param {*} value
   * @returns {string} One name of this enum.
   */
  name(value) {
    return Object.entries(this).find(([key, val]) => val === value)?.[0];
  }
}
