import moment from 'moment';

export function setUser(user) {
  localStorage.setItem('current-user', JSON.stringify(user));
}

export function getUser() {
  const userStr = localStorage.getItem('current-user');
  const user = userStr ? JSON.parse(userStr) || {} : {};
  return user;
}

export function getAuthorityByToken() {
  const str = localStorage.getItem('api-token');
  if (!str) {
    return [];
  }
  const expires = localStorage.getItem('api-token-expires');
  if (moment(expires).isBefore(moment(new Date()))) {
    setUser({});
    return [];
  }
  const user = getUser();
  if (!user || !user.permissions) {
    return [];
  }
  return user.permissions;
}

export function getAuthority() {
  return getAuthorityByToken();
}

export function setAuthorityByToken(token, type) {
  if (!token || JSON.stringify(token) === '{}') {
    localStorage.removeItem('api-token');
    localStorage.removeItem('api-token-expires');
    return;
  }
  const tempToken = {
    ...token,
  };
  if (type === 'hmac') {
    Object.assign(tempToken, {
      expires_in: 1 * 24 * 3600,
    });
  }
  localStorage.setItem('api-token', JSON.stringify(tempToken));
  localStorage.setItem(
    'api-token-expires',
    moment()
      .add(tempToken.expires_in, 'seconds')
      .format(),
  );
}

export function getToken() {
  const str = localStorage.getItem('api-token');
  if (!str) {
    return {};
  }
  const token = JSON.parse(str);
  const expires = localStorage.getItem('api-token-expires');
  if (moment(expires).isBefore(moment(new Date()))) {
    return {};
  }
  return token;
}
