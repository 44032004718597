import {
  fetchSkuManage,
  fetchUnShelveUser,
  fetchStockSkus,
  fetchReviewPeople,
  fetchScmCategories,
  fetchScmRecommend,
  fetchMbProductType,
  fetchBiCountries,
} from '@/services/api';

import {
  fetchProductType,
  fetchSkuReviewL,
  fetchTypeProductStatistics,
  fetchReviewStatistic,
  fetchOperateLog,
  fetchSkuSearch,
  fetchUnShelveSku,
  fetchOnOfferSales,
  fetchSkuStockManage,
  fetchInfringement,
  getSkuTReviewField,
} from '@/services/sku';
import { fetchOperationTimeDetail, fetchOperationTimeTotal } from '@/services/bigData';

import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'sku',

  state: {
    list: {
      data: [],
      meta: {},
    },
    review: {
      data: [],
      meta: {},
    },
    productType: [],
    typeProductStatistics: {
      meta: {},
      total: {},
    },
    reviewStatistics: {
      meta: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      data: [],
    },
    operateLog: [],
    skuSearch: {
      meta: {},
      data: [],
    },
    unShelve: {
      meta: {},
      data: [],
    },
    unShelveUser: [],
    stockSkus: [],
    onOfferSales: {
      meta: {},
      data: [],
      totalData: {},
    },
    skuManage: {
      data: [],
      mate: {},
    },
    operationTimeTotal: [],
    operationTimeList: {
      data: [],
      total: 0,
      totalData: [],
    },
    operationTimeLog: {
      data: [],
      total: 0,
    },
    reviewPeople: [],
    infringementList: {
      data: [],
      total: 0,
    },
    scmRecommend: {
      data: [],
      mate: {},
    },
    scmCategories: [],
    incentiveSku: { data: [], total: 0, stockProductType: [] },
    mbProductType: [],
    canEditTextPicCustom: false,
    biCountries: [],
  },

  effects: {
    *getSkuManage({ payload, callback }, { call, put }) {
      const response = yield call(fetchSkuManage, payload);
      yield put({
        type: 'saveSkuManage',
        payload: response,
      });
      if (callback) callback();
    },
    *getSkuReview({ payload, callback }, { call, put }) {
      const response = yield call(fetchSkuReviewL, payload);
      yield put({
        type: 'saveSkuReview',
        payload: response,
      });
      if (callback) callback();
    },
    *getProductType({ callback }, { call, put }) {
      const response = yield call(fetchProductType);
      if (callback) callback(response);
      yield put({
        type: 'saveProductType',
        payload: response,
      });
    },
    *getTypeProductStatistics({ payload }, { call, put }) {
      const response = yield call(fetchTypeProductStatistics, payload);
      yield put({
        type: 'saveTypeProductStatistics',
        payload: response,
      });
    },
    *getReviewStatistics({ payload }, { call, put }) {
      const response = yield call(fetchReviewStatistic, payload);
      yield put({
        type: 'saveReviewStatistics',
        payload: response,
      });
    },
    *getOperateLog({ payload }, { call, put }) {
      const response = yield call(fetchOperateLog, payload);
      yield put({
        type: 'saveOperateLog',
        payload: response,
      });
    },
    *getSkuSearch({ payload, callback }, { call, put }) {
      const response = yield call(fetchSkuSearch, payload);
      yield put({
        type: 'saveSkuSearch',
        payload: response,
      });
      if (callback) callback();
    },
    *getUnShelveSku({ payload, callback }, { call, put }) {
      const response = yield call(fetchUnShelveSku, payload);
      yield put({
        type: 'saveUnShelveSku',
        payload: response,
      });
      if (callback) callback();
    },
    *getUnShelveUser(_, { call, put }) {
      const response = yield call(fetchUnShelveUser);
      yield put({
        type: 'saveUnShelveUser',
        payload: response,
      });
    },
    *getStockSkus({ payload, callback }, { call, put }) {
      const response = yield call(fetchStockSkus, payload);
      yield put({
        type: 'saveStockSkus',
        payload: response,
      });
      if (callback) callback();
    },
    *getOnOfferSales({ payload, callback }, { call, put }) {
      const response = yield call(fetchOnOfferSales, payload);
      yield put({
        type: 'saveOnOfferSales',
        payload: response,
      });
      if (callback) callback();
    },
    *getSkuStockManage({ payload, callback }, { call, put }) {
      const response = yield call(fetchSkuStockManage, payload);
      yield put({
        type: 'saveSkuStockManage',
        payload: response,
      });
      if (callback) callback();
    },
    *getOperationTimeTotal({ payload }, { call, put }) {
      const response = yield call(fetchOperationTimeTotal, payload);
      const summaryResponse = yield call(fetchOperationTimeTotal, { ...payload, is_total: 1 });
      yield put({
        type: 'saveOperationTimeTotal',
        payload: [...(response?.data?.rows ?? []), ...(summaryResponse?.data?.rows ?? [])],
      });
    },
    *getOperationTimeList({ payload }, { call, put }) {
      const response = yield call(fetchOperationTimeDetail, payload);
      yield put({
        type: 'saveOperationTimeListDetail',
        payload: response?.data ?? {},
      });
    },
    *getOperationTimeLog({ payload }, { call, put }) {
      const response = yield call(fetchOperationTimeDetail, payload);
      yield put({
        type: 'saveOperationTimeLog',
        payload: response?.data ?? {},
      });
    },
    *getReviewPeople({ payload }, { call, put }) {
      const response = yield call(fetchReviewPeople, payload);
      yield put({
        type: 'saveReviewPeople',
        payload: response,
      });
    },
    *getInfringementList({ payload }, { call, put }) {
      const response = yield call(fetchInfringement, payload);
      yield put({
        type: 'saveInfringementList',
        payload: response,
      });
    },
    *getScmRecommend({ payload, callback }, { call, put }) {
      const response = yield call(fetchScmRecommend, payload);
      yield put({
        type: 'saveScmRecommend',
        payload: response,
      });
      if (callback) callback();
    },
    *getScmCategories({ payload }, { call, put }) {
      const response = yield call(fetchScmCategories, payload);
      yield put({
        type: 'saveScmCategories',
        payload: response,
      });
    },
    *fetchMbProductType({ payload }, { call, put }) {
      const response = yield call(fetchMbProductType, payload);
      yield put({
        type: 'saveMbProductType',
        payload: response,
      });
    },
    *fetchSkuTReviewField({ payload }, { call, put }) {
      const response = yield call(getSkuTReviewField, payload);
      yield put({
        type: 'saveSkuTReviewField',
        payload: response,
      });
    },
    *getBiCountries(_, { call, put }) {
      const response = yield call(fetchBiCountries);
      yield put({
        type: 'saveBiCountries',
        payload: response,
      });
    },
  },
  reducers: {
    saveSkuManage(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        list: {
          data: data || [],
          meta: meta || {},
        },
      };
    },
    saveSkuReview(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        review: {
          data: data || [],
          meta: meta || {},
        },
      };
    },
    saveProductType(state, action) {
      return {
        ...state,
        productType: action.payload || [],
      };
    },
    saveTypeProductStatistics(state, action) {
      return {
        ...state,
        typeProductStatistics: action.payload,
      };
    },
    saveReviewStatistics(state, action) {
      const {
        data,
        meta: { total = 0, per_page: pageSize = 10, current_page: page = 1 },
      } = action.payload;
      return {
        ...state,
        reviewStatistics: { data, meta: { total, page, pageSize } },
      };
    },
    saveOperateLog(state, action) {
      return {
        ...state,
        operateLog: action.payload.data,
      };
    },
    clearOperateLog(state) {
      return {
        ...state,
        operateLog: [],
      };
    },
    saveSkuSearch(state, action) {
      const {
        data = [],
        meta: { total = 0, per_page: pageSize = 30, current_page: currentPage = 1 } = {},
      } = action.payload || {};
      const realData = data?.map?.(item => ({ ...item, uuid: generateUuid() }));
      return {
        ...state,
        skuSearch: { data: realData, meta: { total, currentPage, pageSize } },
      };
    },
    saveUnShelveSku(state, action) {
      const {
        data,
        meta: { total = 0, per_page: pageSize = 30, current_page: currentPage = 1 },
      } = action.payload;
      return {
        ...state,
        unShelve: { data, meta: { total, currentPage, pageSize } },
      };
    },
    saveUnShelveUser(state, action) {
      return {
        ...state,
        unShelveUser: action.payload || [],
      };
    },
    saveStockSkus(state, action) {
      return {
        ...state,
        stockSkus: action.payload || [],
      };
    },
    clearStockSkus(state) {
      return {
        ...state,
        stockSkus: [],
      };
    },
    saveOnOfferSales(state, action) {
      const {
        total_data: totalData = {},
        data = [],
        meta: { total = 0, per_page: pageSize = 30, current_page: currentPage = 1 } = {},
      } = action.payload || {};
      return {
        ...state,
        onOfferSales: { data, meta: { total, currentPage, pageSize }, totalData },
      };
    },
    saveSkuStockManage(state, action) {
      const { data = [], meta: { total = 0, current_page: currentPage = 1 } = {} } =
        action.payload || {};
      return {
        ...state,
        skuManage: { data, meta: { total, currentPage } },
      };
    },
    saveOperationTimeTotal(state, action) {
      return {
        ...state,
        operationTimeTotal: action.payload,
      };
    },
    saveOperationTimeListDetail(state, action) {
      const { rows: data = [], totalNum: totalCount } = action.payload || {};
      return {
        ...state,
        operationTimeList: {
          data: data?.map?.(item => ({ ...item, id: generateUuid() })),
          total: totalCount,
        },
      };
    },
    saveOperationTimeLog(state, action) {
      const { rows: data = [], totalNum: total } = action.payload || {};
      return {
        ...state,
        operationTimeLog: {
          data: data?.map?.(item => ({ ...item, id: generateUuid() })),
          total,
        },
      };
    },
    saveInfringementList(state, action) {
      const {
        data,
        meta: { total = 0 },
      } = action.payload;
      return {
        ...state,
        infringementList: {
          data: data || [],
          total,
        },
      };
    },
    saveReviewPeople(state, action) {
      return {
        ...state,
        reviewPeople: action.payload || [],
      };
    },
    saveScmRecommend(state, action) {
      const {
        data = [],
        meta: { total = 0, per_page: pageSize = 30, current_page: currentPage = 1 } = {},
      } = action.payload || {};
      return {
        ...state,
        scmRecommend: { data, meta: { total, currentPage, pageSize } },
      };
    },
    saveScmCategories(state, action) {
      return {
        ...state,
        scmCategories: action?.payload?.data || [],
      };
    },
    saveMbProductType(state, { payload }) {
      return {
        ...state,
        mbProductType: payload?.data?.map(i => ({ label: i.category, value: i.category })) || [],
      };
    },
    saveSkuTReviewField(state, { payload }) {
      return {
        ...state,
        canEditTextPicCustom: payload?.data?.can_edit_text_pic_custom,
      };
    },
    saveBiCountries(state, { payload }) {
      return {
        ...state,
        biCountries: Array.isArray(payload) ? payload : [],
      };
    },
  },
};
