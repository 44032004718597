import { fetchPixel } from '@/services/api';

export default {
  namespace: 'pixelManage',

  state: {
    data: [],
    total: 0,
    pixelNameList: [],
    agentNameList: [],
    bmIdList: [],
  },

  effects: {
    *getPixel({ payload }, { call, put }) {
      const response = yield call(fetchPixel, payload);
      yield put({
        type: 'savePixel',
        payload: response,
      });
    },
  },
  reducers: {
    savePixel(state, action) {
      const { data = [], meta } = action.payload || {};
      return {
        ...state,
        data,
        total: meta?.total || 0,
      };
    },
  },
};
