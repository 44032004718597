import { fetchDomainNamePayment } from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'domainNamePayment',
  state: {
    data: [],
  },

  effects: {
    *getDomainName({ payload }, { call, put }) {
      const response = yield call(fetchDomainNamePayment, payload);
      yield put({
        type: 'saveDomainName',
        payload: response,
      });
    },
  },
  reducers: {
    saveDomainName(state, action) {
      const { data = [] } = action.payload;
      return {
        ...state,
        data: data?.map(i => ({ ...i, uuid: generateUuid() })),
      };
    },
    clearDomainName(state) {
      return {
        ...state,
        data: [],
      };
    },
  },
};
