export default [
  {
    Description: '批量处理超过数量限制。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.BundleNumber',
    ErrorMessage: '\\nThe maximum number of domain names allowed to be added is exceeded.',
  },
  {
    Description: '禁止操作：解析记录被锁定',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordLocked',
    ErrorMessage: 'Your request is dinied because the DNS record is locked.',
  },
  {
    Description: '您的帐户存在风险，详情请联系客服。',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.RiskControl',
    ErrorMessage: 'Your account is at risk. Contact customer support for more details.',
  },
  {
    Description: 'CAA记录仅支持云解析DNS收费版',
    Product: 'Alidns',
    ErrorCode: 'InvalidVersion.Unpaid',
    ErrorMessage: 'You need to upgrade VIPDNS',
  },
  {
    Description: '尚未购买网站监控',
    Product: 'Alidns',
    ErrorCode: 'Monitor.Unpaid',
    ErrorMessage: 'You have not purchased site monitoring.',
  },
  {
    Description: '子域名未开启 加权轮询\n',
    Product: 'Alidns',
    ErrorCode: 'DisableDNSSLB',
    ErrorMessage: 'You have not enabled the weighted round robin for the subdomain.',
  },
  {
    Description: '域名未分配DNS，请联系阿里云客服！',
    Product: 'Alidns',
    ErrorCode: 'DNSnotfound',
    ErrorMessage:
      'You have not enabled DNS for the domain. Please contact our support team for your request.',
  },
  {
    Description: 'TXT记录未设置或者暂时还未生效，请稍后再试。',
    Product: 'Alidns',
    ErrorCode: 'IdentityVerification.TxtRecord',
    ErrorMessage:
      'You have not added a TXT record, or it is not in service yet. Please try again later.',
  },
  {
    Description: '无实名验证',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.UserVerification',
    ErrorMessage: 'You have not  performed the real-name authentication.',
  },
  {
    Description: '您有尚未支付的订单，请先处理后再进行此次操作',
    Product: 'Alidns',
    ErrorCode: 'InstanceHasUnpaidOrder',
    ErrorMessage:
      'You have an incomplete order. Please complete the order before continuing with this operation.',
  },
  {
    Description: '地址池个数超过上限',
    Product: 'Alidns',
    ErrorCode: 'GtmAddrPool.Num.ExceedsLimit',
    ErrorMessage: 'You cannot create more address pools.',
  },
  {
    Description: '访问策略超出限制',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Num.ExceedsLimit',
    ErrorMessage: 'You cannot create more access strategies.',
  },
  {
    Description: '只能查询最近90天的记录',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Time',
    ErrorMessage: 'You can only query the record of the latest 90 days.',
  },
  {
    Description: '操作被禁止',
    Product: 'Alidns',
    ErrorCode: 'Forbidden',
    ErrorMessage: 'You are not authorized to operate the specified resource.',
  },
  {
    Description: '没有权限操作此域名，请先执行找回操作！',
    Product: 'Alidns',
    ErrorCode: 'OperationDomain.NoPermission',
    ErrorMessage:
      'You are not authorized to operate the domain. Please retrieve the domain name first.',
  },
  {
    Description: '用户无资源授权，或者该API不支持RAM',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.RAM',
    ErrorMessage:
      "User not authorized to operate on the specified resource, or this API doesn't support RAM.",
  },
  {
    Description: 'URL备案异常',
    Product: 'Alidns',
    ErrorCode: 'URLForwardError.NotVerifyDomain',
    ErrorMessage: 'URL verification exception.',
  },
  {
    Description: 'TXT正文内容的值的长度不能超过253个字符',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TXTValueLength',
    ErrorMessage: 'Up to 253 characters are allowed for a TXT record.',
  },
  {
    Description: '超出限制，批量删除的解析记录不能超过100个',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Batchdelete',
    ErrorMessage: 'Up to 100 DNS records can be selected for batch deletion.',
  },
  {
    Description: '未注册域名，提交注册',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.NeedRegistered',
    ErrorMessage: 'Unregistered domain name. Register now on Alibaba Cloud.',
  },
  {
    Description: '未注册域名，阿里云不支持此域名注册',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Unregistered',
    ErrorMessage: 'Unregistered domain name.',
  },
  {
    Description: '未知错误，请稍后重试！',
    Product: 'Alidns',
    ErrorCode: 'UnKnownError',
    ErrorMessage: 'Unknown error. Try again later.',
  },
  {
    Description: 'AAAA记录不支持主机记录（RR）值含"_"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.AAARecordUnderLine',
    ErrorMessage: 'Underscores (_) are not allowed in the hostname (RR value) of an AAAA record.',
  },
  {
    Description: 'A记录不支持主机记录（RR）值含"_"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.ARecordUnderLine',
    ErrorMessage: 'Underscores (_) are not allowed in the hostname (RR value) of an A record.',
  },
  {
    Description: 'MX记录不支持主机记录（RR）值含"_"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.MXRecordUnderLine',
    ErrorMessage: 'Underscores (_) are not allowed in the hostname (RR value) of a MX record.',
  },
  {
    Description: '解析模板id不属于该用户。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.IncorrectTemplateUser',
    ErrorMessage: 'Unavailable DNS template ID. This ID belongs to another user.',
  },
  {
    Description: '解锁解析失败',
    Product: 'Alidns',
    ErrorCode: 'RecordUnLocked.fail',
    ErrorMessage: 'Unable to unlock the DNS record.',
  },
  {
    Description: 'Whois邮箱未获取到',
    Product: 'Alidns',
    ErrorCode: 'UnableToGetWhoisEmail',
    ErrorMessage: 'Unable to receive Whois email.',
  },
  {
    Description: '获取不到Whois信息',
    Product: 'Alidns',
    ErrorCode: 'UnabletoGetWoisInfo',
    ErrorMessage: 'Unable to obtain domain information from the Whois system.',
  },
  {
    Description: '锁定解析失败',
    Product: 'Alidns',
    ErrorCode: 'RecordLocked.fail',
    ErrorMessage: 'Unable to lock the DNS record.',
  },
  {
    Description: '启用解析失败',
    Product: 'Alidns',
    ErrorCode: 'RecordEnable.fail',
    ErrorMessage: 'Unable to enable the DNS record.',
  },
  {
    Description: 'URL转发修改失败',
    Product: 'Alidns',
    ErrorCode: 'URLForwardChangeError',
    ErrorMessage: 'Unable to edit the URL forwarding record.',
  },
  {
    Description: '修改解析记录失败',
    Product: 'Alidns',
    ErrorCode: 'chgRRfail',
    ErrorMessage: 'Unable to edit the record.',
  },
  {
    Description: '暂停解析失败',
    Product: 'Alidns',
    ErrorCode: 'RecordDisable.fail',
    ErrorMessage: 'Unable to disable the DNS record.',
  },
  {
    Description: 'URL转发删除失败！',
    Product: 'Alidns',
    ErrorCode: 'URLForwardDeleteError',
    ErrorMessage: 'Unable to delete the URL forwarding record.',
  },
  {
    Description: '删除解析记录失败',
    Product: 'Alidns',
    ErrorCode: 'delRRfail',
    ErrorMessage: 'Unable to delete the record.',
  },
  {
    Description: '删除域名失败！',
    Product: 'Alidns',
    ErrorCode: 'delSoafail',
    ErrorMessage: 'Unable to delete the domain name.',
  },
  {
    Description: '创建域名失败',
    Product: 'Alidns',
    ErrorCode: 'addSoafail',
    ErrorMessage: 'Unable to create the domain name.',
  },
  {
    Description: 'URL转发添加失败',
    Product: 'Alidns',
    ErrorCode: 'URLForwardAddError',
    ErrorMessage: 'Unable to add the URL forwarding record.',
  },
  {
    Description: '添加解析记录失败！',
    Product: 'Alidns',
    ErrorCode: 'addRRfail',
    ErrorMessage: 'Unable to add the record.',
  },
  {
    Description: '超出解析记录数量最大值90',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Record',
    ErrorMessage:
      'Unable to add the record because the number of records with the same settings (Type, RR, Line) exceeds the limit (90).',
  },
  {
    Description: '该域名为受限域名，不能设置解析，故不能添加到该解析列表',
    Product: 'Alidns',
    ErrorCode: 'RestrictedDomain',
    ErrorMessage:
      'Unable to add the domain name to the DNS list because it is a restricted domain name.',
  },
  {
    Description: "记录值中不能有连续的'.'字符。",
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.ValueDupDot',
    ErrorMessage: 'Two consecutive periods (.)  are not allowed in the record.',
  },
  {
    Description: '该操作被风险控制系统禁止。',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.RiskControl',
    ErrorMessage: 'This operation is deinied by the Alibaba Cloud Risk Control system.',
  },
  {
    Description: '“{0}”记录与“{1}”记录冲突',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordConflict',
    ErrorMessage: 'This Domain Record Is in Conflict with Other Records.',
  },
  {
    Description: '当前域名已绑定云解析DNS实例，更换实例可能会改变当前记录设置，请确认！',
    Product: 'Alidns',
    ErrorCode: 'InvalidBinding.BoundDomain',
    ErrorMessage:
      'This Domain is already associated with another advanced DNS instance. Changing the associated instance may affect the DNS setting of this domain name. Are you sure you want to use this domain name?',
  },
  {
    Description: '域名不是阿里云域名',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.NotHichinaDomain',
    ErrorMessage:
      'This action cannot be performed on the domain. Only hichina domains permit this action.',
  },
  {
    Description: '泛解析不允许修改权重',
    Product: 'Alidns',
    ErrorCode: 'PanDomainRecord',
    ErrorMessage: 'The weight value of wildcard DNS records cannot be modified.',
  },
  {
    Description: '该解析的权重值不合法，权重仅允许1-100间整数',
    Product: 'Alidns',
    ErrorCode: 'Invalidweightvalue',
    ErrorMessage: 'The weight is invalid. It must be an integer from1 to 100.',
  },
  {
    Description: '无效的权重值',
    Product: 'Alidns',
    ErrorCode: 'InvalidWeigth',
    ErrorMessage: 'The weight is invalid.',
  },
  {
    Description: '无效的版本',
    Product: 'Alidns',
    ErrorCode: 'NoSuchVersion',
    ErrorMessage: 'The version number is Invalid.',
  },
  {
    Description:
      "主机记录（RR）值合法字符包含a--z、A--Z、0--9、'-' 、'_' 、'.'、'*'、'@'、'中文汉字'。",
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.Format',
    ErrorMessage:
      'The valid characters can be used in a RR value include letters a-z and A-Z, numbers 0-9, Chinese characters, hyphen (-), underscore (_), period (.), asterisk (*), and at sign (@).',
  },
  {
    Description: 'URL转发不支持非default线路',
    Product: 'Alidns',
    ErrorCode: 'URLForwardError.NotDefaultLine',
    ErrorMessage: 'The URL forwarding record only supports default line.',
  },
  {
    Description: 'URL跳转不支持泛解析',
    Product: 'Alidns',
    ErrorCode: 'URLForwardError.PanRecord',
    ErrorMessage: 'The URL forwarding does not support wildcard DNS.',
  },
  {
    Description: 'CNAME记录无法使用[开启/关闭]功能',
    Product: 'Alidns',
    ErrorCode: 'DnsSLB.InvalidType',
    ErrorMessage: 'The turn on/off option is not available for CNAME records.',
  },
  {
    Description: '超出当前配置的最小允许TTL值 {0}。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.TTL',
    ErrorMessage: 'The TTL value is less than the minimum value allowed.',
  },
  {
    Description: '无效的TraceId',
    Product: 'Alidns',
    ErrorCode: 'InvalidTraceId',
    ErrorMessage: 'The TraceId is invalid.',
  },
  {
    Description: '超出当前配置的最大允许子域名级数 {0}。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.SubDomain',
    ErrorMessage: 'The subdomain level exceeds the limit.',
  },
  {
    Description: '无效的状态',
    Product: 'Alidns',
    ErrorCode: 'InvalidStatus',
    ErrorMessage: 'The status is invalid.',
  },
  {
    Description: 'SRV的值不能为空',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.SRVValueEmpty',
    ErrorMessage: 'The SRV record is required.',
  },
  {
    Description: 'SRV记录值格式错误',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.SRVValueFormat',
    ErrorMessage: 'The SRV record is incorrectly formatted.',
  },
  {
    Description: '无效的版本号',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.VersionCode',
    ErrorMessage: 'The specified version is invalid.',
  },
  {
    Description: '最小可用地址数量超过限制',
    Product: 'Alidns',
    ErrorCode: 'GtmAddrPool.Invalid.MinAvailableAddrNum',
    ErrorMessage: 'The specified value for minimum available addresses is invalid.',
  },
  {
    Description: '未被授权的用户ID',
    Product: 'Alidns',
    ErrorCode: 'IllegalUser',
    ErrorMessage: 'The specified user ID is not authorized.',
  },
  {
    Description: '无效的用户id',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.UserId',
    ErrorMessage: 'The specified user ID is invalid.',
  },
  {
    Description: '无效的用户id',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.UserId',
    ErrorMessage: 'The specified user ID is invalid.',
  },
  {
    Description: '无效的TTl',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.Ttl',
    ErrorMessage: 'The specified TTL is invalid.',
  },
  {
    Description: '无效的策略名称',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.Name',
    ErrorMessage: 'The specified strategy name is invalid.',
  },
  {
    Description: '策略名称超长',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Name.ExceedsLimit',
    ErrorMessage: 'The specified strategy name exceeds the character limit.',
  },
  {
    Description: '无效的策略模式',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.StrategyMode',
    ErrorMessage: 'The specified strategy mode is invalid.',
  },
  {
    Description: '主域名字符超出限制',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.UserDomainNameExceedsLimit',
    ErrorMessage: 'The specified primary domain name exceeds the character limit.',
  },
  {
    Description: '主域名无效',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.UserDomainName',
    ErrorMessage: 'The specified primary domain is invalid.',
  },
  {
    Description: '端口号不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Port',
    ErrorMessage: 'The specified port number is invalid.',
  },
  {
    Description: '健康检查ping包数目不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.PacketNum',
    ErrorMessage: 'The specified number of ping packets for the health check is invalid.',
  },
  {
    Description: '健康检查监控节点个数不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.NodeNum.ExceedsLimit',
    ErrorMessage: 'The specified number of monitoring nodes for the health check is invalid.',
  },
  {
    Description: '无效的实例名称',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.Name',
    ErrorMessage: 'The specified instance name is invalid.',
  },
  {
    Description: '实例名称字符超出限制',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.InstanceName.ExceedsLimit',
    ErrorMessage: 'The specified instance name exceeds the character limit.',
  },
  {
    Description: '无效的实例Id',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.InstanceId',
    ErrorMessage: 'The specified instance ID is invalid.',
  },
  {
    Description: '无效的实例Id',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.InstanceId',
    ErrorMessage: 'The specified instance ID is invalid.',
  },
  {
    Description: '实例不存在',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.NotExists',
    ErrorMessage: 'The specified instance does not exist.',
  },
  {
    Description: '无效的id',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.Id',
    ErrorMessage: 'The specified ID is invalid.',
  },
  {
    Description: 'id不合法',
    Product: 'Alidns',
    ErrorCode: 'Gtm.Invalid.Id',
    ErrorMessage: 'The specified ID is invalid.',
  },
  {
    Description: '健康检查用户id不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.UserId',
    ErrorMessage: 'The specified health user ID does not exist.',
  },
  {
    Description: '健康检查URL路径不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Path',
    ErrorMessage: 'The specified health check URL path is invalid.',
  },
  {
    Description: '健康检查状态不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Status',
    ErrorMessage: 'The specified health check status is invalid.',
  },
  {
    Description: '健康检查返回码不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Code',
    ErrorMessage: 'The specified health check return code is invalid.',
  },
  {
    Description: '健康检查协议不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Protocol',
    ErrorMessage: 'The specified health check protocol is invalid.',
  },
  {
    Description: '健康检查丢包率不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.PacketLossRate',
    ErrorMessage: 'The specified health check packet loss ratio is invalid.',
  },
  {
    Description: '健康检查名称不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Name',
    ErrorMessage: 'The specified health check name is invalid.',
  },
  {
    Description: '健康检查间隔不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Interval',
    ErrorMessage: 'The specified health check interval value is invalid.',
  },
  {
    Description: '健康检查id不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Id',
    ErrorMessage: 'The specified health check ID is invalid.',
  },
  {
    Description: '健康检查host不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.Host',
    ErrorMessage: 'The specified health check host is invalid.',
  },
  {
    Description: '健康检查失败比率不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Invalid.FailureRate',
    ErrorMessage: 'The specified health check failure ratio is invalid.',
  },
  {
    Description: '健康检查配置不存在',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.NotExists',
    ErrorMessage: 'The specified health check configuration does not exist.',
  },
  {
    Description: '健康检查已经存在',
    Product: 'Alidns',
    ErrorCode: 'GtmHealthCheck.Exist',
    ErrorMessage: 'The specified health check already exists.',
  },
  {
    Description: 'failover地址池无效',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.FailoverPool',
    ErrorMessage: 'The specified failover address pool is invalid.',
  },
  {
    Description: '到期日无效',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.ExpireDate',
    ErrorMessage: 'The specified expiration date is invalid.',
  },
  {
    Description: '您操作的域名已经不存在，请刷新页面重试。',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.NoExist',
    ErrorMessage: 'The specified domain name does not exist. Refresh the page and try again.',
  },
  {
    Description: '默认地址池和failover地址池一样',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.AddrPool.Same',
    ErrorMessage: 'The specified default address pool and failover address pool are the same.',
  },
  {
    Description: 'cname值无效',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.Cname',
    ErrorMessage: 'The specified CNAME is invalid.',
  },
  {
    Description: '批量操作参数非法',
    Product: 'Alidns',
    ErrorCode: 'BatchOperation.illegal',
    ErrorMessage: 'The specified BatchOperation is invalid.',
  },
  {
    Description: '无效的均衡策略',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.LbaStrategy',
    ErrorMessage: 'The specified balancing strategy is invalid.',
  },
  {
    Description: '无效的报警组',
    Product: 'Alidns',
    ErrorCode: 'GtmInstance.Invalid.AlertGroup',
    ErrorMessage: 'The specified alarm group is invalid.',
  },
  {
    Description: '地址权重不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmAddr.Invalid.LbaWeight',
    ErrorMessage: 'The specified address weight is invalid.',
  },
  {
    Description: '地址池用户id不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmAddrPool.Invalid.UserId',
    ErrorMessage: 'The specified address pool user ID is invalid.',
  },
  {
    Description: '地址池类型不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmAddrPool.Invalid.Type',
    ErrorMessage: 'The specified address pool type is invalid.',
  },
  {
    Description: '地址池name不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmAddrPool.Invalid.Name',
    ErrorMessage: 'The specified address pool name is invalid.',
  },
  {
    Description: '地址池id不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmAddrPool.Invalid.Id',
    ErrorMessage: 'The specified address pool ID is invalid.',
  },
  {
    Description: '地址池不存在',
    Product: 'Alidns',
    ErrorCode: 'GtmAddrPool.NotExists',
    ErrorMessage: 'The specified address pool does not exist.',
  },
  {
    Description: '地址池已经关联访问策略,不能删除',
    Product: 'Alidns',
    ErrorCode: 'GtmAddrPool.RelatedAccessStrategy',
    ErrorMessage:
      'The specified address pool already has an access strategy and cannot be deleted.',
  },
  {
    Description: '地址mode不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmAddr.Invalid.Mode',
    ErrorMessage: 'The specified address mode is invalid.',
  },
  {
    Description: '地址值不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmAddr.Invalid.Value',
    ErrorMessage: 'The specified address is invalid.',
  },
  {
    Description: '地址不存在',
    Product: 'Alidns',
    ErrorCode: 'GtmAddr.NotExists',
    ErrorMessage: 'The specified address does not exist.',
  },
  {
    Description: '地址值重复',
    Product: 'Alidns',
    ErrorCode: 'GtmAddr.Value.Duplicate',
    ErrorMessage: 'The specified address already exists.',
  },
  {
    Description: '无效的访问策略id',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.Id',
    ErrorMessage: 'The specified access strategy ID is invalid.',
  },
  {
    Description: '访问策略不存在',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.NotExists',
    ErrorMessage: 'The specified access strategy does not exist.',
  },
  {
    Description: '无效的访问区域列表',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.AccessLine',
    ErrorMessage: 'The specified access region list is invalid.',
  },
  {
    Description: '访问区域冲突',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.AccessLine.Conflict',
    ErrorMessage: 'The specified access region is in conflict with an existing region.',
  },
  {
    Description: '该访问区域已被占用',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.AccessLine.Forbidden',
    ErrorMessage: 'The specified access region is already in use.',
  },
  {
    Description: '无效的访问模式',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.AccessMode',
    ErrorMessage: 'The specified access mode is invalid.',
  },
  {
    Description: '特殊域名绑定无效',
    Product: 'Alidns',
    ErrorCode: 'InvalidBinding.SpecialDomain',
    ErrorMessage: 'The special domain name is invalid to bind on the DNS product.',
  },
  {
    Description: '服务器当前无法处理请求',
    Product: 'Alidns',
    ErrorCode: 'ServiceUnavailable',
    ErrorMessage: 'The server cannot process your request at this time.',
  },
  {
    Description: '服务器无法完成对请求的处理',
    Product: 'Alidns',
    ErrorCode: 'InternalError',
    ErrorMessage: 'The server cannot process this request.',
  },
  {
    Description: '无可用的值（指定的解析记录已删除或暂停）',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NoRecord',
    ErrorMessage: 'The RR value has been deleted or disabled.',
  },
  {
    Description: '禁止操作：域名已过期，解析已被锁定。',
    Product: 'Alidns',
    ErrorCode: 'DomainExpiredDNSForbidden',
    ErrorMessage:
      'The request is denied because the domain name has expired and the DNS record is locked.',
  },
  {
    Description: '解析类型不能为空',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TypeEmpty',
    ErrorMessage: 'The record type is required.',
  },
  {
    Description: '域名数目已经达到产品定义的最大值',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.domainlist',
    ErrorMessage: 'The product has reached the maximum number of domain names.',
  },
  {
    Description: '缺少参数：{0}',
    Product: 'Alidns',
    ErrorCode: 'MissingParameter',
    ErrorMessage: 'The parameter {0} is required for this request.',
  },
  {
    Description: '参数取值{0}无效',
    Product: 'Alidns',
    ErrorCode: 'InvalidParameter',
    ErrorMessage: 'The parameter value {0} is invalid.',
  },
  {
    Description:
      '操作失败！产品配置发生变化，系统正在为您切换，切换期间，暂不支持解析的增删改操作！请您稍后再试！',
    Product: 'Alidns',
    ErrorCode: 'RecordForbidden.DNSChange',
    ErrorMessage: 'The operation cannot be performed because the DNS is upgrading.',
  },
  {
    Description:
      '您的域名正在遭受恶意攻击，且攻击量已超过您购买的域名解析防护量，当前处于黑洞状态（暂停解析），黑洞期间暂不支持解析的增删改操作！建议您立即升级配置或者等待攻击结束',
    Product: 'Alidns',
    ErrorCode: 'RecordFobidden.BlackHole',
    ErrorMessage: 'The operation cannot be performed because some records are in black hole.',
  },
  {
    Description: '超出当前配置的最大URL转发设置数量 {0}。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.URLRecord',
    ErrorMessage: 'The number of URL forwarding records exceeds the maximum number.',
  },
  {
    Description: '解析模板个数超过最大限制。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.QuotaExceededTemplate',
    ErrorMessage: 'The number of templates exceeds the limit.',
  },
  {
    Description: '解析模板记录长度超过最大限制。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.QuotaExceededTemplateNameLength',
    ErrorMessage: 'The number of templates exceeds the limit.',
  },
  {
    Description: '解析模板记录个数超过最大限制。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.QuotaExceededTemplateRecord',
    ErrorMessage: 'The number of template records exceeds the limit.',
  },
  {
    Description: '超出数量限制，当前配置的最大监控任务数量为 {0} 个',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitoringTask',
    ErrorMessage: 'The number of monitor tasks exceeds the maximum number ({0}).',
  },
  {
    Description: '超出数量限制，当前配置的监控节点最多为 {0} 个',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitorNodes',
    ErrorMessage: 'The number of monitor nodes exceeds the maximum number ({0}).',
  },
  {
    Description: '超出数量限制，当前配置的最大备用 IP/CNAME 数量为 {0} 个',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitoringIp',
    ErrorMessage: 'The number of backup IP or CNAME exceeds the maximum number.',
  },
  {
    Description: '地址池的地址个数不合法',
    Product: 'Alidns',
    ErrorCode: 'GtmAddr.Num.ExceedsLimit',
    ErrorMessage: 'The number of addresses in the specified address pool exceeds the limit.',
  },
  {
    Description: '分组名称为空',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainGroup.EmptyName',
    ErrorMessage: 'The name of the domain group is empty.',
  },
  {
    Description: '域名分组不存在',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainGroup.NotExist',
    ErrorMessage: 'The name of the domain group does not exist.',
  },
  {
    Description: '分组名称已存在',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainGroup.Duplicate',
    ErrorMessage: 'The name of the domain group already exists.',
  },
  {
    Description: '要操作的监控不存在！',
    Product: 'Alidns',
    ErrorCode: 'Monitor.TaskNoExists',
    ErrorMessage: 'The monitoring task does not exist.',
  },
  {
    Description: '监控项不完全属于同一个用户',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NotSameUser',
    ErrorMessage: 'The monitored subdomains does not belong to the same user.',
  },
  {
    Description: '监控项不完全属于同一个域名',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NotSameDomain',
    ErrorMessage: 'The monitored subdomains does not belong to the same domain name.',
  },
  {
    Description: '超出当前配置的最大A记录设置数量 {0}。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.ARecord',
    ErrorMessage: 'The maximum number of {0}  records is exceeded.',
  },
  {
    Description: '批量操作的数量超过限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.BatchNumber',
    ErrorMessage: 'The maximum number of selections available for the batch operation is exceeded.',
  },
  {
    Description: '云解析添加监控超过限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Monitor',
    ErrorMessage: 'The maximum number of monitors is exceeded.',
  },
  {
    Description: 'NS记录的值的长度不能超过253个字符',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NSValueLength',
    ErrorMessage: 'The maximum length of the value is 253 characters for a NS record.',
  },
  {
    Description: 'URL转发记录的值最长不能超过500个字符，请重新填写。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.FWDValueLength',
    ErrorMessage:
      'The maximum length of the value for a URL forwarding record is 500 characters. Re-enter the value.',
  },
  {
    Description: '“.”分割的每个字符串长度最长为63字符。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.Length',
    ErrorMessage:
      'The maximum length of each part (separated by periods) in the string is 63 characters.',
  },
  {
    Description: '分组名称长度超过限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.GroupNameLength',
    ErrorMessage: 'The length of the group name exceeds the limit (20).',
  },
  {
    Description: '当前版本不支持的解析线路',
    Product: 'Alidns',
    ErrorCode: 'UnsupportedLine',
    ErrorMessage: 'The ISP line is not supported in the current version.',
  },
  {
    Description: '当前云解析配置不支持此线路',
    Product: 'Alidns',
    ErrorCode: 'NotSupport.Line',
    ErrorMessage: 'The ISP is invalid for the DNS.',
  },
  {
    Description: '主机记录（RR）不能为空值，请您输入。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.RrEmpty',
    ErrorMessage: 'The hostname (RR value) is required.',
  },
  {
    Description: '免费版解析暂不支持监控操作。',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NotSupport',
    ErrorMessage: 'The free version of DNS does not support monitoring.',
  },
  {
    Description: '找回白名单域名不允许找回',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.ProtectedDomain',
    ErrorMessage: 'The domain names in the whitelist cannot be retrieved.',
  },
  {
    Description: '被找回域名被删除，此次找回已失效',
    Product: 'Alidns',
    ErrorCode: 'InvalidRetrievedomain.delete',
    ErrorMessage: 'The domain name you want to retrieve has been deleted.',
  },
  {
    Description: '域名不能为空，请选择域名。',
    Product: 'Alidns',
    ErrorCode: 'DeleteDomain.Empty',
    ErrorMessage: 'The domain name is required. Please select a domain name.',
  },
  {
    Description: '域名不能为空',
    Product: 'Alidns',
    ErrorCode: 'DomainEmpty',
    ErrorMessage: 'The domain name is required.',
  },
  {
    Description: '域名不是阿里云域名。',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.NotAliyunDomain',
    ErrorMessage: 'The domain name is not registered with Alibaba Cloud.',
  },
  {
    Description: '域名被其他账户添加',
    Product: 'Alidns',
    ErrorCode: 'DomainAddedByOthers',
    ErrorMessage: 'The domain name has been added by another user.',
  },
  {
    Description: '域名不存在！',
    Product: 'Alidns',
    ErrorCode: 'DomainNotFound',
    ErrorMessage: 'The domain name does not exist.',
  },
  {
    Description: '该域名非本账户域名',
    Product: 'Alidns',
    ErrorCode: 'AliyunDomainAddedByOthers',
    ErrorMessage: 'The domain name does not belong to the current user.',
  },
  {
    Description:
      '该域名非本账户域名，阿里云域名不能进行跨账号解析，您可以将该域名进行账号间转移来实现域名绑定与解析设置。',
    Product: 'Alidns',
    ErrorCode: 'IncorrectDomainUser',
    ErrorMessage:
      'The domain name belongs to other users. Transfer the domain name to the current user and then try the binding and setting actions.',
  },
  {
    Description: '域名已存在于解析列表中，无需再次添加。点击解析列表中的域名即可开始解析',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Duplicate',
    ErrorMessage: 'The domain name already exists in the DNS list.',
  },
  {
    Description: '域名含有锁定解析',
    Product: 'Alidns',
    ErrorCode: 'Fobidden.RecordLocked',
    ErrorMessage: 'The domain has a locked record.',
  },
  {
    Description: '系统暂不支持该域名后缀解析',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Suffix',
    ErrorMessage: 'The DNS suffix is not supported.',
  },
  {
    Description: '您操作的解析记录已经不存在，请刷新页面重试',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NoExist',
    ErrorMessage: 'The DNS record no longer exists. Refresh the page and try again.',
  },
  {
    Description: '解析记录被暂停',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordDisable',
    ErrorMessage: 'The DNS record is suspended.',
  },
  {
    Description: '解析记录值（RR value）的输入内容不合法或格式错误',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.Value',
    ErrorMessage: 'The DNS record is invalid or in the wrong format.',
  },
  {
    Description: '解析记录在本账户下不存在',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordNotBelongToUser',
    ErrorMessage: 'The DNS record does not exist in your account.',
  },
  {
    Description: '解析记录已存在',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordDuplicate',
    ErrorMessage: 'The DNS record already exists.',
  },
  {
    Description: '云解析产品不存在',
    Product: 'Alidns',
    ErrorCode: 'InvalidDnsProduct',
    ErrorMessage: 'The DNS product does not exist.',
  },
  {
    Description: '默认地址池无效',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.Invalid.DefaultPool',
    ErrorMessage: 'The default address pool is invalid.',
  },
  {
    Description: '数据格式不正确',
    Product: 'Alidns',
    ErrorCode: 'DataFormatError',
    ErrorMessage: 'The data is incorrectly formatted.',
  },
  {
    Description: '当前访问状态是Failover地址池',
    Product: 'Alidns',
    ErrorCode: 'GtmAccessStrategy.AccessStatus.Failover',
    ErrorMessage: 'The current access status is failover.',
  },
  {
    Description: '全局负载均衡暂不支持CNAME记录，请关闭CNAME记录后再尝试开启。',
    Product: 'Alidns',
    ErrorCode: 'LineDnsSlb.Cname',
    ErrorMessage:
      'The cross-region load balancer does not support CNAME records. Disable CNAME records and try again.',
  },
  {
    Description: '需要通过域名持有者身份验证，才可以进行添加。\r\n点击这里验证身份',
    Product: 'Alidns',
    ErrorCode: 'Authentication.domain',
    ErrorMessage:
      'The authentication of the registrant is required before adding the domain. Click here to start authentication.',
  },
  {
    Description: 'URL转发记录的值为域名或URL地址，请重新填写。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.FWDValue',
    ErrorMessage:
      'Specify a domain name or an URL address for the value of a URL forwarding record.',
  },
  {
    Description: '部分记录字段内容非法',
    Product: 'Alidns',
    ErrorCode: 'Record.illegal',
    ErrorMessage: 'Some fields of the record are invalid.',
  },
  {
    Description: '部分记录字段为空！',
    Product: 'Alidns',
    ErrorCode: 'Record.empty',
    ErrorMessage: 'Some fields of the record are empty.',
  },
  {
    Description: '域名解析防护进入黑洞',
    Product: 'Alidns',
    ErrorCode: 'RecordForbidden.BlackHole',
    ErrorMessage: 'Some DNS records are in the DNS server blackhole.',
  },
  {
    Description: '不支持短消息通知类型',
    Product: 'Alidns',
    ErrorCode: 'NotSupport.SMS',
    ErrorMessage: 'SMS notice is not supported.',
  },
  {
    Description: '操作扫描任务出错！',
    Product: 'Alidns',
    ErrorCode: 'ScanningOperationError',
    ErrorMessage: 'Scanning exception.',
  },
  {
    Description: '您这个时段的流量已经超限。如果不能满足现有业务要求可以提工单进行申请。',
    Product: 'Alidns',
    ErrorCode: 'Throttling',
    ErrorMessage: 'Request refused by the traffic control system.',
  },
  {
    Description: '90秒内不允许重复找回',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.TooOfen',
    ErrorMessage: 'Please wait 90 seconds before retrieving again.',
  },
  {
    Description: '全局负载均衡需要主机记录开启网站监控，请前往配置。',
    Product: 'Alidns',
    ErrorCode: 'LineDnsSlb.Monitor',
    ErrorMessage:
      'Please add the hostname to the site monitoring list before enabling the cross-region load balancer.',
  },
  {
    Description: '用户名下没有监控',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NotExist',
    ErrorMessage: 'No site has been added for monitoring  in your account.',
  },
  {
    Description:
      'A(AAAA)记录数量超出限制，云解析免费版可以添加10个相同主机记录相同线路的A(AAAA)记录。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.FreeDnsRecord',
    ErrorMessage:
      'No more than 10 A (AAAA) records can be added with same hostname and line. Try Advanced DNS for more records.',
  },
  {
    Description: '没有添加过默认线路，请先添加默认线路。',
    Product: 'Alidns',
    ErrorCode: 'DefaultlineNotadded',
    ErrorMessage: 'No default line has been added. Please dad a default line first.',
  },
  {
    Description: '监控的自定义切换无备用IP！',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NoBackupIp',
    ErrorMessage: 'No backup IP for the custom switching of monitoring.',
  },
  {
    Description: '解析模板记录不允许的解析类型。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidType',
    ErrorMessage:
      'Invalid type for a template record. Only CNAME, A, MX, and TXT records are supported.',
  },
  {
    Description: '无效的解析ttl',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.TTL',
    ErrorMessage: 'Invalid TTL.',
  },
  {
    Description: '解析模板记录不允许的TTL。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidTTL',
    ErrorMessage: 'Invalid TTL for a template record.',
  },
  {
    Description: '无效的签名',
    Product: 'Alidns',
    ErrorCode: 'SignatureDoesNotMatch',
    ErrorMessage:
      'Invalid signature. Refer to the authentication section in the API reference for details.',
  },
  {
    Description: '无效的接口',
    Product: 'Alidns',
    ErrorCode: 'UnsupportedOperation',
    ErrorMessage: 'Invalid port.',
  },
  {
    Description: 'MX解析无效的优先级',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.Priority',
    ErrorMessage: 'Invalid MX Priority.',
  },
  {
    Description: '超出线路限制，当前配置不支持设置此线路类型',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.Line',
    ErrorMessage: 'Invalid line.',
  },
  {
    Description: '解析模板记录不允许的线路类型。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidLine',
    ErrorMessage: 'Invalid ISP line for a template record.',
  },
  {
    Description: '设置异常，当前配置允许的监控间隔为 {0} 分钟。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitoringFrequency',
    ErrorMessage: 'Invalid input. The monitoring frequency allowed is {0} minutes.',
  },
  {
    Description: '主机记录（RR）值的输入内容不合法、或超出限制',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.RR',
    ErrorMessage: 'Invalid hostname (RR value).',
  },
  {
    Description: '不支持此格式：如为punycode码，请输入中文域名',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Idn',
    ErrorMessage: 'Invalid format. For punycode, enter a Chinese domain name.',
  },
  {
    Description: '域名格式错误：请以“.”进行分隔。',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Label',
    ErrorMessage: 'Invalid domain name. Use periods (.) to separate each part of the domain name.',
  },
  {
    Description: '域名格式错误：“.”仅作为分隔符使用，且不能连续；',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.DupDot',
    ErrorMessage:
      'Invalid domain name. Use periods (.) only as separators and do not use consecutive periods.',
  },
  {
    Description:
      '域名格式错误：以“."分割的每部分不超过63字符，包含点的全长不超过255字符（含最后的“.“）',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Length',
    ErrorMessage:
      'Invalid domain name. Each part of the domain name (separated by periods) can contain up to 63 characters, and the maximum length of the domain name with periods is 255 characters.',
  },
  {
    Description: '域名格式错误：“-”不能放到开头和结尾',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.MiddleLine',
    ErrorMessage: 'Invalid domain name. Do not use hyphens (-) at the start and end of the string.',
  },
  {
    Description: '域名格式错误：字符串不能以“."开始',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.StartWithDot',
    ErrorMessage: 'Invalid domain name. Do not start with a period (.).',
  },
  {
    Description: '域名格式错误：不能全为数字',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Number',
    ErrorMessage: 'Invalid domain name. Do not contain only numbers.',
  },
  {
    Description: '域名格式错误：域名一般由英文字母、汉字、阿拉伯数字、“.”、“－”组成。',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Illegalcharacter',
    ErrorMessage:
      'Invalid domain name. A domain name is not case-sensitive and can contain the letters a-z, numbers 0-9, Chinese characters, and periods (.).',
  },
  {
    Description: '域名格式错误：请输入正确的域名格式',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Format',
    ErrorMessage: 'Invalid domain name.',
  },
  {
    Description: '解析模板记录id不存在。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidTemplateRecordId',
    ErrorMessage: 'Invalid DNS template record ID.',
  },
  {
    Description: '解析模板id不存在。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidTemplateId',
    ErrorMessage: 'Invalid DNS template ID.',
  },
  {
    Description: '主机记录类型输入无效，主机记录类型只支持NS、CNAME、A记录、AAAA、MX、TXT、SRV记录',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.Type',
    ErrorMessage:
      'Invalid DNS record type. Available types include NS, CNAME, A, AAAA, TXT, and SRV.',
  },
  {
    Description: 'TXT正文内容不能包含非法字符。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TXTValueIllegalChar',
    ErrorMessage: 'Invalid characters are not allowed in a TXT record.',
  },
  {
    Description: '无效的Access Key',
    Product: 'Alidns',
    ErrorCode: 'InvalidAccessKeyId.NotFound',
    ErrorMessage: 'Invalid Access Key.',
  },
  {
    Description: '域名分组数量超过限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.GroupCount',
    ErrorMessage: 'Group number exceeds the limit (100).',
  },
  {
    Description: '请输入正确的IPv6地址。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.AAAAValue',
    ErrorMessage: 'Enter a correct IPv6 address.',
  },
  {
    Description: '请输入正确的IP地址，例如:10.10.10.10。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.AValue',
    ErrorMessage: 'Enter a correct IP address, such as \\',
  },
  {
    Description: '记录值输入有误，请输入正确的域名，例如:www.net.cn。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.MXValue',
    ErrorMessage: 'Enter a correct domain name for the value, such as \\',
  },
  {
    Description: 'NS记录的值为域名，请输入正确域名。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NSValue',
    ErrorMessage: 'Enter a correct domain name for the value of a NS record.',
  },
  {
    Description: '解析模板名不能重复。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.DuplicateName',
    ErrorMessage: 'Duplicated name.',
  },
  {
    Description: '阿里云注册域名，不能进行此操作 。',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.DomainType',
    ErrorMessage: 'Domain Names Registered on Alibaba Cloud Do Not Support This Operation.',
  },
  {
    Description: "RR值不能有连续的'.'字符。",
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.DupDot',
    ErrorMessage: 'Do not use two consecutive periods (.) in the RR value.',
  },
  {
    Description: 'RR值中逗点之间不允许为空。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.Empty',
    ErrorMessage: 'Do not use two consecutive commas (,) in the RR value.',
  },
  {
    Description: 'RR值中不能以字符 “-”开头和结尾。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.MiddleLine',
    ErrorMessage: 'Do not start or end the RR value with a hyphen (-).',
  },
  {
    Description: 'RR值不能以“逗点”符号开头或结尾。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.Format.Dot',
    ErrorMessage: 'Do not start or end the RR value with a comma (,).',
  },
  {
    Description: 'URL转发目标地址中不能包含中文字符',
    Product: 'Alidns',
    ErrorCode: 'URLForwardError.ChineseChar',
    ErrorMessage:
      'Chinese characters are not allowed in the target address of a URL forwarding record.',
  },
  {
    Description: 'TXT正文内容不能包含中文字符',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TXTValueUnicode',
    ErrorMessage: 'Chinese characters are not allowed for the TXT record.',
  },
  {
    Description: '您没有购买地域线路，无法切换线路类型',
    Product: 'Alidns',
    ErrorCode: 'NotSupport.LineType',
    ErrorMessage: 'Cannot switch to the regional ISP you have not purchased.',
  },
  {
    Description: '查询实例未付款订单失败。',
    Product: 'Alidns',
    ErrorCode: 'QueryInstanceUnpaidOrderFailed',
    ErrorMessage: 'Cannot query the unpaid orders of the instance.',
  },
  {
    Description: '查询续费价格失败。',
    Product: 'Alidns',
    ErrorCode: 'QueryRenewPriceFailed',
    ErrorMessage: 'Cannot query the renewal price.',
  },
  {
    Description: '批量操作线程已满，请稍后重试！',
    Product: 'Alidns',
    ErrorCode: 'OperationThreadInUsed',
    ErrorMessage:
      'Cannot perform the batch operation because all threads are in use. Try again later.',
  },
  {
    Description: '批量导入系统繁忙，请稍后重试！',
    Product: 'Alidns',
    ErrorCode: 'BatchOperation.timeout',
    ErrorMessage:
      'Cannot perform the batch import due to system throttlling. Please try again later.',
  },
  {
    Description: '获取运营商列表失败',
    Product: 'Alidns',
    ErrorCode: 'Monitor.GetISPFailed',
    ErrorMessage: 'Cannot obtain the list of ISPs.',
  },
  {
    Description: '获取运营商可用的城市列表失败',
    Product: 'Alidns',
    ErrorCode: 'Monitor.GetISPCityList',
    ErrorMessage: 'Cannot obtain the list of available cities of the ISP.',
  },
  {
    Description: '获取可用节点失败！',
    Product: 'Alidns',
    ErrorCode: 'Monitor.GetMonitorNodes',
    ErrorMessage: 'Cannot obtain the available nodes.',
  },
  {
    Description: '获取监控地图详情失败',
    Product: 'Alidns',
    ErrorCode: 'Monitor.GetMonitorMap',
    ErrorMessage: 'Cannot obtain details of the monitor map.',
  },
  {
    Description: '打开 加权轮询 失败。',
    Product: 'Alidns',
    ErrorCode: 'EnableDnsSlbFailed',
    ErrorMessage: 'Cannot enable the weighted round robin.',
  },
  {
    Description: 'A/AAAA记录条数超过限制，无法开启 加权轮询',
    Product: 'Alidns',
    ErrorCode: 'LineDnsSlb.QuotaExceeded',
    ErrorMessage:
      'Cannot enable the weighted round robin because the maxinmum number of A or AAAA records is exceeded.',
  },
  {
    Description: '监控操作失败',
    Product: 'Alidns',
    ErrorCode: 'Monitor.Fail',
    ErrorMessage: 'Cannot enable the monitoring.',
  },
  {
    Description: '关闭 加权轮询 失败',
    Product: 'Alidns',
    ErrorCode: 'CloseDnsSlbFailed',
    ErrorMessage: 'Cannot disable the weighted round robin.',
  },
  {
    Description: '含有域名的分组无法删除',
    Product: 'Alidns',
    ErrorCode: 'Fobidden.NotEmptyGroup',
    ErrorMessage: 'Cannot delete the domain group that is not empty.',
  },
  {
    Description: '默认域名分组无法删除',
    Product: 'Alidns',
    ErrorCode: 'Fobidden.DefaultGroup',
    ErrorMessage: 'Cannot delete the default domain group.',
  },
  {
    Description: '创建订单失败',
    Product: 'Alidns',
    ErrorCode: 'CreateOrderFailed',
    ErrorMessage: 'Cannot create the order.',
  },
  {
    Description: '作废订单失败。',
    Product: 'Alidns',
    ErrorCode: 'CancelOrderFailed',
    ErrorMessage: 'Cannot cancel the order.',
  },
  {
    Description:
      '域名已被列入工信部黑名单，黑名单管理相关文件请查阅《关于建立境内违法互联网站黑名单管理制度的通知》（工信部联电管【2009】371号）。',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.miitBlackList',
    ErrorMessage: 'Cannot add the the domain name because it is in the MIIT blacklist.',
  },
  {
    Description: '该域名与注册局域名信息库不匹配，无法添加，请输入全简体域名或全繁体域名。',
    Product: 'Alidns',
    ErrorCode: 'CHNDomainInfoNotMatch',
    ErrorMessage:
      'Cannot add the domain name because it does not match the CNNIC data. Please enter a  domain name that only contains simplified Chinese characters or traditional Chinese characters.',
  },
  {
    Description: '绑定次数超过版本规定的限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Binding',
    ErrorMessage: 'Binding count exceeds the limit.',
  },
  {
    Description: 'NS记录不支持主机记录（RR）值为"@"或为空',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NSRecordRrEmpty',
    ErrorMessage:
      'At signs (@) or empty are not allowed in the hostname (RR value) of a NS record.',
  },
  {
    Description: 'TXT记录不支持主机记录（RR）值为"*"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TXTRecordPan',
    ErrorMessage: 'Asterisks (*) are not allowed in the hostname (RR value) of a TXT record.',
  },
  {
    Description: 'SRV记录不支持主机记录（RR）值为"*"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.SRVRecordPan',
    ErrorMessage: 'Asterisks (*) are not allowed in the hostname (RR value) of a SRV record.',
  },
  {
    Description: 'NS记录不支持主机记录（RR）值为"*"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NSRecordPan',
    ErrorMessage: 'Asterisks (*) are not allowed in the hostname (RR value) of a NS record.',
  },
  {
    Description: '上次任务正在执行',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.TaskNotNull',
    ErrorMessage: 'Another task is running. Please wait for it to complete.',
  },
  {
    Description: '已经存在导入任务，请等待任务完成后再提交！',
    Product: 'Alidns',
    ErrorCode: 'TaskExecutio',
    ErrorMessage: 'An import task already exists. Wait until that task is completed and try again.',
  },
  {
    Description: '验签失败',
    Product: 'Alidns',
    ErrorCode: 'Gtm.VerifySign.Failed',
    ErrorMessage: 'An error occurred while verifying the signature.',
  },
  {
    Description: '绑定错误',
    Product: 'Alidns',
    ErrorCode: 'Binding.Error',
    ErrorMessage: 'An error occured while binding.',
  },
  {
    Description: '超出限制，当前配置不允许添加此切换策略的监控。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitoringSwitchingStrategy',
    ErrorMessage: 'Adding a monitor for the switching policy is not allowed.',
  },
  {
    Description: "泛解析只能以'*.'字符开头。",
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.PanDomainRecord',
    ErrorMessage: 'A Wildcard DNS record must start with an asterisk (*).',
  },
  {
    Description: '批量导入系统繁忙，请稍后重试！',
    Product: 'Alidns',
    ErrorCode: 'BatchOperation.timeout',
    ErrorMessage: '',
  },
  {
    Description: '尚未购买网站监控',
    Product: 'Alidns',
    ErrorCode: 'Monitor.Unpaid',
    ErrorMessage: '',
  },
  {
    Description: "RR值不能有连续的'.'字符。",
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.DupDot',
    ErrorMessage: '',
  },
  {
    Description: '监控操作失败',
    Product: 'Alidns',
    ErrorCode: 'Monitor.Fail',
    ErrorMessage: '',
  },
  {
    Description: '全局负载均衡需要主机记录开启网站监控，请前往配置。',
    Product: 'Alidns',
    ErrorCode: 'LineDnsSlb.Monitor',
    ErrorMessage: '',
  },
  {
    Description: '分组名称已存在',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainGroup.Duplicate',
    ErrorMessage: '',
  },
  {
    Description: '当前云解析配置不支持此线路',
    Product: 'Alidns',
    ErrorCode: 'NotSupport.Line',
    ErrorMessage: '',
  },
  {
    Description: 'URL跳转不支持泛解析',
    Product: 'Alidns',
    ErrorCode: 'URLForwardError.PanRecord',
    ErrorMessage: '',
  },
  {
    Description: '数据格式不正确',
    Product: 'Alidns',
    ErrorCode: 'DataFormatError',
    ErrorMessage: '',
  },
  {
    Description: '锁定解析失败',
    Product: 'Alidns',
    ErrorCode: 'RecordLocked.fail',
    ErrorMessage: '',
  },
  {
    Description: '超出限制，当前配置不允许添加此切换策略的监控。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitoringSwitchingStrategy',
    ErrorMessage: '',
  },
  {
    Description: '该域名为受限域名，不能设置解析，故不能添加到该解析列表',
    Product: 'Alidns',
    ErrorCode: 'RestrictedDomain',
    ErrorMessage: '',
  },
  {
    Description: '无效的解析ttl',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.TTL',
    ErrorMessage: '',
  },
  {
    Description: '用户名下没有监控',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NotExist',
    ErrorMessage: '',
  },
  {
    Description: '分组名称长度超过限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.GroupNameLength',
    ErrorMessage: '',
  },
  {
    Description: '删除解析记录失败',
    Product: 'Alidns',
    ErrorCode: 'delRRfail',
    ErrorMessage: '',
  },
  {
    Description: "泛解析只能以'*.'字符开头。",
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.PanDomainRecord',
    ErrorMessage: '',
  },
  {
    Description: '默认域名分组无法删除',
    Product: 'Alidns',
    ErrorCode: 'Fobidden.DefaultGroup',
    ErrorMessage: '',
  },
  {
    Description: '您这个时段的流量已经超限。如果不能满足现有业务要求可以提工单进行申请。',
    Product: 'Alidns',
    ErrorCode: 'Throttling',
    ErrorMessage: '',
  },
  {
    Description: '无效的TraceId',
    Product: 'Alidns',
    ErrorCode: 'InvalidTraceId',
    ErrorMessage: '',
  },
  {
    Description: '监控项不完全属于同一个用户',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NotSameUser',
    ErrorMessage: '',
  },
  {
    Description: '云解析产品不存在',
    Product: 'Alidns',
    ErrorCode: 'InvalidDnsProduct',
    ErrorMessage: '',
  },
  {
    Description: '域名格式错误：字符串不能以“."开始',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.StartWithDot',
    ErrorMessage: '',
  },
  {
    Description: '打开 加权轮询 失败。',
    Product: 'Alidns',
    ErrorCode: 'EnableDnsSlbFailed',
    ErrorMessage: '',
  },
  {
    Description: '含有域名的分组无法删除',
    Product: 'Alidns',
    ErrorCode: 'Fobidden.NotEmptyGroup',
    ErrorMessage: '',
  },
  {
    Description: '绑定错误',
    Product: 'Alidns',
    ErrorCode: 'Binding.Error',
    ErrorMessage: '',
  },
  {
    Description: 'TXT正文内容不能包含中文字符',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TXTValueUnicode',
    ErrorMessage: '',
  },
  {
    Description: '阿里云注册域名，不能进行此操作 。',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.DomainType',
    ErrorMessage: '',
  },
  {
    Description: 'URL转发删除失败！',
    Product: 'Alidns',
    ErrorCode: 'URLForwardDeleteError',
    ErrorMessage: '',
  },
  {
    Description: '超出数量限制，当前配置的最大备用 IP/CNAME 数量为 {0} 个',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitoringIp',
    ErrorMessage: '',
  },
  {
    Description: '被找回域名被删除，此次找回已失效',
    Product: 'Alidns',
    ErrorCode: 'InvalidRetrievedomain.delete',
    ErrorMessage: '',
  },
  {
    Description: '域名数目已经达到产品定义的最大值',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.domainlist',
    ErrorMessage: '',
  },
  {
    Description: '服务器无法完成对请求的处理',
    Product: 'Alidns',
    ErrorCode: 'InternalError',
    ErrorMessage: '',
  },
  {
    Description: '域名格式错误：请输入正确的域名格式',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Format',
    ErrorMessage: '',
  },
  {
    Description: '您操作的域名已经不存在，请刷新页面重试。',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.NoExist',
    ErrorMessage: '',
  },
  {
    Description: '域名格式错误：“-”不能放到开头和结尾',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.MiddleLine',
    ErrorMessage: '',
  },
  {
    Description: '没有权限操作此域名，请先执行找回操作！',
    Product: 'Alidns',
    ErrorCode: 'OperationDomain.NoPermission',
    ErrorMessage: '',
  },
  {
    Description: '获取运营商可用的城市列表失败',
    Product: 'Alidns',
    ErrorCode: 'Monitor.GetISPCityList',
    ErrorMessage: '',
  },
  {
    Description: '监控项不完全属于同一个域名',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NotSameDomain',
    ErrorMessage: '',
  },
  {
    Description: 'TXT正文内容的值的长度不能超过253个字符',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TXTValueLength',
    ErrorMessage: '',
  },
  {
    Description: '部分记录字段内容非法',
    Product: 'Alidns',
    ErrorCode: 'Record.illegal',
    ErrorMessage: '',
  },
  {
    Description: 'AAAA记录不支持主机记录（RR）值含"_"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.AAARecordUnderLine',
    ErrorMessage: '',
  },
  {
    Description: '创建域名失败',
    Product: 'Alidns',
    ErrorCode: 'addSoafail',
    ErrorMessage: '',
  },
  {
    Description: '不支持此格式：如为punycode码，请输入中文域名',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Idn',
    ErrorMessage: '',
  },
  {
    Description: '作废订单失败。',
    Product: 'Alidns',
    ErrorCode: 'CancelOrderFailed',
    ErrorMessage: '',
  },
  {
    Description: 'CAA记录仅支持云解析DNS收费版',
    Product: 'Alidns',
    ErrorCode: 'InvalidVersion.Unpaid',
    ErrorMessage: '',
  },
  {
    Description: '未被授权的用户ID',
    Product: 'Alidns',
    ErrorCode: 'IllegalUser',
    ErrorMessage: '',
  },
  {
    Description: '未注册域名，提交注册',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.NeedRegistered',
    ErrorMessage: '',
  },
  {
    Description: '无效的版本',
    Product: 'Alidns',
    ErrorCode: 'NoSuchVersion',
    ErrorMessage: '',
  },
  {
    Description:
      '域名格式错误：以“."分割的每部分不超过63字符，包含点的全长不超过255字符（含最后的“.“）',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Length',
    ErrorMessage: '',
  },
  {
    Description: '超出线路限制，当前配置不支持设置此线路类型',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.Line',
    ErrorMessage: '',
  },
  {
    Description: '域名被其他账户添加',
    Product: 'Alidns',
    ErrorCode: 'DomainAddedByOthers',
    ErrorMessage: '',
  },
  {
    Description: 'TXT正文内容不能包含非法字符。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TXTValueIllegalChar',
    ErrorMessage: '',
  },
  {
    Description: '与其他记录冲突，不能添加',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordConflict',
    ErrorMessage: '',
  },
  {
    Description: '无实名验证',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.UserVerification',
    ErrorMessage: '',
  },
  {
    Description: '解析模板记录不允许的解析类型。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidType',
    ErrorMessage: '',
  },
  {
    Description:
      '该域名非本账户域名，阿里云域名不能进行跨账号解析，您可以将该域名进行账号间转移来实现域名绑定与解析设置。',
    Product: 'Alidns',
    ErrorCode: 'IncorrectDomainUser',
    ErrorMessage: '',
  },
  {
    Description: '全局负载均衡暂不支持CNAME记录，请关闭CNAME记录后再尝试开启。',
    Product: 'Alidns',
    ErrorCode: 'LineDnsSlb.Cname',
    ErrorMessage: '',
  },
  {
    Description: '解析记录被暂停',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordDisable',
    ErrorMessage: '',
  },
  {
    Description:
      '您的域名正在遭受恶意攻击，且攻击量已超过您购买的域名解析防护量，当前处于黑洞状态（暂停解析），黑洞期间暂不支持解析的增删改操作！建议您立即升级配置或者等待攻击结束',
    Product: 'Alidns',
    ErrorCode: 'RecordFobidden.BlackHole',
    ErrorMessage: '',
  },
  {
    Description: '获取监控地图详情失败',
    Product: 'Alidns',
    ErrorCode: 'Monitor.GetMonitorMap',
    ErrorMessage: '',
  },
  {
    Description: '创建订单失败',
    Product: 'Alidns',
    ErrorCode: 'CreateOrderFailed',
    ErrorMessage: '',
  },
  {
    Description: '查询实例未付款订单失败。',
    Product: 'Alidns',
    ErrorCode: 'QueryInstanceUnpaidOrderFailed',
    ErrorMessage: '',
  },
  {
    Description: '监控的自定义切换无备用IP！',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NoBackupIp',
    ErrorMessage: '',
  },
  {
    Description: 'RR值不能以“逗点”符号开头或结尾。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.Format.Dot',
    ErrorMessage: '',
  },
  {
    Description: '主机记录类型输入无效，主机记录类型只支持NS、CNAME、A记录、AAAA、MX、TXT、SRV记录',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.Type',
    ErrorMessage: '',
  },
  {
    Description: '要操作的监控不存在！',
    Product: 'Alidns',
    ErrorCode: 'Monitor.TaskNoExists',
    ErrorMessage: '',
  },
  {
    Description: '该解析的权重值不合法，权重仅允许1-100间整数',
    Product: 'Alidns',
    ErrorCode: 'Invalidweightvalue',
    ErrorMessage: '',
  },
  {
    Description: '域名格式错误：不能全为数字',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Number',
    ErrorMessage: '',
  },
  {
    Description: '无效的Access Key',
    Product: 'Alidns',
    ErrorCode: 'InvalidAccessKeyId.NotFound',
    ErrorMessage: '',
  },
  {
    Description:
      "主机记录（RR）值合法字符包含a--z、A--Z、0--9、'-' 、'_' 、'.'、'*'、'@'、'中文汉字'。",
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.Format',
    ErrorMessage: '',
  },
  {
    Description: '请输入正确的IP地址，例如:10.10.10.10。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.AValue',
    ErrorMessage: '',
  },
  {
    Description: '“.”分割的每个字符串长度最长为63字符。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.Length',
    ErrorMessage: '',
  },
  {
    Description: '您没有购买地域线路，无法切换线路类型',
    Product: 'Alidns',
    ErrorCode: 'NotSupport.LineType',
    ErrorMessage: '',
  },
  {
    Description: '域名格式错误：“.”仅作为分隔符使用，且不能连续；',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.DupDot',
    ErrorMessage: '',
  },
  {
    Description: '超出限制，批量删除的解析记录不能超过100个',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Batchdelete',
    ErrorMessage: '',
  },
  {
    Description: '没有添加过默认线路，请先添加默认线路。',
    Product: 'Alidns',
    ErrorCode: 'DefaultlineNotadded',
    ErrorMessage: '',
  },
  {
    Description: 'NS记录的值为域名，请输入正确域名。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NSValue',
    ErrorMessage: '',
  },
  {
    Description: '批量操作的数量超过限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.BatchNumber',
    ErrorMessage: '',
  },
  {
    Description: '主机记录（RR）值的输入内容不合法、或超出限制',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.RR',
    ErrorMessage: '',
  },
  {
    Description: '工信部黑名单域名',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.miitBlackList',
    ErrorMessage: '',
  },
  {
    Description: '解析模板名不能重复。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.DuplicateName',
    ErrorMessage: '',
  },
  {
    Description: 'URL转发目标地址中不能包含中文字符',
    Product: 'Alidns',
    ErrorCode: 'URLForwardError.ChineseChar',
    ErrorMessage: '',
  },
  {
    Description: '解析模板记录长度超过最大限制。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.QuotaExceededTemplateNameLength',
    ErrorMessage: '',
  },
  {
    Description: '找回白名单域名不允许找回',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.ProtectedDomain',
    ErrorMessage: '',
  },
  {
    Description: '请输入正确的IPv6地址。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.AAAAValue',
    ErrorMessage: '',
  },
  {
    Description: '服务器当前无法处理请求',
    Product: 'Alidns',
    ErrorCode: 'ServiceUnavailable',
    ErrorMessage: '',
  },
  {
    Description: 'SRV的值不能为空',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.SRVValueEmpty',
    ErrorMessage: '',
  },
  {
    Description: '解析模板记录不允许的线路类型。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidLine',
    ErrorMessage: '',
  },
  {
    Description: '需要通过域名持有者身份验证，才可以进行添加。\r\n点击这里验证身份',
    Product: 'Alidns',
    ErrorCode: 'Authentication.domain',
    ErrorMessage: '',
  },
  {
    Description: '90秒内不允许重复找回',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.TooOfen',
    ErrorMessage: '',
  },
  {
    Description: '当前域名已绑定云解析DNS实例，更换实例可能会改变当前记录设置，请确认！',
    Product: 'Alidns',
    ErrorCode: 'InvalidBinding.BoundDomain',
    ErrorMessage: '',
  },
  {
    Description: '不支持短消息通知类型',
    Product: 'Alidns',
    ErrorCode: 'NotSupport.SMS',
    ErrorMessage: '',
  },
  {
    Description: '解析模板记录id不存在。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidTemplateRecordId',
    ErrorMessage: '',
  },
  {
    Description: '查询续费价格失败。',
    Product: 'Alidns',
    ErrorCode: 'QueryRenewPriceFailed',
    ErrorMessage: '',
  },
  {
    Description: 'MX解析无效的优先级',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.Priority',
    ErrorMessage: '',
  },
  {
    Description: '无效的签名',
    Product: 'Alidns',
    ErrorCode: 'SignatureDoesNotMatch',
    ErrorMessage: '',
  },
  {
    Description: '暂停解析失败',
    Product: 'Alidns',
    ErrorCode: 'RecordDisable.fail',
    ErrorMessage: '',
  },
  {
    Description: '解析模板记录不允许的TTL。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidTTL',
    ErrorMessage: '',
  },
  {
    Description: '禁止操作：域名已过期，解析已被锁定。',
    Product: 'Alidns',
    ErrorCode: 'DomainExpiredDNSForbidden',
    ErrorMessage: '',
  },
  {
    Description: '关闭 加权轮询 失败',
    Product: 'Alidns',
    ErrorCode: 'CloseDnsSlbFailed',
    ErrorMessage: '',
  },
  {
    Description: '子域名未开启 加权轮询\n',
    Product: 'Alidns',
    ErrorCode: 'DisableDNSSLB',
    ErrorMessage: '',
  },
  {
    Description: '域名不是阿里云域名',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.NotHichinaDomain',
    ErrorMessage: '',
  },
  {
    Description: '解析模板id不存在。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.InvalidTemplateId',
    ErrorMessage: '',
  },
  {
    Description: 'TXT记录不支持主机记录（RR）值为"*"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TXTRecordPan',
    ErrorMessage: '',
  },
  {
    Description: '您有尚未支付的订单，请先处理后再进行此次操作',
    Product: 'Alidns',
    ErrorCode: 'InstanceHasUnpaidOrder',
    ErrorMessage: '',
  },
  {
    Description: '当前版本不支持的解析线路',
    Product: 'Alidns',
    ErrorCode: 'UnsupportedLine',
    ErrorMessage: '',
  },
  {
    Description: 'CNAME记录无法使用“开启/关闭”功能',
    Product: 'Alidns',
    ErrorCode: 'DnsSLB.InvalidType',
    ErrorMessage: '',
  },
  {
    Description: '操作被禁止',
    Product: 'Alidns',
    ErrorCode: 'Forbidden',
    ErrorMessage: '',
  },
  {
    Description: '批量处理超过数量限制。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.BundleNumber',
    ErrorMessage: '',
  },
  {
    Description: '您操作的解析记录已经不存在，请刷新页面重试',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NoExist',
    ErrorMessage: '',
  },
  {
    Description: '记录值输入有误，请输入正确的域名，例如:www.net.cn。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.MXValue',
    ErrorMessage: '',
  },
  {
    Description: '已经存在导入任务，请等待任务完成后再提交！',
    Product: 'Alidns',
    ErrorCode: 'TaskExecutio',
    ErrorMessage: '',
  },
  {
    Description: '无效的接口',
    Product: 'Alidns',
    ErrorCode: 'UnsupportedOperation',
    ErrorMessage: '',
  },
  {
    Description: '域名已存在于解析列表中，无需再次添加。点击解析列表中的域名即可开始解析',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Duplicate',
    ErrorMessage: '',
  },
  {
    Description: '该操作被风险控制系统禁止。',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.RiskControl',
    ErrorMessage: '',
  },
  {
    Description: 'TXT记录未设置或者暂时还未生效，请稍后再试。',
    Product: 'Alidns',
    ErrorCode: 'IdentityVerification.TxtRecord',
    ErrorMessage: '',
  },
  {
    Description: '部分记录字段为空！',
    Product: 'Alidns',
    ErrorCode: 'Record.empty',
    ErrorMessage: '',
  },
  {
    Description: 'URL转发不支持非default线路',
    Product: 'Alidns',
    ErrorCode: 'URLForwardError.NotDefaultLine',
    ErrorMessage: '',
  },
  {
    Description: '操作扫描任务出错！',
    Product: 'Alidns',
    ErrorCode: 'ScanningOperationError',
    ErrorMessage: '',
  },
  {
    Description: '域名分组数量超过限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.GroupCount',
    ErrorMessage: '',
  },
  {
    Description: 'SRV记录不支持主机记录（RR）值为"*"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.SRVRecordPan',
    ErrorMessage: '',
  },
  {
    Description: '解析记录在本账户下不存在',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordNotBelongToUser',
    ErrorMessage: '',
  },
  {
    Description: 'URL转发添加失败',
    Product: 'Alidns',
    ErrorCode: 'URLForwardAddError',
    ErrorMessage: '',
  },
  {
    Description: '解锁解析失败',
    Product: 'Alidns',
    ErrorCode: 'RecordUnLocked.fail',
    ErrorMessage: '',
  },
  {
    Description: '超出数量限制，当前配置的最小允许TTL值为 {0}',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.TTL',
    ErrorMessage: '',
  },
  {
    Description: '用户无资源授权，或者该API不支持RAM',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.RAM',
    ErrorMessage: '',
  },
  {
    Description: '无效的状态',
    Product: 'Alidns',
    ErrorCode: 'InvalidStatus',
    ErrorMessage: '',
  },
  {
    Description: 'A记录不支持主机记录（RR）值含"_"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.ARecordUnderLine',
    ErrorMessage: '',
  },
  {
    Description: '域名含有锁定解析',
    Product: 'Alidns',
    ErrorCode: 'Fobidden.RecordLocked',
    ErrorMessage: '',
  },
  {
    Description: '泛解析不允许修改权重',
    Product: 'Alidns',
    ErrorCode: 'PanDomainRecord',
    ErrorMessage: '',
  },
  {
    Description: '域名解析防护进入黑洞',
    Product: 'Alidns',
    ErrorCode: 'RecordForbidden.BlackHole',
    ErrorMessage: '',
  },
  {
    Description: '域名分组不存在',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainGroup.NotExist',
    ErrorMessage: '',
  },
  {
    Description: '解析模板id不属于该用户。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.IncorrectTemplateUser',
    ErrorMessage: '',
  },
  {
    Description: '超出数量限制，当前配置的最大设置数量为 {0} 条URL转发',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.URLRecord',
    ErrorMessage: '',
  },
  {
    Description: '解析记录值（RR value）的输入内容不合法或格式错误',
    Product: 'Alidns',
    ErrorCode: 'SubDomainInvalid.Value',
    ErrorMessage: '',
  },
  {
    Description: '解析类型不能为空',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.TypeEmpty',
    ErrorMessage: '',
  },
  {
    Description: '域名不是阿里云域名。',
    Product: 'Alidns',
    ErrorCode: 'Forbidden.NotAliyunDomain',
    ErrorMessage: '',
  },
  {
    Description: "记录值中不能有连续的'.'字符。",
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.ValueDupDot',
    ErrorMessage: '',
  },
  {
    Description: '添加解析记录失败！',
    Product: 'Alidns',
    ErrorCode: 'addRRfail',
    ErrorMessage: '',
  },
  {
    Description: 'URL转发记录的值最长不能超过500个字符，请重新填写。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.FWDValueLength',
    ErrorMessage: '',
  },
  {
    Description: '上次任务正在执行',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.TaskNotNull',
    ErrorMessage: '',
  },
  {
    Description: '解析模板记录个数超过最大限制。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.QuotaExceededTemplateRecord',
    ErrorMessage: '',
  },
  {
    Description: '域名不能为空',
    Product: 'Alidns',
    ErrorCode: 'DomainEmpty',
    ErrorMessage: '',
  },
  {
    Description: '启用解析失败',
    Product: 'Alidns',
    ErrorCode: 'RecordEnable.fail',
    ErrorMessage: '',
  },
  {
    Description: '解析模板个数超过最大限制。',
    Product: 'Alidns',
    ErrorCode: 'DnsTemplate.QuotaExceededTemplate',
    ErrorMessage: '',
  },
  {
    Description: 'RR值中不能以字符 “-”开头和结尾。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.MiddleLine',
    ErrorMessage: '',
  },
  {
    Description: '超出数量限制，当前配置的监控节点最多为 {0} 个',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitorNodes',
    ErrorMessage: '',
  },
  {
    Description: '域名不能为空，请选择域名。',
    Product: 'Alidns',
    ErrorCode: 'DeleteDomain.Empty',
    ErrorMessage: '',
  },
  {
    Description: 'RR值中逗点之间不允许为空。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.Empty',
    ErrorMessage: '',
  },
  {
    Description: '免费版解析暂不支持监控操作。',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NotSupport',
    ErrorMessage: '',
  },
  {
    Description: '超出解析记录数量最大值90',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Record',
    ErrorMessage: '',
  },
  {
    Description: '获取不到Whois信息',
    Product: 'Alidns',
    ErrorCode: 'UnabletoGetWoisInfo',
    ErrorMessage: '',
  },
  {
    Description: '域名格式错误：域名一般由英文字母、汉字、阿拉伯数字、“.”、“－”组成。',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Illegalcharacter',
    ErrorMessage: '',
  },
  {
    Description: '禁止操作：解析记录被锁定',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordLocked',
    ErrorMessage: '',
  },
  {
    Description: '删除域名失败！',
    Product: 'Alidns',
    ErrorCode: 'delSoafail',
    ErrorMessage: '',
  },
  {
    Description: '未知错误，请稍后重试！',
    Product: 'Alidns',
    ErrorCode: 'UnKnownError',
    ErrorMessage: '',
  },
  {
    Description: 'MX记录不支持主机记录（RR）值含"_"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.MXRecordUnderLine',
    ErrorMessage: '',
  },
  {
    Description: 'Whois邮箱未获取到',
    Product: 'Alidns',
    ErrorCode: 'UnableToGetWhoisEmail',
    ErrorMessage: '',
  },
  {
    Description: '超出数量限制，当前配置的最大设置数量为 {0} 条记录',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.ARecord',
    ErrorMessage: '',
  },
  {
    Description: '云解析添加监控超过限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Monitor',
    ErrorMessage: '',
  },
  {
    Description: '域名未分配DNS，请联系阿里云客服！',
    Product: 'Alidns',
    ErrorCode: 'DNSnotfound',
    ErrorMessage: '',
  },
  {
    Description: '分组名称为空',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainGroup.EmptyName',
    ErrorMessage: '',
  },
  {
    Description: '获取运营商列表失败',
    Product: 'Alidns',
    ErrorCode: 'Monitor.GetISPFailed',
    ErrorMessage: '',
  },
  {
    Description: '修改解析记录失败',
    Product: 'Alidns',
    ErrorCode: 'chgRRfail',
    ErrorMessage: '',
  },
  {
    Description: '设置异常，当前配置允许的监控间隔为 {0} 分钟。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitoringFrequency',
    ErrorMessage: '',
  },
  {
    Description: '域名格式错误：请以“.”进行分隔。',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Label',
    ErrorMessage: '',
  },
  {
    Description: 'NS记录的值的长度不能超过253个字符',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NSValueLength',
    ErrorMessage: '',
  },
  {
    Description: '该域名非本账户域名',
    Product: 'Alidns',
    ErrorCode: 'AliyunDomainAddedByOthers',
    ErrorMessage: '',
  },
  {
    Description: '解析记录已存在',
    Product: 'Alidns',
    ErrorCode: 'DomainRecordDuplicate',
    ErrorMessage: '',
  },
  {
    Description: '批量操作线程已满，请稍后重试！',
    Product: 'Alidns',
    ErrorCode: 'OperationThreadInUsed',
    ErrorMessage: '',
  },
  {
    Description: '批量操作参数非法',
    Product: 'Alidns',
    ErrorCode: 'BatchOperation.illegal',
    ErrorMessage: '',
  },
  {
    Description: 'A/AAAA记录条数超过限制，无法开启 加权轮询',
    Product: 'Alidns',
    ErrorCode: 'LineDnsSlb.QuotaExceeded',
    ErrorMessage: '',
  },
  {
    Description: '子域名长度超出数量限制，当前配置的最大允许子域名级数为 {0}',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.SubDomain',
    ErrorMessage: '',
  },
  {
    Description: '获取可用节点失败！',
    Product: 'Alidns',
    ErrorCode: 'Monitor.GetMonitorNodes',
    ErrorMessage: '',
  },
  {
    Description: 'SRV记录值格式错误',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.SRVValueFormat',
    ErrorMessage: '',
  },
  {
    Description:
      'A(AAAA)记录数量超出限制，云解析免费版可以添加10个相同主机记录相同线路的A(AAAA)记录。',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.FreeDnsRecord',
    ErrorMessage: '',
  },
  {
    Description: '无效的权重值',
    Product: 'Alidns',
    ErrorCode: 'InvalidWeigth',
    ErrorMessage: '',
  },
  {
    Description: 'URL转发记录的值为域名或URL地址，请重新填写。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.FWDValue',
    ErrorMessage: '',
  },
  {
    Description: '域名不存在！',
    Product: 'Alidns',
    ErrorCode: 'DomainNotFound',
    ErrorMessage: '',
  },
  {
    Description: 'URL转发修改失败',
    Product: 'Alidns',
    ErrorCode: 'URLForwardChangeError',
    ErrorMessage: '',
  },
  {
    Description: 'URL备案异常',
    Product: 'Alidns',
    ErrorCode: 'URLForwardError.NotVerifyDomain',
    ErrorMessage: '',
  },
  {
    Description: '系统暂不支持该域名后缀解析',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Suffix',
    ErrorMessage: '',
  },
  {
    Description: '绑定次数超过版本规定的限制',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Binding',
    ErrorMessage: '',
  },
  {
    Description: '只能查询最近90天的记录',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.Time',
    ErrorMessage: '',
  },
  {
    Description: '参数取值{0}无效',
    Product: 'Alidns',
    ErrorCode: 'InvalidParameter',
    ErrorMessage: '',
  },
  {
    Description: 'NS记录不支持主机记录（RR）值为"@"或为空',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NSRecordRrEmpty',
    ErrorMessage: '',
  },
  {
    Description: '缺少参数：{0}',
    Product: 'Alidns',
    ErrorCode: 'MissingParameter',
    ErrorMessage: '',
  },
  {
    Description: '特殊域名绑定无效',
    Product: 'Alidns',
    ErrorCode: 'InvalidBinding.SpecialDomain',
    ErrorMessage: '',
  },
  {
    Description: 'NS记录不支持主机记录（RR）值为"*"',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.NSRecordPan',
    ErrorMessage: '',
  },
  {
    Description: '未注册域名，阿里云不支持此域名注册',
    Product: 'Alidns',
    ErrorCode: 'InvalidDomainName.Unregistered',
    ErrorMessage: '',
  },
  {
    Description: '主机记录（RR）不能为空值，请您输入。',
    Product: 'Alidns',
    ErrorCode: 'InvalidRR.RrEmpty',
    ErrorMessage: '',
  },
  {
    Description:
      '操作失败！产品配置发生变化，系统正在为您切换，切换期间，暂不支持解析的增删改操作！请您稍后再试！',
    Product: 'Alidns',
    ErrorCode: 'RecordForbidden.DNSChange',
    ErrorMessage: '',
  },
  {
    Description: '该域名与注册局域名信息库不匹配，无法添加，请输入全简体域名或全繁体域名。',
    Product: 'Alidns',
    ErrorCode: 'CHNDomainInfoNotMatch',
    ErrorMessage: '',
  },
  {
    Description: '无可用的值（指定的解析记录已删除或暂停）',
    Product: 'Alidns',
    ErrorCode: 'Monitor.NoRecord',
    ErrorMessage: '',
  },
  {
    Description: '超出数量限制，当前配置的最大监控任务数量为 {0} 个',
    Product: 'Alidns',
    ErrorCode: 'QuotaExceeded.MonitoringTask',
    ErrorMessage: '',
  },
];
