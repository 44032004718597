export default function(initialState) {
  const {
    currentUser: { permissions = [] },
  } = initialState;
  return {
    permissionFilter: route => {
      return route.authority?.length ? route.authority.some(i => permissions.includes(i)) : true;
    },
  };
}
