import { Pagination } from 'antd';
import React from 'react';
import pageStyles from '@/utils/utils.less';

/**
 * @deprecated use Pagination instead.
 */
const CustomPagination = ({
  total,
  loading,
  fetchPage,
  options,
  currentPage,
  pageSize,
  isShowTip = true,
  isBlock = true,
  handleSizeChange,
  handlePageChange,
  isShowHistory,
  ...rest
}) => {
  return (
    <div className={isBlock ? pageStyles['page-pagination'] : undefined} {...rest}>
      {!isShowHistory && isShowTip && Number(total) > 0 && <div>共{total}条记录</div>}
      {isShowHistory && Number(total) > 0 && <div>历史产品个数 : {total}个</div>}
      {loading === false ? (
        <Pagination
          style={{ marginLeft: 'auto' }}
          onChange={handlePageChange || fetchPage}
          showSizeChanger
          pageSizeOptions={options || ['10', '30', '50', '100', '200']}
          total={Number(total)}
          size="small"
          showLessItems
          showTotal={v => !isShowTip && `共${v}条记录`}
          current={Number(currentPage)}
          pageSize={Number(pageSize)}
          showQuickJumper
          hideOnSinglePage={!Number(total)}
        />
      ) : null}
    </div>
  );
};

export default CustomPagination;
