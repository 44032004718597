import { fetchRefundDetail } from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'refundDetail',

  state: {
    data: [],
    totalCount: 0,
    total: {
      all_refund_amount_cny: 0,
      order_count: 0,
      all_refund_amount_usd: 0,
      refund_count: 0,
    },
  },

  effects: {
    *getRefundDetail({ payload }, { call, put }) {
      const response = yield call(fetchRefundDetail, payload);
      yield put({
        type: 'saveRefundDetail',
        payload: response,
      });
    },
  },
  reducers: {
    saveRefundDetail(state, action) {
      const { data, total } = action.payload;
      return {
        ...state,
        data: data?.rows?.map(i => ({ ...i, rowkey: generateUuid() })) || [],
        totalCount: data?.totalNum || 0,
        total: total?.[0] || {},
      };
    },
  },
};
