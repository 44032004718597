import { fetchRealTimeSpending } from '@/services/api';

export default {
  namespace: 'realTimeSpending',

  state: {
    data: [],
    total: 0,
    detailData: [],
  },

  effects: {
    *getRealTimeSpending({ payload }, { call, put }) {
      const response = yield call(fetchRealTimeSpending, payload);
      yield put({
        type: 'saveRealTimeSpending',
        payload: response,
      });
    },
  },
  reducers: {
    saveRealTimeSpending(state, action) {
      const { data, meta } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
      };
    },
  },
};
