import { fetchCustomizedProduct, fetchDeletedSupply, fetchDeletedAttr } from '@/services/api';

export default {
  namespace: 'customize',
  state: {
    data: {},
    deletedSupply: [],
    deletedAttr: [],
  },

  effects: {
    *getCustomizedData({ payload, callback }, { call, put }) {
      const response = yield call(fetchCustomizedProduct, payload);
      yield put({
        type: 'saveCustomizedData',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getDeletedSupply({ payload, callback }, { call, put }) {
      const response = yield call(fetchDeletedSupply, payload);
      yield put({
        type: 'saveDeletedSupply',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getDeletedAttr({ payload, callback }, { call, put }) {
      const response = yield call(fetchDeletedAttr, payload);
      yield put({
        type: 'saveDeletedAttr',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveCustomizedData(state, action) {
      const {
        data: {
          images = [],
          son_skus: sonSkus,
          supplys = [],
          t_sku: tsku,
          title,
          v_sku: vsku,
          vs_skus_info: vSkusInfo,
          check_stat: checkStat,
          is_customized: isCustomized,
          why_cannot_custom: whyCannotCustom,
          order_processed: orderProcessed,
        },
      } = action.payload || { data: {} };
      return {
        ...state,
        data: {
          images,
          sonSkus,
          supplys,
          title,
          tsku,
          vsku,
          vSkusInfo,
          checkStat,
          isCustomized,
          whyCannotCustom,
          orderProcessed,
        },
      };
    },
    saveDeletedSupply(state, action) {
      const { data } = action.payload || {};
      return {
        ...state,
        deletedSupply: data,
      };
    },
    saveDeletedAttr(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        deletedAttr: data,
      };
    },
  },
};
