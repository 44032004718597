import { fetchAdSpendDayList, fetchAdSpendDayTotal, fetchFBAdSpendTotal } from '@/services/api';

export default {
  namespace: 'spend',

  state: {
    adSpendDayList: [],
    meta: {
      currentPage: 1,
      lastPage: 1,
      total: 1,
      totalData: {},
    },
    data: [],
    totalMeta: {
      total: 1,
      page: 1,
      pageSize: 10,
    },
    summary: {
      totalData: [],
      testData: [],
      summaryData: [],
    },
  },

  effects: {
    *getAdSpendDayList({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdSpendDayList, payload);
      yield put({
        type: 'saveAdSpendDayList',
        payload: response,
      });
      if (callback) callback();
    },
    *getAdSpendDayTotal({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdSpendDayTotal, payload);
      yield put({
        type: 'saveAdSpendDayTotal',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getFBAdSpendTotal({ payload }, { call, put }) {
      const response = yield call(fetchFBAdSpendTotal, payload);
      yield put({
        type: 'saveFBAdSpendTotal',
        payload: response,
      });
    },
  },
  reducers: {
    saveAdSpendDayList(state, action) {
      const {
        data,
        meta: { current_page: currentPage, last_page: lastPage, total, total_data: totalData },
      } = action.payload;
      return {
        ...state,
        adSpendDayList: data,
        meta: {
          currentPage,
          lastPage,
          total,
          totalData,
        },
      };
    },
    saveAdSpendDayTotal(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      const { page, total, per_page: pageSize } = meta || {};
      return {
        ...state,
        data: data || [],
        totalMeta: {
          total,
          page,
          pageSize,
        },
      };
    },
    saveFBAdSpendTotal(state, action) {
      const { data } = action.payload || {};
      // const tempTestTotal = [];
      // const tempHaveTest = false;
      const hasIncludeSomeType = type => data.findIndex(i => i.part_type === type) !== -1;
      // if (action?.payload?.meta?.test_total_data) {
      //   if (['[]'].includes(JSON.stringify(action?.payload?.meta?.test_total_data))) {
      //     tempHaveTest = true;
      //   } else {
      //     tempTestTotal = [action?.payload?.meta?.test_total_data];
      //   }
      // }
      let totalData = [];
      if (hasIncludeSomeType('内部部门')) {
        totalData = data.filter(i => i.part_type === '内部部门');
      } else if (hasIncludeSomeType(null)) {
        totalData = data.filter(i => i.part_type === null);
      }

      return {
        ...state,
        summary: {
          totalData,
          testData: hasIncludeSomeType('外部部门')
            ? data.filter(i => i.part_type === '外部部门')
            : [],
          summaryData: data,
        },
      };
    },
  },
};
