import { fetchAdOffShelfData, fetchAdOffShelfLog } from '@/services/sku';

export default {
  namespace: 'adOffShelf',
  state: {
    adOffShelfData: [],
    total: 0,
    logTotal: 0,
    logData: [],
  },

  effects: {
    *getAdOffShelfData({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdOffShelfData, payload);
      yield put({
        type: 'saveAdOffShelfData',
        payload: response,
      });
      if (callback) callback();
    },
    *getAdOffShelfLog({ payload }, { call, put }) {
      const response = yield call(fetchAdOffShelfLog, payload);
      yield put({
        type: 'saveAdOffShelfLog',
        payload: response,
      });
    },
  },
  reducers: {
    saveAdOffShelfData(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        adOffShelfData: data?.map((i, index) => ({ ...i, rowKey: index })),
        total: meta?.total || 0,
      };
    },
    saveAdOffShelfLog(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        logData: data,
        logTotal: meta?.total || 0,
      };
    },
  },
};
