import {
  fetchAccountAssets,
  fetchAdAppeal,
  fetchAdApply,
  fetchAdCharge,
  fetchAppealOperator,
  fetchPageIdList,
  fetchPixelApply,
  fetchViolation,
  fetchReviewLog,
  fetchEmailApplyList,
  fetchOldBM,
  fetchBmAsset,
  fetchBmAssetDiff,
  fetchOldBMChange,
  fetchDoubleVerify,
  fetchBmApiChange,
  fetchAssetVps,
  fetchLandingPage,
  fetchLandingStatus,
  fetchLandingPageDetail,
  fetchUserBmID,
  fetchAdAppealReason,
} from '@/services/api';
import { fetchOldBMCategories } from '@/services/asset';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'adReview',

  state: {
    adCharge: {
      data: [],
      meta: {},
    },
    adApply: {
      data: [],
      meta: {},
    },
    accountAssets: {
      data: [],
      meta: {},
    },
    pageId: {
      meta: {},
      data: [],
    },
    pixelList: {
      meta: {},
      data: [],
    },
    pixelUnassignList: {
      meta: {},
      data: [],
    },
    adViolation: {
      meta: {},
      data: [],
    },
    adAppeal: {
      meta: {},
      data: [],
    },
    appealOperator: [],
    reviewLog: {
      data: [],
    },
    emailApplyData: {
      data: [],
      meta: {},
    },
    oldBMChange: {
      data: [],
      meta: {},
    },
    oldBMCategories: [],
    bmAsset: {
      data: [],
      meta: {},
    },
    bmAssetDiff: {
      oldAsset: {},
      newAsset: {},
    },
    oldBmApply: {
      data: [],
      meta: {},
    },
    doubleVerify: {
      data: [],
      meta: {},
    },
    bmApiChange: {
      data: [],
      meta: {},
    },
    assetVps: [],
    landingPage: {
      data: [],
      meta: {},
    },
    landingStatus: {},
    landingDetail: [],
    userBmIdList: [],
    adAppealReason: [],
  },

  effects: {
    *getAdCharge({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdCharge, payload);
      yield put({
        type: 'saveAdCharge',
        payload: response,
      });
      if (callback) callback();
    },
    *getAdApply({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdApply, payload);
      yield put({
        type: 'saveAdApply',
        payload: response,
      });
      if (callback) callback();
    },
    *getPageId({ payload, callback }, { call, put }) {
      const response = yield call(fetchPageIdList, payload);
      yield put({
        type: 'savePageId',
        payload: response,
      });
      if (callback) callback();
    },
    *getAccountAssets({ payload, callback }, { call, put }) {
      const response = yield call(fetchAccountAssets, payload);
      yield put({
        type: 'saveAccountAssets',
        payload: response,
      });
      if (callback) callback();
    },
    *getViolation({ payload, callback }, { call, put }) {
      const response = yield call(fetchViolation, payload);
      yield put({
        type: 'saveViolation',
        payload: response,
      });
      if (callback) callback();
    },
    *getPixelApply({ payload, callback }, { call, put }) {
      const response = yield call(fetchPixelApply, payload);
      yield put({
        type: 'savePixelApply',
        payload: response,
      });
      if (callback) callback();
    },
    *getAdAppeal({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdAppeal, payload);
      yield put({
        type: 'saveAdAppeal',
        payload: response,
      });
      if (callback) callback();
    },
    *getAppealOperator(_, { call, put }) {
      const response = yield call(fetchAppealOperator);
      yield put({
        type: 'saveAppealOperator',
        payload: response,
      });
    },
    *getReviewLog({ payload }, { call, put }) {
      const response = yield call(fetchReviewLog, payload);
      yield put({
        type: 'saveReviewLog',
        payload: response,
      });
    },
    *getEmailApplyList({ payload }, { call, put }) {
      const response = yield call(fetchEmailApplyList, payload);
      yield put({
        type: 'saveEmailApply',
        payload: response,
      });
    },
    *getOldBMList({ payload }, { call, put }) {
      const response = yield call(fetchOldBM, payload);
      yield put({
        type: 'saveOldBMApply',
        payload: response,
      });
    },
    *getOldBmCategories({ payload }, { call, put }) {
      const response = yield call(fetchOldBMCategories, payload);
      yield put({
        type: 'saveOldBMCategories',
        payload: response,
      });
    },
    *getBmAsset({ payload, callback }, { call, put }) {
      const response = yield call(fetchBmAsset, payload);
      yield put({
        type: 'saveBmAsset',
        payload: response,
      });
      if (callback) callback();
    },
    *getBmAssetDiff({ payload, callback }, { call, put }) {
      const response = yield call(fetchBmAssetDiff, payload);
      yield put({
        type: 'saveBmAssetDiff',
        payload: response,
      });
      if (callback) callback();
    },
    *getOldBmApply({ payload, callback }, { call, put }) {
      const response = yield call(fetchOldBMChange, payload);
      yield put({
        type: 'saveOldBMChangeApply',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getDoubleVerify({ payload, callback }, { call, put }) {
      const response = yield call(fetchDoubleVerify, payload);
      yield put({
        type: 'saveDoubleVerify',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getBmApiChange({ payload, callback }, { call, put }) {
      const response = yield call(fetchBmApiChange, payload);
      yield put({
        type: 'saveBmApiChange',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getAssetVps({ payload, callback }, { call, put }) {
      const response = yield call(fetchAssetVps, payload);
      yield put({
        type: 'saveAssetVps',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getLandingPage({ payload, callback }, { call, put }) {
      const response = yield call(fetchLandingPage, payload);
      yield put({
        type: 'saveLandingPage',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getLandingStatus(_, { call, put }) {
      const response = yield call(fetchLandingStatus);
      yield put({
        type: 'saveLandingStatus',
        payload: response,
      });
    },
    *getLandingPageDetail({ payload }, { call, put }) {
      const response = yield call(fetchLandingPageDetail, payload);
      yield put({
        type: 'saveLandingPageDetail',
        payload: response,
      });
    },
    *getUserBmId({ payload }, { call, put }) {
      const response = yield call(fetchUserBmID, payload);
      yield put({
        type: 'saveUserBmId',
        payload: response,
      });
    },
    *getAdAppealReason(_, { call, put }) {
      const response = yield call(fetchAdAppealReason);
      yield put({
        type: 'saveAdAppealReason',
        payload: response,
      });
    },
  },
  reducers: {
    saveAdCharge(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        adCharge: {
          data,
          meta,
        },
      };
    },
    saveAdApply(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        adApply: {
          data,
          meta,
        },
      };
    },
    saveAccountAssets(state, action) {
      const { data = [], current_page = 1, total = 0 } = action.payload; // eslint-disable-line
      return {
        ...state,
        accountAssets: {
          data,
          meta: {
            current_page,
            total,
          },
        },
      };
    },
    savePageId(state, action) {
      const { data = [], current_page = 1, total = 0 } = action.payload || {}; // eslint-disable-line
      return {
        ...state,
        pageId: {
          data,
          meta: {
            current_page,
            total,
          },
        },
      };
    },
    saveViolation(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        adViolation: {
          data,
          meta,
        },
      };
    },
    savePixelApply(state, action) {
      const { data = [], current_page = 1, total = 0 } = action.payload || {}; // eslint-disable-line
      return {
        ...state,
        pixelList: {
          data,
          meta: {
            current_page,
            total,
          },
        },
      };
    },
    saveAdAppeal(state, action) {
      const { data = [], meta } = action.payload || {}; // eslint-disable-line
      return {
        ...state,
        adAppeal: {
          data,
          meta: {
            ...meta,
            current_page: meta?.current_page || 1,
            total: meta?.total || 0,
          },
        },
      };
    },
    saveAppealOperator(state, action) {
      const { list = [] } = action.payload || {};
      return {
        ...state,
        appealOperator: list,
      };
    },
    saveEmailApply(state, action) {
      const data = action.payload || {};
      if (data.meta) {
        return {
          ...state,
          emailApplyData: data,
        };
      }
      return {
        ...state,
        emailApplyData: {
          meta: {},
          data: [],
        },
      };
    },
    saveReviewLog(state, { payload }) {
      return {
        ...state,
        reviewLog: payload,
      };
    },
    saveOldBMApply(state, { payload }) {
      const data = payload || {};
      if (data.meta) {
        return {
          ...state,
          oldBMChange: data,
        };
      }
      return {
        ...state,
        oldBMChange: {
          meta: {},
          data: [],
        },
      };
    },
    saveOldBMCategories(state, { payload }) {
      const data = payload || [];
      return {
        ...state,
        oldBMCategories: data,
      };
    },
    cleanReviewLog(state) {
      return {
        ...state,
        reviewLog: {},
      };
    },
    saveBmAsset(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        bmAsset: {
          data,
          meta,
        },
      };
    },
    saveBmAssetDiff(state, action) {
      const { old_asset: oldAsset = [], new_asset: newAsset = [] } = action.payload || {};
      return {
        ...state,
        bmAssetDiff: {
          oldAsset,
          newAsset,
        },
      };
    },
    saveOldBMChangeApply(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        oldBmApply: {
          data,
          meta,
        },
      };
    },
    saveDoubleVerify(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        doubleVerify: {
          data,
          meta,
        },
      };
    },
    saveBmApiChange(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        bmApiChange: {
          data,
          meta,
        },
      };
    },
    saveAssetVps(state, action) {
      const { data = [] } = action.payload || {};
      return {
        ...state,
        assetVps: data,
      };
    },
    saveLandingStatus(state, action) {
      return {
        ...state,
        landingStatus: action.payload || {},
      };
    },
    saveLandingPage(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        landingPage: {
          data,
          meta,
        },
      };
    },
    saveLandingPageDetail(state, action) {
      const temp = action?.payload?.data || [];
      return {
        ...state,
        landingPageDetail: temp?.map(i => ({ ...i, uuid: generateUuid() })),
      };
    },
    saveUserBmId(state, action) {
      const { data } = action?.payload || [];
      return {
        ...state,
        userBmIdList: data,
      };
    },
    saveAdAppealReason(state, action) {
      const data = action?.payload || [];
      return {
        ...state,
        adAppealReason: data.filter(i => i),
      };
    },
  },
};
