import { message } from 'antd';
import { fetchOrderDetail } from '@/services/order';

export default {
  namespace: 'orderDetail',

  state: {
    data: [],
  },

  effects: {
    *getOrderDetail({ payload, callback }, { call, put }) {
      const response = yield call(fetchOrderDetail, payload);
      yield put({
        type: 'saveOrderDetail',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveOrderDetail(state, action) {
      const res = action.payload;
      if (!res?.data?.length) {
        message.error('未查询到数据');
      }
      return {
        ...state,
        data: res.data || [],
      };
    },
  },
};
