import { fetchClosedAd, fetchAdPolicyLog, fetchAdPolicies } from '@/services/api';

export default {
  namespace: 'adPolicy',

  state: {
    adPolicyLog: {
      data: [],
      meta: {},
    },
    closedAd: {
      data: [],
      meta: {},
    },
    policies: [],
  },

  effects: {
    *getClosedAd({ payload, callback }, { call, put }) {
      const response = yield call(fetchClosedAd, payload);
      yield put({
        type: 'saveClosedAd',
        payload: response,
      });
      if (callback) callback();
    },
    *getAdPolicyLog({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdPolicyLog, payload);
      yield put({
        type: 'saveAdPolicyLog',
        payload: response,
      });
      if (callback) callback();
    },
    *getAdPolicies(_, { call, put }) {
      const response = yield call(fetchAdPolicies);
      yield put({
        type: 'saveAdPolicies',
        payload: response,
      });
    },
  },
  reducers: {
    saveClosedAd(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        closedAd: {
          data,
          meta,
        },
      };
    },
    saveAdPolicyLog(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        adPolicyLog: {
          data,
          meta,
        },
      };
    },
    saveAdPolicies(state, action) {
      const { data = [] } = action.payload;
      return {
        ...state,
        policies: data,
      };
    },
  },
};
