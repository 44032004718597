import {
  fetchCreditCard,
  fetchCardAgents,
  fetchCardHolders,
  fetchCreditCardInfo,
  fetchInfoHolders,
} from '@/services/api';

export default {
  namespace: 'creditCard',
  state: {
    data: [],
    infoData: [],
    total: 0,
    infoTotal: 0,
    totalAmount: 0,
    agents: [],
    holders: [],
    infoHolders: [],
  },

  effects: {
    *getCreditCardData({ payload }, { call, put }) {
      const response = yield call(fetchCreditCard, payload);
      yield put({
        type: 'saveCreditCardData',
        payload: response,
      });
    },
    *getCreditCardInfo({ payload }, { call, put }) {
      const response = yield call(fetchCreditCardInfo, payload);
      yield put({
        type: 'saveCreditCardInfo',
        payload: response,
      });
    },
    *getCardAgents({ payload }, { call, put }) {
      const response = yield call(fetchCardAgents, payload);
      yield put({
        type: 'saveCardAgents',
        payload: response,
      });
    },
    *getCardHolders({ payload }, { call, put }) {
      const response = yield call(fetchCardHolders, payload);
      yield put({
        type: 'saveCardHolders',
        payload: response,
      });
    },
    *getInfoHolders({ payload }, { call, put }) {
      const response = yield call(fetchInfoHolders, payload);
      yield put({
        type: 'saveInfoHolders',
        payload: response,
      });
    },
  },
  reducers: {
    saveCreditCardData(state, action) {
      const { data, meta = {}, total_amount: totalAmount } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
        totalAmount,
      };
    },
    saveCreditCardInfo(state, action) {
      const { data, meta = {} } = action.payload;
      return {
        ...state,
        infoData: data,
        infoTotal: meta?.total || 0,
      };
    },
    saveCardAgents(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        agents: data,
      };
    },
    saveCardHolders(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        holders: data,
      };
    },
    saveInfoHolders(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        infoHolders: data,
      };
    },
  },
};
