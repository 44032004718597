import {
  fetchGroupRoi,
  fetchGroupROIBigData,
  fetchGroupROITotalData,
  fetchGroupROIStore,
  fetchGroupROIAdSpend,
  fetchGroupRoiTestTotal,
} from '@/services/api';
// import { sortByStr } from '@/utils/utils';

export default {
  namespace: 'grouproi',

  state: {
    data: [],
    totalData: [],
    unsetData: {},
    accountSpends: [],
    testData: [],
  },

  effects: {
    *getGroup({ payload, callback }, { call, put }) {
      const response = yield call(fetchGroupRoi, payload);
      yield put({
        type: 'saveGroup',
        payload: response,
      });
      if (callback) callback();
    },
    *fetchGroupROIBigData({ payload, callback }, { call, put }) {
      const response = yield call(fetchGroupROIBigData, { ...payload, page: 1, page_size: 2000 });
      yield put({
        type: 'saveListData',
        payload: response.data,
      });
      if (callback) callback();
    },
    *fetchGroupROITotalData({ payload, callback }, { call, put }) {
      const res = yield call(fetchGroupROITotalData, payload);

      // if (user) {
      //   yield put({
      //     type: 'svaeTotalAccordingList',
      //     payload: res.data,
      //   });
      // } else {
      //   yield put({
      //     type: 'saveTotalData',
      //     payload: res.data,
      //   });
      // }
      yield put({
        type: 'svaeTotalAccordingList',
        payload: res,
      });
      if (callback) callback();
    },
    *fetchGroupROITestTotalData({ payload, callback }, { call, put }) {
      const res = yield call(fetchGroupRoiTestTotal, payload);
      yield put({
        type: 'svaeGroupROITestTotalData',
        payload: res,
      });
      if (callback) callback();
    },
    *fetchGroupROIStore({ payload, callback }, { call, put }) {
      const res = yield call(fetchGroupROIStore, { ...payload, page: 1, page_size: 2000 });
      yield put({
        type: 'saveStoreList',
        payload: res.data,
      });
      if (callback) callback();
    },
    *fetchGroupROIAdSpend({ payload, callback }, { call, put }) {
      const res = yield call(fetchGroupROIAdSpend, { ...payload, page: 1, page_size: 2000 });
      yield put({
        type: 'saveAdList',
        payload: res.data,
      });
      if (callback) callback();
    },
    *fetchTotalAccordingList({ payload, callback }, { call, put }) {
      const response = yield call(fetchGroupROIBigData, { ...payload, page: 1, page_size: 2000 });
      yield put({
        type: 'svaeTotalAccordingList',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    // saveGroup(state, action) {
    //   const {
    //     data = [],
    //     total_data: totalData,
    //     unset_data: unsetData,
    //     account_spends: accountSpends,
    //   } = action.payload;
    //   data?.sort(sortByStr('name')); //eslint-disable-line
    //   return {
    //     ...state,
    //     // data,
    //     // totalData,
    //     // unsetData,
    //     // accountSpends,
    //     // testData: action.payload?.test_data ? [{ ...action.payload?.test_data, id: 1 }] : [],
    //   };
    // },
    saveListData(state, { payload }) {
      const data = payload?.filter(item => {
        return (
          Object.entries(item).reduce((prev, [key, curr]) => {
            if (typeof curr === 'number' && key !== 'purchase_value') {
              return prev + curr;
            }
            return prev;
          }, 0) !== 0
        );
      });
      return {
        ...state,
        data,
      };
    },
    saveTotalData(state, { payload }) {
      const total = payload?.filter(i => i.part_type === '内部部门');
      const testTotal = payload?.filter(i => i.part_type === '外部部门');
      return {
        ...state,
        totalData: total,
        testData: testTotal,
      };
    },
    saveStoreList(state, { payload }) {
      const data = payload?.filter(item => {
        return (
          Object.values(item).reduce((prev, curr) => {
            if (typeof curr === 'number') {
              return prev + curr;
            }
            return prev;
          }, 0) !== 0
        );
      });
      return {
        ...state,
        data,
      };
    },
    saveAdList(state, { payload }) {
      const data = payload?.filter(item => {
        return (
          Object.entries(item).reduce((prev, [key, curr]) => {
            if (
              typeof curr === 'number' &&
              ['purchase', 'spend', 'purchase_value', 'roas'].includes(key)
            ) {
              return prev + curr;
            }
            return prev;
          }, 0) !== 0
        );
      });
      return {
        ...state,
        accountSpends: data,
      };
    },
    svaeTotalAccordingList(state, { payload }) {
      return {
        ...state,
        totalData: payload?.data || [],
      };
    },
    svaeGroupROITestTotalData(state, { payload }) {
      return {
        ...state,
        testData: Object.entries(payload).reduce((total, current) => {
          if (current[0] !== 'all' && current[1]) {
            total.push({ ...current[1], summaryPart: current[0] });
          }
          return total;
        }, []),
      };
    },
  },
};
