import {
  fetchDealPeople,
  fetchErrorMsg,
  fetchStoresLists,
  fetchErrorMsgDealPeoples,
} from '@/services/api';

export default {
  namespace: 'errormsg',

  state: {
    meta: {},
    data: [],
    dealPeople: [],
    need_process_count: 0,
    storeList: [],
    peopleList: [],
  },

  effects: {
    *getErrorMsg({ payload, callback }, { call, put }) {
      const response = yield call(fetchErrorMsg, payload);
      yield put({
        type: 'saveErrorMsg',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getPeople(_, { call, put }) {
      const response = yield call(fetchDealPeople);
      yield put({
        type: 'savePeople',
        payload: response,
      });
    },
    *fetchUserStores({ payload }, { call, put }) {
      const res = yield call(fetchStoresLists, payload);
      yield put({
        type: 'saveUserStores',
        payload: res,
      });
    },
    *fetchPeopleList({ payload }, { call, put }) {
      const res = yield call(fetchErrorMsgDealPeoples, payload);
      yield put({
        type: 'savePeopleList',
        payload: res,
      });
    },
  },
  reducers: {
    saveErrorMsg(state, action) {
      const { meta = {}, data = [], need_process_count: count } = action.payload;
      return {
        ...state,
        meta,
        data,
        need_process_count: count,
      };
    },
    savePeople(state, action) {
      return {
        ...state,
        dealPeople: action.payload || [],
      };
    },
    saveUserStores(state, action) {
      return {
        ...state,
        storeList: action?.payload?.domains || [],
      };
    },
    savePeopleList(state, action) {
      return {
        ...state,
        peopleList: action?.payload || [],
      };
    },
  },
};
