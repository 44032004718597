import { fetchOrderReport } from '@/services/api';

export default {
  namespace: 'orderReport',
  state: {
    data: [],
    totalData: [],
  },

  effects: {
    *getOrderReport({ payload, callback }, { call, put }) {
      const response = yield call(fetchOrderReport, payload);
      yield put({
        type: 'saveOrderReport',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveOrderReport(state, action) {
      const { data = [], total_data: totalData } = action.payload;
      return {
        ...state,
        data: data || [],
        totalData: totalData ? [totalData] : [],
      };
    },
  },
};
