import { fetchOrderLogistics } from '@/services/api';

export default {
  namespace: 'orderLogistics',
  state: {
    data: [],
    total: 0,
  },

  effects: {
    *getOrderLogistics({ payload, callback }, { call, put }) {
      const response = yield call(fetchOrderLogistics, payload);
      yield put({
        type: 'saveOrderLogistics',
        payload: { response, tabName: payload.tabName },
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveOrderLogistics(state, action) {
      const {
        response: { data = [], meta },
        tabName,
      } = action.payload || {};
      return {
        ...state,
        data: data?.map(i => ({ ...i, uniqueTypeAndId: tabName + i.id })),
        total: meta?.total || 0,
      };
    },
  },
};
