import request from '@/utils/request';

//运营分部门人数
export async function fetchOperatPartPeople(data) {
  return request('/api/user/resign/statistics/detail', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
//运营分部门人数
export async function fetchOperatPartPeopleTotal(data) {
  return request('/api/user/resign/statistics/total', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

//导出分部门数据
export async function exportOperatPartPeople(data) {
  return request('/api/user/resign/statistics/export', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

//爱马仕用户列表
export async function fetchUserList(params) {
  return request(`/api/users/username`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}

//用户书签列表
export async function fetchUserBookMark(params) {
  return request(`/api/user/book_mark`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}

//删除书签
export async function removeUserBookMark(id, data) {
  return request(`/api/user/book_mark/${id}`, {
    method: 'DELETE',
    data,
    removeBlankProperty: true,
  });
}

//更新书签
export async function updateUserBookMark(id, data) {
  return request(`/api/user/book_mark/${id}`, {
    method: 'PUT',
    data,
    removeBlankProperty: true,
  });
}

//新增书签
export async function createUserBookMark(data) {
  return request(`/api/user/book_mark`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 更新用户马帮信息
export async function updateMaBangUser(data) {
  const id = data.id;
  delete data.id;
  return request(`/api/users/${id}/ma-bang`, {
    method: 'PUT',
    data,
    removeBlankProperty: true,
  });
}
// 获取指定用户最新马帮名称
export async function fetchLatestMaBangName(id) {
  return request(`/api/users/${id}/ma-bang-name`, {
    method: 'GET',
  });
}

// 获取店铺新归属人的关联邮箱
export async function fetchUserInfo(params) {
  return request(`/api/users/get-user-info`, {
    method: 'GET',
    params,
  });
}
//获取自己及底下人员店铺
export async function fetchUserStores(params) {
  return request(`/api/users/stores`, {
    method: 'GET',
    params,
  });
}

// 根据域名获取人员
export async function fetchUserStoresInfo(params) {
  return request(`/api/users/get-store-info`, {
    method: 'GET',
    params,
  });
}

export async function fetchAssociationUsers() {
  return request('/api/association-users');
}

// 用户密码策略-配置获取
export async function fetchUserPwdRuleConfig() {
  return request(`/api/user-pwd-rule/get-config`, {
    method: 'GET',
  });
}

// 用户密码策略-修改配置
export async function updateUserPwdRuleConfig(data) {
  return request(`/api/user-pwd-rule/set-config`, {
    method: 'POST',
    data,
  });
}

// 批量重置密码
export async function batchResetPassword(data) {
  return request(`/api/users/batch-reset-password`, {
    method: 'PUT',
    data,
  });
}
// 批量新增用户模板
export async function fetchUserTemplate() {
  return request(`/api/users/batch-create-users/template`);
}
// 批量新增密码
export async function batchCreatePassword(data) {
  return request(`/api/users/batch-create-users`, {
    method: 'POST',
    data,
  });
}

// 用户管理关联人员导出
export async function exportUserPeoples(params) {
  return request(`/api/users/user_peoples`, {
    method: 'GET',
    params,
    headers: {
      export: 'xlsx',
    },
  });
}
