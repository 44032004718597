import {
  fetchAnalysisData,
  fetchOrders,
  fetchSpecifiedOrders,
  fetchTracking,
  fetchTrackingLogsWithIdDetail,
} from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'order',

  state: {
    orders: [],
    meta: {},
    specifiedOrders: {
      data: [],
      meta: {},
    },
    tracking: {
      data: [],
      meta: {},
    },
    analysisData: [],
    platformsList: [],
    total: [],
    trackingDetail: {
      data: [],
      meta: {},
    },
    haveTest: false,
    testTotal: [],
  },

  effects: {
    *getOrders({ payload, callback }, { call, put }) {
      const response = yield call(fetchOrders, payload);
      yield put({
        type: 'saveOrders',
        payload: response,
      });
      if (callback) callback();
    },
    *getSpecifiedOrders({ payload, callback }, { call, put }) {
      const response = yield call(fetchSpecifiedOrders, payload);
      yield put({
        type: 'saveSpecifiedOrders',
        payload: response,
      });
      if (callback) callback();
    },
    *getTracking({ payload, callback }, { call, put }) {
      const response = yield call(fetchTracking, payload);
      yield put({
        type: 'saveTracking',
        payload: response,
      });
      if (callback) callback();
    },
    *getAnalysisData({ payload, callback }, { call, put }) {
      const response = yield call(fetchAnalysisData, payload);
      yield put({
        type: 'saveAnalysisData',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getTrackingLogsWithIdDetail({ payload, callback }, { call, put }) {
      const response = yield call(fetchTrackingLogsWithIdDetail, payload);
      yield put({
        type: 'saveTrackingLogsWithIdDetail',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveOrders(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        orders: data,
        meta,
      };
    },
    saveSpecifiedOrders(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        specifiedOrders: {
          data,
          meta,
        },
      };
    },
    saveTracking(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        tracking: {
          data,
          meta,
        },
      };
    },
    saveAnalysisData(state, action) {
      const { data = [], platforms = [], total = {} } = action.payload;
      for (let i = 0; i < data?.length; i += 1) {
        data[i].id = generateUuid();
      }
      let tempTestTotal = [];
      let tempHaveTest = false;
      if (action?.payload?.test_total) {
        if (['[]'].includes(JSON.stringify(action?.payload?.test_total))) {
          tempHaveTest = true;
        } else {
          tempTestTotal = [action?.payload?.test_total];
        }
      }
      return {
        ...state,
        analysisData: data || [],
        platformsList: platforms || [],
        total: total ? [total] : [],
        testTotal: tempTestTotal,
        haveTest: tempHaveTest,
      };
    },
    saveTrackingLogsWithIdDetail(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        trackingDetail: {
          data,
          meta,
        },
      };
    },
    clearOrderListData(state) {
      return {
        ...state,
        orders: [],
        meta: {},
      };
    },
  },
};
