export function memorizeFn(fn) {
  let prevArgs;
  const memorized = (...args) => {
    prevArgs = args;
    return fn(...args);
  };
  memorized.getPrevArgs = () => prevArgs;
  return memorized;
}

export function intersection(...args) {
  if (args.length === 1) {
    return args[0];
  }
  const [a, b, ...nextArgs] = args;
  const s = new Set(b);
  const result = [...new Set(a)].filter(x => s.has(x));
  if (nextArgs.length > 0) {
    return intersection(result, ...nextArgs);
  }
  return result;
}

export function setToken(token) {
  const newToken = JSON.parse(localStorage.getItem('api-token'));
  newToken.access_token = token.replaceAll('\n', '');
  localStorage.setItem('api-token', JSON.stringify(newToken));
}
