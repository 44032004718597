import { getSkuSaleJob } from '@/services/sku';

export default {
  namespace: 'skusales',

  state: {
    exportData: { total: 0, data: [] },
  },

  effects: {
    *getSkuSaleJobList({ payload, callback }, { call, put }) {
      const response = yield call(getSkuSaleJob, payload);
      yield put({
        type: 'saveSkuSaleJobList',
        payload: response,
      });
      if (callback) callback();
    },
  },

  reducers: {
    saveSkuSaleJobList(state, action) {
      const { meta: { total } = {}, data = [] } = action.payload || {};
      return {
        ...state,
        exportData: {
          total,
          data,
        },
      };
    },
  },
};
