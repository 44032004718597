import { history } from 'umi';
import { fetchCurUser, fetchTheme } from '@/services/api';
import { getToken } from '@/utils/authority';
import { reloadAuthorized } from './utils/Authorized';
import { ConfigProvider } from 'antd';

const loginPath = '/user/login';

export async function getInitialState() {
  let theme = {};
  try {
    const themeResponse = await fetchTheme();
    theme = themeResponse;
    theme.config = JSON.parse(themeResponse.config) ?? {};
    window.theme = theme;
    ConfigProvider.config(window['theme'].config.ConfigProvider);
    console.log('ConfigProvider.config successful');
    if (theme.config?.htmlStyle) {
      const htmlDom = document.querySelector('html');
      Object.entries(theme.config.htmlStyle).forEach(([key, value]) => {
        htmlDom.style[key] = value;
      });
      console.log('Html.style successful');
    }
  } catch (e) {
    console.log('ConfigProvider.config failed', e);
  }
  const fetchUserInfo = async () => {
    // if(window.location.pathname=='/user/login') return
    try {
      const msg = await fetchCurUser();
      return msg;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  // 如果是登录页面，不执行
  if (JSON.stringify(getToken()) !== '{}' && window.location.pathname !== '/user/login') {
    const currentUser = await fetchUserInfo();
    if (currentUser?.permissions) {
      localStorage.setItem('current-user', JSON.stringify(currentUser));
      reloadAuthorized();
    }
    return {
      // fetchUserInfo,
      currentUser,
      theme,
      // settings: {},
    };
  }
  return {
    // fetchUserInfo,
    // settings: {},
    currentUser: { permissions: [] },
    theme,
  };
}
