import { fetchSkuManage, getPeopleList } from '@/services/api';
import { fetchAddChildSku, fetchComplexSkuList, searchVsku, uploadGroupSku } from '@/services/sku';
import { exportTskuToVsku, sortByStr } from '@/utils/utils';

export default {
  namespace: 'complexSku',
  state: {
    childSku: [],
    skuList: {
      data: [],
      total: [],
    },
    meta: {},
    complexSkuList: {
      data: [],
      totalCount: 0,
    },
    peopleList: [],
  },
  effects: {
    *getComplexSkuList({ payload, callback }, { call, put }) {
      const response = yield call(fetchComplexSkuList, payload);
      yield put({
        type: 'saveComplexSkuList',
        payload: response,
      });
      if (callback) callback(response);
    },
    *addChildSku({ payload, callback }, { call, put }) {
      const response = yield call(fetchAddChildSku, payload);
      callback(response);
      yield put({
        type: 'saveChildSku',
        payload: response,
      });
    },
    *uploadGroupSku({ payload, callback }, { call }) {
      const response = yield call(uploadGroupSku, payload);
      if (callback) callback(response);
    },
    *searchVsku({ payload }, { call, put }) {
      const response = yield call(searchVsku, payload);

      yield put({
        type: 'saveVChildSku',
        payload: response,
      });
    },
    *searchTsku({ payload }, { call, put }) {
      const response = yield call(fetchSkuManage, payload);
      yield put({
        type: 'saveTChildSku',
        payload: response,
      });
    },
    *getPeople(_, { call, put }) {
      const response = yield call(getPeopleList);
      yield put({
        type: 'savePeople',
        payload: response,
      });
    },
  },
  reducers: {
    saveChildSku(state, action) {
      return {
        ...state,
        childSku: action.payload.data,
      };
    },
    saveVChildSku(state, action) {
      const {
        data,
        meta: { total },
      } = action.payload;
      return {
        ...state,
        skuList: {
          data: data || [],
          total: total || 0,
        },
      };
    },
    saveTChildSku(state, action) {
      const { meta, data } = action.payload;
      const result = exportTskuToVsku(data);
      return {
        ...state,
        skuList: {
          data: result,
          total: meta.total || 0,
        },
      };
    },
    saveComplexSkuList(state, action) {
      const { payload } = action;
      const {
        data,
        meta: { total: totalCount },
      } = payload;
      return {
        ...state,
        complexSkuList: { data, totalCount },
      };
    },
    savePeople(state, action) {
      const { payload } = action;
      payload?.sort?.(sortByStr('name'));
      return {
        ...state,
        peopleList: payload?.sort?.(sortByStr('name')) ?? payload,
      };
    },
  },
};
