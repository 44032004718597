import { fetchProductType } from '@/services/sku';

export default {
  namespace: 'assetReview',
  state: {
    productType: [],
  },
  effects: {
    *fetchProductType(_, { call, put }) {
      const response = yield call(fetchProductType);
      yield put({
        type: 'saveProductType',
        payload: response,
      });
    },
  },
  reducers: {
    saveProductType(state, action) {
      const { payload } = action;
      return {
        ...state,
        productType:
          payload.map(item => ({
            label: item.type_name.slice(0, item.type_name.indexOf('(')),
            value: item.type_name.slice(0, item.type_name.indexOf('(')),
          })) || [],
      };
    },
  },
};
