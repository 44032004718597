import { fetchProductAfterSale } from '@/services/api';

export default {
  namespace: 'productAfterSalesReview',
  state: {
    data: [],
    total: 0,
    landingPageOrPost: [],
  },
  effects: {
    *getProductAfterSale({ payload }, { call, put }) {
      const response = yield call(fetchProductAfterSale, payload);
      yield put({
        type: 'saveProductAfterSale',
        payload: response,
      });
    },
  },
  reducers: {
    saveProductAfterSale(state, action) {
      const { data = [], meta } = action.payload;
      return {
        ...state,
        data,
        total: meta?.totalNum || 0,
      };
    },
    saveLandingPageOrPost(state, action) {
      const { data = [] } = action.payload;
      return {
        ...state,
        landingPageOrPost: data || [],
      };
    },
  },
};
