import { fetchComparedData } from '@/services/api';

export default {
  namespace: 'compared',
  state: {
    data: [],
    meta: {
      total: 0,
      perPage: 30,
      currentPage: 1,
    },
  },
  effects: {
    *getComparedData({ payload }, { call, put }) {
      const response = yield call(fetchComparedData, payload);
      yield put({
        type: 'saveCompared',
        payload: response,
      });
    },
  },
  reducers: {
    saveCompared(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        data,
        meta: {
          total: meta.total,
          perPage: meta.per_page,
          currentPage: meta.current_page,
        },
      };
    },
  },
};
