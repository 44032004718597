import request from '@/utils/request';
import { stringify } from 'qs';

// 异常处理订单列表
export async function fetchAbnormalOrder(data) {
  return request(`/api/order/abnormal_order/index`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 异常处理订单详情
export async function fetchAbnormalOrderDetail(params) {
  return request(`/api/order/abnormal_order/detail`, {
    params: { ...params, type: 1 },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 新增异常订单处理操作记录
export function updateAbnormalOrder(data) {
  return request('/api/order/abnormal_order/add', {
    method: 'POST',
    data,
    // removeBlankProperty: true,
  });
}

// 批量处理异常订单处理记录
export function batchUpdateAbnormalOrder(data) {
  return request('/api/order/abnormal-order/batch', {
    method: 'POST',
    data,
    // removeBlankProperty: true,
  });
}

// 异常订单处理异步导出
export async function createAbnormalOrderExcel(data) {
  return request('/api/order/abnormal_order/async_excel', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 异常处理订单-马帮同步日志
export async function fetchSyncMabangLogs(params) {
  return request(`/api/order/abnormal_order/mabang-logs`, {
    params,
    removeBlankProperty: true,
  });
}

// 仓库24小时发货率列表
export async function fetchWareHouseDeliveryRate(params) {
  return request(`/api/warehouse-delivery-rate/order`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 仓库24小时发货率列表异步导出
export async function createWareHouseDeliveryRateExportTask(params) {
  return request(`/api/warehouse-delivery-rate/order/async-export`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// sku维度导出
export async function createWareHouseDeliveryRateSkuExportTask(params) {
  return request(`/api/warehouse-delivery-rate/sku/async-export`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// 仓库24小时发货率tab=>SKU
export async function warehouseDeliveryRate(params) {
  return request(`/api/warehouse-delivery-rate/sku`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
//
export async function warehouseDeliveryRateDetail(params) {
  return request(`/api/warehouse-delivery-rate/sku/detail`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

//订单详情
export async function fetchOrderDetail(data) {
  return request(`/api/orders/details`, {
    method: 'POST',
    data,
  });
}

// sku供应链下载
export async function skuSupplyDownload(data) {
  return request('/api/order/sku-supplies', {
    method: 'POST',
    data,
  });
}

// 仓库列表
export async function fetchWarehousesList() {
  return request('/api/warehouse-delivery-rate/warehouses', {
    method: 'GET',
  });
}
