import { getCollectionList } from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'collection',
  state: {
    data: [],
    total: 0,
    totalData: [],
    testTotalData: [],
    havaTest: false,
  },

  effects: {
    *getCollection({ payload, callback }, { call, put }) {
      const response = yield call(getCollectionList, payload);
      yield put({
        type: 'saveCollection',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveCollection(state, action) {
      const { data = [], meta, total_data: totalData } = action.payload;
      const realTotal =
        data.length > 0 ? [totalData]?.map(i => ({ ...i, uuid: generateUuid() })) : [];
      let tempTestTotal = [];
      let tempHaveTest = false;
      if (action?.payload?.test_data) {
        if (['[]'].includes(JSON.stringify(action?.payload?.test_data))) {
          tempHaveTest = true;
        } else {
          tempTestTotal = [action?.payload?.test_data];
        }
      }
      return {
        ...state,
        data: data.map(i => ({ ...i, uuid: generateUuid() })),
        total: meta?.total || 0,
        totalData: realTotal,
        testTotalData: tempTestTotal,
        havaTest: tempHaveTest,
      };
    },
  },
};
