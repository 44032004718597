import { fetchDetailById } from '@/services/api';

const initState = {
  sales: {},
  sessions: {},
  orders: {},
  spend: {},
};

export default {
  namespace: 'detail',

  state: initState,

  effects: {
    *getDetailById({ payload, callback }, { call, put }) {
      const response = yield call(fetchDetailById, payload);
      yield put({
        type: 'saveDetail',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveDetail(state, action) {
      const { sales = {}, orders = {}, sessions = {}, spend = {} } = action.payload;
      return {
        ...state,
        sales,
        orders,
        sessions,
        spend,
      };
    },
    clear() {
      return initState;
    },
  },
};
