import { fetchGoogleAdSpend } from '@/services/api';

export default {
  namespace: 'googleAdSpend',
  state: {
    data: [],
    total: 0,
    accountName: [],
    totalData: [],
  },

  effects: {
    *getGoogleAdSpend({ payload }, { call, put }) {
      const response = yield call(fetchGoogleAdSpend, payload);
      yield put({
        type: 'saveGoogleAdSpend',
        payload: response,
      });
    },
  },
  reducers: {
    saveGoogleAdSpend(state, action) {
      const { data, meta, summary } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
        totalData: 'add_to_cart' in summary ? [summary] : [],
      };
    },
  },
};
