import {
  fetchOperationDispute,
  fetchPPFamilyDispute,
  fetchStoreDispute,
  fetchSpecialTotal,
  fetchOperationSpecialTotal,
} from '@/services/api';
import {
  fetchSkuDispute,
  fetchSkuDisputeData,
  fetchSkuDisputeByStore,
  fetchSkuDisputeDataNew,
} from '@/services/sku';
import { generateUuid } from '@/utils/utils';

const getSpecialTotal = data => {
  return Array.isArray(data)
    ? []
    : Object.entries(data).reduce((total, current) => {
        if (current[0] && current[1]) {
          total.push({ ...current[1], summaryPart: current[0] });
        }
        return total;
      }, []);
};

const formatResponse = data => {
  const newData = Object.entries(data).reduce((prev, [key, value]) => {
    if (!Number.isNaN(Number(value))) {
      return { ...prev, [key]: Number(value) };
    }
    return { ...prev, [key]: value };
  }, {});
  return newData;
};

export default {
  namespace: 'dispute',
  state: {
    vSku: {
      data: [],
      total: 0,
      totalData: [],
    },
    tSku: {
      data: [],
      total: 0,
      totalData: [],
    },
    sku: {
      data: [],
      total: 0,
      totalData: [],
      testData: [],
      specialTotal: [],
    },
    store: {
      data: [],
      totalData: [],
      totalCount: 0,
      testTotal: [],
      haveTest: false,
      specialTotal: [],
    },
    operation: {
      data: [],
      totalData: [],
      testTotal: [],
      haveTest: false,
      totalCount: 0,
      specialTotal: [],
    },

    ppFamily: {
      data: [],
      totalData: [],
      total: 0,
    },
  },
  effects: {
    *getVskuDispute({ payload }, { call, put }) {
      const response = yield call(fetchSkuDispute, payload);

      yield put({
        type: 'saveVskuDispute',
        payload: response,
      });
    },
    *getTskuDispute({ payload }, { call, put }) {
      const response = yield call(fetchSkuDispute, payload);
      yield put({
        type: 'saveTskuDispute',
        payload: response,
      });
    },
    *fetchSkuDispute({ payload, callback }, { call, put }) {
      const response = yield call(fetchSkuDisputeData, payload);
      yield put({
        type: 'saveSkuDispute',
        payload: response,
      });
      if (callback) callback(response);
    },
    *fetchSkuDisputeNew({ payload, callback }, { call, put }) {
      const response = yield call(fetchSkuDisputeDataNew, payload);
      yield put({
        type: 'saveSkuDisputeNew',
        payload: response,
      });
      if (callback) callback(response);
    },
    *fetchSkuDisputeTotalNew({ payload, callback }, { call, put }) {
      const response = yield call(fetchSkuDisputeDataNew, { ...payload, page: 1 });
      yield put({
        type: 'saveSkuDisputeTotalNew',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getSkuDisputeByStore({ payload, callback }, { call, put }) {
      const response = yield call(fetchSkuDisputeByStore, payload);
      yield put({
        type: 'saveSkuDisputeByStore',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getOperationDispute({ payload, callback }, { call, put }) {
      const response = yield call(fetchOperationDispute, payload);
      yield put({
        type: 'saveOperationDispute',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getOperationTotal({ payload }, { call, put }) {
      const response = yield call(fetchOperationDispute, payload);
      yield put({
        type: 'saveOperationTotal',
        payload: response,
      });
    },
    *getOperationSpecialTotal({ payload }, { call, put }) {
      const response = yield call(fetchOperationSpecialTotal, payload);
      yield put({
        type: 'saveOperationSpecialTotal',
        payload: response,
      });
    },

    *getStoreDispute({ payload, callback }, { call, put }) {
      const response = yield call(fetchStoreDispute, payload);
      yield put({
        type: 'saveStoreDispute',
        payload: response,
      });
      if (callback) callback();
    },
    *getStoreTotalDispute({ payload }, { call, put }) {
      const response = yield call(fetchStoreDispute, payload);
      yield put({
        type: 'saveStoreTotalDispute',
        payload: response,
      });
    },
    *getPPFamilyDispute({ payload, callback }, { call, put }) {
      const response = yield call(fetchPPFamilyDispute, payload);
      yield put({
        type: 'savePPFamilyDispute',
        payload: response,
      });
      if (callback) callback();
    },
    *getStoreSpecialTotal({ payload }, { call, put }) {
      const response = yield call(fetchSpecialTotal, payload);
      yield put({
        type: 'saveStoreSpecialTotal',
        payload: response,
      });
    },
    *getSkuSpecialTotalByStore({ payload }, { call, put }) {
      const response = yield call(fetchSkuDisputeDataNew, payload);
      yield put({
        type: 'saveSkuSpecialTotalByStore',
        payload: response,
      });
    },
    *getSkuTotalByStore({ payload }, { call, put }) {
      const response = yield call(fetchSkuDisputeByStore, payload);
      yield put({
        type: 'saveSkuTotalByStore',
        payload: response,
      });
    },
  },
  reducers: {
    saveVskuDispute(state, action) {
      const { data = [], meta = {}, total: totalData = {} } = action.payload;
      const totalDataSource = [];
      if (totalData && !['{}', '[]'].includes(JSON.stringify(totalData))) {
        totalData.id = 1;
        totalDataSource.push(totalData);
      }
      return {
        ...state,
        vSku: {
          data: data?.map(i => ({ ...i, uuid: generateUuid() })),
          total: meta?.total || 0,
          totalData: totalDataSource || [],
        },
      };
    },
    saveTskuDispute(state, action) {
      const { data = [], meta = {}, total: totalData = {} } = action.payload;
      const totalDataSource = [];
      if (totalData && !['{}', '[]'].includes(JSON.stringify(totalData))) {
        totalData.id = 2;
        totalDataSource.push(totalData);
      }
      return {
        ...state,
        tSku: {
          data: data.map(i => ({ ...i, uuid: generateUuid() })),
          total: meta?.total || 0,
          totalData: totalDataSource || [],
        },
      };
    },
    saveSkuDispute(state, action) {
      const { data = [], meta = {}, total: totalData = {} } = action.payload;
      const totalDataSource = [];
      if (totalData && !['{}', '[]'].includes(JSON.stringify(totalData))) {
        totalData.id = 2;
        totalDataSource.push(totalData);
      }
      return {
        ...state,
        sku: {
          data: data.map(i => ({ ...i, uuid: generateUuid() })),
          total: meta?.total || 0,
          totalData: totalDataSource || [],
        },
      };
    },
    saveSkuDisputeNew(state, action) {
      const { data = [], meta = {}, total: totalData = {} } = action.payload;
      const totalDataSource = [];
      if (totalData && !['{}', '[]'].includes(JSON.stringify(totalData))) {
        totalData.id = 2;
        totalDataSource.push(totalData);
      }
      return {
        ...state,
        sku: {
          ...state.sku,
          data: data.map(i => ({ ...formatResponse(i), uuid: generateUuid() })),
          total: meta?.total || 0,
          // totalData: totalDataSource || [],
        },
      };
    },
    saveSkuDisputeTotalNew(state, action) {
      const { data: totalData = [] } = action.payload;
      const totalDataSource = [];
      if (totalData && !['{}', '[]'].includes(JSON.stringify(totalData))) {
        totalDataSource.push({ ...formatResponse(totalData[0]), id: 2 });
      }
      return {
        ...state,
        sku: {
          ...state.sku,
          totalData: totalDataSource || [],
        },
      };
    },
    saveSkuDisputeByStore(state, action) {
      const { data = [], meta = {} } = action.payload;
      // const totalData = total?.filter(i => i.part_type === '内部部门');
      // const testData = total?.filter(i => i.part_type === '外部部门');
      return {
        ...state,
        sku: {
          ...state.sku,
          data: data.map(i => ({ ...i, uuid: generateUuid() })),
          total: meta?.total || 0,
          // totalData,
          // testData,
        },
      };
    },
    saveOperationDispute(state, action) {
      const { data = [], total = [], meta } = action.payload;
      // const totalDataSource = [];
      // if (total && !['{}', '[]'].includes(JSON.stringify(total))) {
      //   total.id = 1;
      //   totalDataSource.push(total);
      // }
      // let tempTestTotal = [];
      // let tempHaveTest = false;
      // if (action?.payload?.test_total) {
      //   if (['[]'].includes(JSON.stringify(action?.payload?.test_total))) {
      //     tempHaveTest = true;
      //   } else {
      //     tempTestTotal = [action?.payload?.test_total];
      //   }
      // }
      return {
        ...state,
        operation: {
          ...state.operation,
          data: data?.map(i => ({ ...i, uuid: generateUuid() })),
          // totalData: totalDataSource,
          // testTotal: tempTestTotal,
          // haveTest: tempHaveTest,
          totalCount: meta?.total || 0,
        },
      };
    },
    saveStoreDispute(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        store: {
          ...state.store,
          data: data?.map(i => ({ ...i, uuid: generateUuid() })),
          totalCount: meta?.total || 0,
        },
      };
    },

    savePPFamilyDispute(state, action) {
      const { data = [], total = [], meta } = action.payload || [];
      const totalDataSource = [];
      if (total && !['{}', '[]'].includes(JSON.stringify(total))) {
        total.id = 1;
        totalDataSource.push(total);
      }

      return {
        ...state,
        ppFamily: {
          data: data?.map(i => ({ ...i, uuid: generateUuid() })) || [],
          totalData: totalDataSource || [],
          total: meta?.total || 0,
        },
      };
    },
    saveStoreSpecialTotal(state, { payload }) {
      return {
        ...state,
        store: {
          ...state.store,
          specialTotal: getSpecialTotal(payload?.data),
        },
      };
    },
    saveStoreTotalDispute(state, { payload }) {
      return {
        ...state,
        store: {
          ...state.store,
          totalData: payload?.data || [],
        },
      };
    },
    saveOperationSpecialTotal(state, { payload }) {
      return {
        ...state,
        operation: {
          ...state.operation,
          specialTotal: getSpecialTotal(payload?.data || []),
        },
      };
    },
    saveOperationTotal(state, { payload }) {
      const firstData = payload?.data?.[0];
      return {
        ...state,
        operation: {
          ...state.operation,
          totalData:
            !firstData?.total_order_count && !firstData?.total_order_amount_usd
              ? []
              : payload?.data,
        },
      };
    },

    saveSkuSpecialTotalByStore(state, { payload }) {
      return {
        ...state,
        sku: {
          ...state.sku,
          specialTotal: getSpecialTotal(payload?.data || []),
        },
      };
    },
    saveSkuTotalByStore(state, { payload }) {
      return {
        ...state,
        sku: {
          ...state.sku,
          totalData: payload?.data || [],
        },
      };
    },
  },
};
