import {
  addUsersPart,
  addUsersStaff,
  addUsersStore,
  deleteUsersPart,
  deleteUsersStore,
  fetchNoAccountUsers,
  fetchPart,
  fetchPermissionTypes,
  fetchPermissions,
  fetchRolesOptions,
  fetchStoresLists,
  fetchUserPeople,
  fetchUsersStaff,
  fetchUsersStaffOptions,
  fetchUserOptions,
  fetchWorkPeople,
} from '@/services/api';
import { fetchUserList } from '@/services/user';

export default {
  namespace: 'accountUser',

  state: {
    usersStaff: {
      data: [],
      meta: {},
    },
    userNames: [],
    partLists: [],
    rolesOptions: [],
    permissionsOptions: [],
    parts: [],
    storesLists: { vendors: [], domains: [], parts: [] },
    noAccountUsers: [],
    permissionTypes: [],
    userPeople: {},
    userOptions: [],
    workPeoples: [],
    userList: [],
    workUserList: [],
  },

  effects: {
    *getStoresLists({ payload, callback }, { call, put }) {
      const response = yield call(fetchStoresLists, payload);
      yield put({
        type: 'saveStoresLists',
        payload: response,
      });
      if (callback) callback();
    },
    *getUsersStaff({ payload, callback }, { call, put }) {
      const response = yield call(fetchUsersStaff, payload);
      yield put({
        type: 'saveUsersStaff',
        payload: response,
      });
      if (callback) callback();
    },
    *getUsersStaffOptions({ payload, callback }, { call, put }) {
      const response = yield call(fetchUsersStaffOptions, payload);
      yield put({
        type: 'saveUsersStaffOptions',
        payload: response,
      });
      if (callback) callback();
    },
    *getParts({ payload, callback }, { call, put }) {
      const response = yield call(fetchPart, payload);
      yield put({
        type: 'saveParts',
        payload: response,
      });
      if (callback) callback();
    },
    *getRolesOptions({ payload, callback }, { call, put }) {
      const response = yield call(fetchRolesOptions, payload);
      yield put({
        type: 'saveRolesOptions',
        payload: response,
      });
      if (callback) callback();
    },
    *getPermissionsOptions({ payload, callback }, { call, put }) {
      const response = yield call(fetchPermissions, payload);
      yield put({
        type: 'savePermissionsOptions',
        payload: response,
      });
      if (callback) callback();
    },
    *addUsersStaff({ payload, callback }, { call }) {
      const response = yield call(addUsersStaff, payload);
      if (callback) callback(response);
    },
    *addUsersPart({ payload, callback }, { call }) {
      const response = yield call(addUsersPart, payload);
      if (callback) callback(response);
    },
    *deleteUsersStaff({ payload, callback }, { call }) {
      const response = yield call(deleteUsersPart, payload);
      if (callback) callback(response);
    },
    *deleteUsersStore({ payload, callback }, { call }) {
      const response = yield call(deleteUsersStore, payload);
      if (callback) callback(response);
    },
    *addUsersStore({ payload, callback }, { call }) {
      const response = yield call(addUsersStore, payload);
      if (callback) callback(response);
    },
    *getNoAccountUsers(_, { call, put }) {
      const response = yield call(fetchNoAccountUsers);
      yield put({
        type: 'saveNoAccountUsers',
        payload: response,
      });
    },
    *getPermissionTypes(_, { call, put }) {
      const response = yield call(fetchPermissionTypes);
      yield put({
        type: 'savePermissionTypes',
        payload: response,
      });
    },
    *getUserPeople({ payload, callback }, { call, put }) {
      const response = yield call(fetchUserPeople, payload);
      yield put({
        type: 'saveUserPeople',
        payload: response,
      });
      if (callback) callback();
    },
    *getUserOptions({ payload, callback }, { call, put }) {
      const response = yield call(fetchUserOptions, payload);
      yield put({
        type: 'saveUserOptions',
        payload: response,
      });
      if (callback) callback();
    },
    *fetchWorkPeoples(_, { call, put }) {
      const res = yield call(fetchWorkPeople, { status: 1 });
      yield put({
        type: 'saveWorkPeoples',
        payload: res,
      });
    },
    *fetchUserList({ payload }, { call, put }) {
      const res = yield call(fetchUserList, payload);
      yield put({
        type: 'saveUserList',
        payload: res,
      });
    },
    *fetchWorkUserList({ payload }, { call, put }) {
      const res = yield call(fetchUserList, payload);
      yield put({
        type: 'saveWorkUserList',
        payload: res,
      });
    },
  },
  reducers: {
    saveUsersStaff(state, action) {
      const data = action.payload;
      return {
        ...state,
        usersStaff: data,
      };
    },
    saveUsersStaffOptions(state, action) {
      const { user_names: userNames, part_lists: partLists } = action.payload;
      return {
        ...state,
        userNames,
        partLists,
      };
    },
    saveRolesOptions(state, action) {
      const data = action.payload;
      return {
        ...state,
        rolesOptions: data,
      };
    },
    savePermissionsOptions(state, action) {
      const data = action.payload;
      return {
        ...state,
        permissionsOptions: data,
      };
    },
    saveParts(state, action) {
      const data = action.payload;
      return {
        ...state,
        parts: data,
      };
    },
    saveStoresLists(state, action) {
      const data = action.payload;
      return {
        ...state,
        storesLists: data,
      };
    },
    saveNoAccountUsers(state, action) {
      return {
        ...state,
        noAccountUsers: action.payload || [],
      };
    },
    savePermissionTypes(state, action) {
      return {
        ...state,
        permissionTypes: action.payload || [],
      };
    },
    saveUserPeople(state, action) {
      const { data = [], total = 0, current_page: currentPage = 1 } = action.payload || {};
      return {
        ...state,
        userPeople: {
          data,
          total,
          currentPage,
        },
      };
    },
    saveUserOptions(state, action) {
      const { data = [] } = action.payload || {};
      return {
        ...state,
        userOptions: data,
      };
    },
    saveWorkPeoples(state, { payload }) {
      return {
        ...state,
        workPeoples: payload.user_names,
      };
    },
    saveUserList(state, { payload }) {
      return {
        ...state,
        userList: payload?.data || [],
      };
    },
    saveWorkUserList(state, { payload }) {
      return {
        ...state,
        workUserList: payload?.data || [],
      };
    },
  },
};
