import { fetchBlockDomain } from '@/services/api';

export default {
  namespace: 'blockDomain',

  state: {
    blockDomain: {
      data: [],
      total: 0,
    },
  },

  effects: {
    *fetchBlockDomain({ payload }, { call, put }) {
      const response = yield call(fetchBlockDomain, payload);
      yield put({
        type: 'saveBlockDomain',
        payload: response,
      });
    },
  },
  reducers: {
    saveBlockDomain(state, { payload }) {
      return {
        ...state,
        blockDomain: payload,
      };
    },
  },
};
