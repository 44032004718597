// import { stringify } from 'qs';
// import { routerRedux } from 'dva/router';

import { fetchCurUser, login, logout, saveUserHelpTime } from '@/services/api';
import { setAuthorityByToken, setUser } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';
import { getPageQuery } from '@/utils/utils';

const Model = {
  namespace: 'login',
  state: {
    status: undefined,
  },
  effects: {
    *login({ payload }, { call, put }) {
      const response = yield call(login, payload);
      if (response.access_token) {
        yield put({
          type: 'changeLoginStatus',
          payload: response,
        });
        const user = yield call(fetchCurUser);

        if (user && user.roles) {
          setUser(user);
        }

        if (user.permissions.includes('help_manual') && !user.help_time) {
          yield call(saveUserHelpTime);
          window.location.href = `${window.location.origin}/faq`;
        } else {
          // Login successfully
          const urlParams = new URL(window.location.href);
          const params = getPageQuery();
          let { redirect } = params;
          if (redirect) {
            const redirectUrlParams = new URL(redirect);
            if (redirectUrlParams.origin === urlParams.origin) {
              redirect = redirect.substr(urlParams.origin.length);

              if (redirect.match(/^\/.*#/)) {
                redirect = redirect.substr(redirect.indexOf('#') + 1);
              }
            } else {
              window.location.href = '/';
            }
          }
          reloadAuthorized();
          window.location.href = redirect || '/';
        }
      }
      return response;
    },
    *logout({ payload }, { call, put }) {
      if (payload) yield call(logout);
      yield put({
        type: 'changeLoginStatus',
        payload: {},
      });
      setUser({});
      reloadAuthorized();

      /*
      yield put(
        routerRedux.push({
          pathname: '/user/login',
          search:
            window.location.pathname === '/user/login'
              ? window.location.search
              : stringify({
                  redirect: window.location.href,
                }),
        }),
      );
       */

      // yield put(
      //   routerRedux.push({
      //     pathname: '/user/login',
      //   }),
      // );
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthorityByToken(payload);
      return { ...state, status: 'ok' };
    },
  },
};
export default Model;
