import { fetchTimeZoneHistory } from '@/services/api';

export default {
  nameSpace: 'storeChangeHistory',
  state: {
    TimeZoneChangeHistoryList: [],
    totalCount: 0,
  },
  effects: {
    *getTimeZoneChangeHistory({ payload }, { call, put }) {
      const res = yield call(fetchTimeZoneHistory, payload);
      yield put({
        type: 'saveTimeZoneHistory',
        payload: res,
      });
    },
  },
  reducers: {
    saveTimeZoneHistory(state, action) {
      return {
        ...state,
        TimeZoneChangeHistoryList: action.payload.data || [],
        totalCount: action.payload.total_count || 0,
      };
    },
  },
};
