import {
  fetchDomainList,
  fetchDomainAccountOption,
  fetchDnsList,
  fetchPeopleList,
} from '@/services/api';

export default {
  namespace: 'domainName',
  state: {
    data: [],
    total: 0,
    notExistDomain: [],
    accountOptions: [],
    dnsList: {
      data: [],
      total: 0,
    },
    peopleList: [],
  },

  effects: {
    *getDomainName({ payload, callback }, { call, put }) {
      const response = yield call(fetchDomainList, payload);
      if (callback) callback();
      yield put({
        type: 'saveDomainName',
        payload: response,
      });
    },
    *getDomainAccountOption(_, { call, put }) {
      const response = yield call(fetchDomainAccountOption);
      yield put({
        type: 'saveDomainAccountOption',
        payload: response,
      });
    },
    *getDnsList({ payload }, { call, put }) {
      const response = yield call(fetchDnsList, payload);
      yield put({
        type: 'saveDnsList',
        payload: response,
      });
    },
    *getPeopleList({ payload }, { call, put }) {
      const response = yield call(fetchPeopleList, payload);
      yield put({
        type: 'savePeopleList',
        payload: response,
      });
      return response;
    },
  },
  reducers: {
    saveDomainName(state, action) {
      const { data = [], meta, not_exist: notExistDomain = [] } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
        notExistDomain,
      };
    },
    saveDomainAccountOption(state, action) {
      return {
        ...state,
        accountOptions: action.payload || [],
      };
    },
    saveDnsList(state, action) {
      const { DomainRecords, TotalCount } = action.payload;
      return {
        ...state,
        dnsList: {
          data: DomainRecords?.Record || [],
          total: TotalCount || 0,
        },
      };
    },
    savePeopleList(state, action) {
      return {
        ...state,
        peopleList: action.payload || [],
      };
    },
  },
};
