import { fetchPPAlert } from '@/services/api';

export default {
  namespace: 'ppalert',

  state: {
    data: [],
  },

  effects: {
    *getPPAlert({ payload }, { call, put }) {
      const response = yield call(fetchPPAlert, payload);
      yield put({
        type: 'savePPAlert',
        payload: response,
      });
    },
  },
  reducers: {
    savePPAlert(state, action) {
      const { data = [] } = action.payload || {};
      return {
        ...state,
        data,
      };
    },
  },
};
