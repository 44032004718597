import {
  fetchLabels,
  fetchLogs,
  fetchProducts,
  fetchProductsWithID,
  fetchQueryLogs,
  fetchStatistics,
  fetchAllProducts,
  fetchMigrateLogs,
  fetchMigrateLogDetailData,
  fetchUpdateLog,
  fetchProductComment,
} from '@/services/api';
import { fetchProductsUserList } from '@/services/products';

export default {
  namespace: 'product',

  state: {
    labels: [],
    product: {
      data: [],
      meta: {},
    },
    logs: {
      data: [],
      meta: {},
    },
    importLogs: {
      data: [],
      meta: {},
    },
    editProduct: {},
    statistics: {
      data: [],
      total: {},
    },
    allProducts: {
      data: [],
      meta: {},
    },
    migrateLogs: {
      data: [],
      meta: {},
    },
    migrateDetails: {
      data: [],
      meta: {},
    },
    updateLog: {
      data: [],
      meta: {},
    },
    commentData: {},
    storagePeople: [],
  },

  effects: {
    *getProducts({ payload, callback }, { call, put }) {
      const response = yield call(fetchProducts, payload);
      yield put({
        type: 'saveProducts',
        payload: response,
      });
      if (callback) callback();
    },
    *getLogs({ payload, callback }, { call, put }) {
      const response = yield call(fetchLogs, payload);
      yield put({
        type: 'saveLogs',
        payload: response,
      });
      if (callback) callback();
    },
    *getImportLogs({ payload, callback }, { call, put }) {
      const response = yield call(fetchQueryLogs, payload);
      yield put({
        type: 'saveImportLogs',
        payload: response,
      });
      if (callback) callback();
    },
    *getLabels(_, { call, put }) {
      const response = yield call(fetchLabels);
      yield put({
        type: 'saveLabels',
        payload: response,
      });
    },
    *getProductSpec({ payload, callback }, { call, put }) {
      const response = yield call(fetchProductsWithID, payload);
      yield put({
        type: 'saveProductSpec',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getStatistics({ payload, callback }, { call, put }) {
      const response = yield call(fetchStatistics, payload);
      yield put({
        type: 'saveStatistics',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getAllProducts({ payload, callback }, { call, put }) {
      const response = yield call(fetchAllProducts, payload);
      yield put({
        type: 'saveAllProducts',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getMigrateLogs({ payload, callback }, { call, put }) {
      const response = yield call(fetchMigrateLogs, payload);
      yield put({
        type: 'saveMigrateLogs',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getMigrateDetails({ payload, callback }, { call, put }) {
      const response = yield call(fetchMigrateLogDetailData, payload);
      yield put({
        type: 'saveMigrateDetails',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getUpdateLog({ payload, callback }, { call, put }) {
      const response = yield call(fetchUpdateLog, payload);
      yield put({
        type: 'saveUpdateLog',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getComments({ payload, callback }, { call, put }) {
      const response = yield call(fetchProductComment, payload);
      yield put({
        type: 'saveComments',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getStoragePeople({ payload, callback }, { call, put }) {
      const response = yield call(fetchProductsUserList, payload);
      yield put({
        type: 'saveStoragePeople',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveProducts(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        product: {
          data,
          meta,
        },
      };
    },
    saveLogs(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        logs: {
          data,
          meta,
        },
      };
    },
    saveImportLogs(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        importLogs: {
          data,
          meta,
        },
      };
    },
    saveLabels(state, action) {
      return {
        ...state,
        labels: action.payload || [],
      };
    },
    saveProductSpec(state, action) {
      const { data } = action.payload || {};
      return {
        ...state,
        editProduct: data || {},
      };
    },
    saveStatistics(state, action) {
      return {
        ...state,
        statistics: action.payload || {},
      };
    },
    saveAllProducts(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        allProducts: {
          data,
          meta,
        },
      };
    },
    saveMigrateLogs(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        migrateLogs: {
          data,
          meta,
        },
      };
    },
    saveMigrateDetails(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        migrateDetails: {
          data,
          meta,
        },
      };
    },
    saveUpdateLog(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        updateLog: {
          data,
          meta,
        },
      };
    },
    updateEditProduct(state, action) {
      const { editProduct } = state;
      const tempData = [...editProduct.variants];
      const { name, value, index, all = false, selectedRowKeys } = action.payload;
      let result = [];
      if (all) {
        result = tempData.map(item => {
          if (selectedRowKeys.includes(item.id)) {
            return { ...item, [name]: value };
          }
          return item;
        });
      } else {
        result = tempData.map((item, i) => {
          if (i === index) {
            return { ...item, [name]: value };
          }
          return item;
        });
      }

      const finallyResult = { ...editProduct, variants: result };
      return {
        ...state,
        editProduct: finallyResult || {},
      };
    },
    saveComments(state, action) {
      return {
        ...state,
        commentData: action?.payload?.data || {},
      };
    },
    saveStoragePeople(state, action) {
      return {
        ...state,
        storagePeople:
          action?.payload?.data?.map(item => ({
            label: item.name,
            value: item.id,
          })) || [],
      };
    },
  },
};
