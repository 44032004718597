import { fetchSkuShipping } from '@/services/sku';
import { generateUuid } from '@/utils/utils';

export default {
  nameSpace: 'skuShippingStatus',
  state: {
    data: [],
    total: 0,
    summary: {},
  },
  effects: {
    *getSkuShippingStatus({ payload }, { call, put }) {
      const response = yield call(fetchSkuShipping, payload);
      yield put({
        type: 'saveSkuShippingStatus',
        payload: response,
      });
    },
  },
  reducers: {
    saveSkuShippingStatus(state, action) {
      const { data = [], meta = {}, total } = action?.payload;
      return {
        ...state,
        data: data?.map(i => ({ ...i, rowkey: generateUuid() })),
        total: meta?.total || 0,
        summary: total,
      };
    },
  },
};
