import { fetchCustomizedProducts, fetchCustomizedAlarms } from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'customizedManage',
  state: {
    data: [],
    total: 0,
    alarmData: {},
  },
  effects: {
    *getCustomizedProduct({ payload }, { call, put }) {
      const response = yield call(fetchCustomizedProducts, payload);
      yield put({
        type: 'saveCustomizedProduct',
        payload: response,
      });
    },
    *getCustomizedAlarms({ payload, callback }, { call, put }) {
      const response = yield call(fetchCustomizedAlarms, payload);
      yield put({
        type: 'saveCustomizedAlarms',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveCustomizedProduct(state, action) {
      const { data = [], meta = {} } = action.payload;
      for (let i = 0; i < data?.length; i += 1) {
        data[i].id = generateUuid();
      }
      return {
        ...state,
        data,
        total: meta.total || 0,
      };
    },
    saveCustomizedAlarms(state, action) {
      const { data = {} } = action.payload;
      return {
        ...state,
        alarmData: data,
      };
    },
  },
};
