import { fetchGoogleAuthorizeEmail } from '@/services/api';

export default {
  namespace: 'googleAuthorizeEmail',

  state: {
    data: [],
    total: 0,
  },

  effects: {
    *getGoogleAuthorizeEmail({ payload }, { call, put }) {
      const response = yield call(fetchGoogleAuthorizeEmail, payload);
      yield put({
        type: 'saveGoogleAuthorizeEmail',
        payload: response,
      });
    },
  },
  reducers: {
    saveGoogleAuthorizeEmail(state, action) {
      const { data, meta } = action.payload;
      return {
        ...state,
        data: data || [],
        total: meta?.total || 0,
      };
    },
  },
};
