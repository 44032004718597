import { fetchProductAfterSaleNew, fetchProductAfterSaleDisputeNew } from '@/services/api';

export default {
  namespace: 'productAfterSalesReviewNew',
  state: {
    data: [],
    total: 0,
    landingPageOrPost: [],
  },
  effects: {
    *getProductAfterSale({ payload }, { call, put }) {
      const FETCHURL =
        payload['review_module'] === 'url'
          ? fetchProductAfterSaleNew
          : fetchProductAfterSaleDisputeNew;
      delete payload['review_module'];
      const response = yield call(FETCHURL, payload);
      yield put({
        type: 'saveProductAfterSale',
        payload: response,
      });
    },
  },
  reducers: {
    saveProductAfterSale(state, action) {
      const { data = [], meta } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
        pageSize: meta.per_page || 0,
        page: meta.current_page || 0,
      };
    },
    saveLandingPageOrPost(state, action) {
      const { data = [] } = action.payload;
      return {
        ...state,
        landingPageOrPost: data || [],
      };
    },
  },
};
