import { fetchClipTasks, fetchParamsOvEditor, fetchMeClipTasks } from '@/services/api';

export default {
  namespace: 'materialDispatch',

  state: {
    dispatch: {
      data: [],
      total: 0,
    },
    orders: {
      doing_tasks: [],
      tasks: [],
      total: 0,
      maxCardTotal: 3,
    },
    editor: [],
    maxCutTotal: 0,
  },

  effects: {
    *fetchDispatch({ payload }, { call, put }) {
      const response = yield call(fetchClipTasks, payload);
      yield put({
        type: 'saveDispatch',
        payload: response,
      });
    },
    *fetchOrders({ payload }, { call, put }) {
      const response = yield call(fetchMeClipTasks, payload);
      yield put({
        type: 'saveOrders',
        payload: response,
      });
    },
    *fetchParamsEditor({ payload }, { call, put }) {
      const response = yield call(fetchParamsOvEditor, payload);
      yield put({
        type: 'saveParamsOvEditor',
        payload: response,
      });
    },
  },
  reducers: {
    saveDispatch(state, { payload }) {
      return {
        ...state,
        dispatch: payload,
      };
    },
    saveOrders(state, { payload }) {
      return {
        ...state,
        orders: {
          doing_tasks: payload.doing_tasks?.data || [],
          tasks: payload.tasks?.data || [],
          total: payload.tasks?.total || 0,
          // eslint-disable-next-line camelcase
          maxCardTotal: payload.doing_tasks?.max_doing_clip_tasks_count || 3,
        },
      };
    },
    saveParamsOvEditor(state, { payload }) {
      return {
        ...state,
        editor: payload.data || [],
        maxCutTotal: payload.max_doing_clip_tasks_count || 0,
      };
    },
  },
};
