export function dealWithFilter(params) {
  let query = '?';
  Object.keys(params).forEach(key => {
    if (params[key] !== '' && params[key] !== undefined) query += `${key}=${params[key]}&`;
  });
  if (query !== '?') {
    return query.substr(0, query.lastIndexOf('&'));
  }
  return '';
}

export function dealWithUrl(url, params) {
  let requestUrl = url;
  if (params && Object.keys(params).length) {
    requestUrl += dealWithFilter(params);
  }
  return requestUrl;
}
