const inSideReviewDataService = {
  namespace: 'inSideReviewDataService',
  state: {
    leftData: [],
    currentTag: 0,
    rightData: [],
    leftDataCheckedKeys: []
  },

  // 与当前勾选的url相同的项
  getRightDataItemByUrl(urls, rightData) {
    return rightData.find(i => {
      const isExitCommonUrl = i.assets.some(j => {
        if (j.post_url) {
          return urls.includes(j.post_url)
        }
        return urls.includes(j.landing_url)
      })
      return isExitCommonUrl
    })
  },
  // 右边url与当前取消勾选存在相同tag,并删除标签
  getRightDataItemsByTag(tag, rightData, checkTagType) {
    return rightData.map(i => {
      if (i[checkTagType] === tag) {
        delete i[checkTagType]
      }
      return i
    })
  },

  //删除右边url中与当前取消的tag相同的项
  uncheckRightDataByTag(tag, rightData, checkTagType) {
    const rightDataItems = inSideReviewDataService.getRightDataItemsByTag(tag, rightData, checkTagType)
    return rightDataItems
  },
  //删除左边url中与当前取消的tag相同的项
  uncheckLeftDataByTag(tag, leftData) {
    return leftData.map(i => {
      if (i?.tag === tag) {
        delete i.tag
        delete i.check
      }
      return i
    })
  },
  //左边url中与右边当前选中的url存在交集,加上当前标签
  getLeftDataItemByUrl(tag, urls, leftData) {
    return leftData.map(i => {
      if (i.post_url && urls.includes(i.post_url)) {
        i.tag = i?.tag || tag
        i.check = true
      } else if (!i.post_url && urls.includes(i.landing_url)) {
        i.tag = i?.tag || tag
        i.check = true
      }
      return i
    })
  },
  getLeftDataCheckedKeys(leftData) {
    const checkedKeys = leftData.reduce((prev, current, index) => {
      if (current?.check) {
        prev.push(index)
      }
      return prev
    }, [])
    return checkedKeys
  },

  getRightDataItemByUrlForRightData(urls, rightData, checkTagType) {
    const currentRightDataHasLabel = rightData.filter(i => i?.[checkTagType]) || []
    return currentRightDataHasLabel.find(i => {
      const isExitCommonUrl = (i.assets || []).some(j => {
        if (j.post_url) {
          return urls?.includes?.(j.post_url)
        }
        return urls?.includes?.(j.landing_url)
      })
      return isExitCommonUrl
    })
  },
  getRightDataItemByUrlForLeftData(rightData, item) {
    const currentRightDataHasLabel = rightData.filter(i => i?.postTag || i?.landingTag) || []
    return currentRightDataHasLabel.find(j => {
      const isExitCommonUrl = (j.assets || []).some(k => {
        if (k.post_url) {
          return k.post_url === item.post_url
        }
        return k.landing_url === item.landing_url
      })
      return isExitCommonUrl
    })
  },


  getLeftDataByUrl(leftData, rightData, leftDataCheckedKeys) {
    return leftData.map((i, index) => {
      if (leftDataCheckedKeys.includes(index)) {
        i.check = true
        const getRightDataItemByUrl = inSideReviewDataService.getRightDataItemByUrlForLeftData(rightData, i)
        if (i.post_url) {
          i.tag = i?.tag || getRightDataItemByUrl?.postTag
        } else {
          i.tag = i?.tag || getRightDataItemByUrl?.landingTag
        }

      } else {
        i.check = false
        i.tag = null
      }
      return i
    })
  },



  effects: {
    * checkRightDataByIndex({ payload, callback }, { call, put, select }) {
      let { checkItem, checkTagType } = payload
      const { postUrls, landingUrls, index } = checkItem
      const { inSideReviewDataService: dataService } = yield select();
      let { rightData, currentTag, leftData, leftDataCheckedKeys } = dataService
      const urls = postUrls?.length ? postUrls : landingUrls
      const rightDataItemData = inSideReviewDataService.getRightDataItemByUrlForRightData(urls, rightData, checkTagType)
      let tag;
      if (rightDataItemData?.[checkTagType]) {
        tag = rightDataItemData[checkTagType]
      } else {
        tag = currentTag + 1 // 同时根据顺序两边标签框标记相同数字以示对应关系

        yield put({
          type: 'generateTag',
        })
      }
      rightData[index][checkTagType] = tag

      yield put({
        type: 'saveRightData',
        payload: rightData
      })

      // 左侧审核内容中的对应着陆页也同步勾选
      leftData = inSideReviewDataService.getLeftDataItemByUrl(tag, urls, leftData)
      leftDataCheckedKeys = inSideReviewDataService.getLeftDataCheckedKeys(leftData, leftDataCheckedKeys)
      callback(leftData)
      yield put({
        type: 'saveLeftData',
        payload: leftData
      })
      yield put({
        type: 'saveLeftDataCheckedKeys',
        payload: leftDataCheckedKeys
      })
    },
    * uncheckRightDataByIndex({ payload, callback }, { call, put, select }) {
      let { checkItem, checkTagType, } = payload
      const { inSideReviewDataService: dataService } = yield select();
      let { rightData, leftData } = dataService
      let { index, leftDataCheckedKeys } = checkItem
      const tag = rightData[index][checkTagType]
      // 两边同步取消勾选同时自动移除标签数字
      rightData = inSideReviewDataService.uncheckRightDataByTag(tag, rightData, checkTagType)
      leftData = inSideReviewDataService.uncheckLeftDataByTag(tag, leftData)
      leftDataCheckedKeys = inSideReviewDataService.getLeftDataCheckedKeys(leftData, leftDataCheckedKeys)
      callback(leftData, leftDataCheckedKeys)
      yield put({
        type: 'saveRightData',
        payload: rightData
      })
      yield put({
        type: 'saveLeftData',
        payload: leftData
      })
      yield put({
        type: 'saveLeftDataCheckedKeys',
        payload: leftDataCheckedKeys
      })
    },
    *checkLeftDataByIndex({ payload, callback }, { put, select }) {
      const { inSideReviewDataService: dataService } = yield select();
      let { leftDataCheckedKeys } = payload
      let { rightData, leftData } = dataService
      const latestLeftData = inSideReviewDataService.getLeftDataByUrl(leftData, rightData, leftDataCheckedKeys)
      leftDataCheckedKeys = inSideReviewDataService.getLeftDataCheckedKeys(latestLeftData, leftDataCheckedKeys)
      callback(latestLeftData)
      yield put({
        type: 'saveLeftData',
        payload: latestLeftData
      })
      yield put({
        type: 'saveLeftDataCheckedKeys',
        payload: leftDataCheckedKeys
      })
    },
    *unCheckLeftDataByIndex({ callback }, { put, select }) {
      const { inSideReviewDataService: dataService } = yield select();
      let { leftData } = dataService
      leftData = leftData.map(i => {
        delete i?.check
        delete i?.tag
        return i
      })
      callback(leftData)
      yield put({
        type: 'saveLeftData',
        payload: leftData
      })
      yield put({
        type: 'saveLeftDataCheckedKeys',
        payload: []
      })
    }

  },


  reducers: {
    generateTag(state) {
      return {
        ...state,
        currentTag: state.currentTag + 1
      }
    },
    saveLeftData(state, action) {
      return {
        ...state,
        leftData: action.payload
      }
    },
    saveRightData(state, action) {
      return {
        ...state,
        rightData: action.payload
      }
    },
    saveLeftDataCheckedKeys(state, action) {
      return {
        ...state,
        leftDataCheckedKeys: action.payload
      }
    },
    resetInSideReviewDataServiceState(state, action) {
      return {
        ...state,
        currentTag: 0,
        leftDataCheckedKeys: [],
        ...action.payload,
      }
    }
  },
};

export default inSideReviewDataService
