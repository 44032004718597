import { fetchPPList, fetchSubAccount } from '@/services/api';

export default {
  namespace: 'ppList',
  state: {
    data: [],
    meta: {
      total: 0,
    },
    subAccount: {
      data: [],
      meta: {},
    },
    attachRecord: {},
    attachShowUser: [],
  },

  effects: {
    *getPPList({ payload }, { call, put }) {
      const response = yield call(fetchPPList, payload);
      yield put({
        type: 'savePPlist',
        payload: response,
      });
    },
    *getSubAccount({ payload }, { call, put }) {
      const response = yield call(fetchSubAccount, payload);
      yield put({
        type: 'saveSubAccount',
        payload: response,
      });
    },
    *getAttachRecord({ payload }, { call, put }) {
      const response = yield call(fetchSubAccount, payload);
      yield put({
        type: 'saveAttachRecord',
        payload: { attachRecord: response?.data?.[0] || {} },
      });
    },
  },
  reducers: {
    savePPlist(state, action) {
      const {
        data = [],
        meta: { total = 0 },
      } = action.payload;
      return {
        ...state,
        data,
        meta: {
          total,
        },
      };
    },
    saveSubAccount(state, action) {
      const {
        data = [],
        meta: { total = 0, current_page: currentPage = 0 },
      } = action.payload;
      return {
        ...state,
        subAccount: { data, meta: { total, currentPage } },
      };
    },
    saveAttachRecord(state, action) {
      const { attachRecord = {} } = action.payload;
      return {
        ...state,
        attachRecord,
        attachShowUser: attachRecord?.users || [],
      };
    },
    saveAttachShowUser(state, action) {
      const { attachShowUser = [] } = action.payload;
      return {
        ...state,
        attachShowUser,
      };
    },
  },
};
