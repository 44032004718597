import { fetchAdspendCpm } from '@/services/bigData';

export default {
  namespace: 'cpmAnalysis',

  state: {
    detail: {
      data: [],
      accountIds: [],
    },
    avg: {
      data: [],
      accountIds: [],
    },
  },

  effects: {
    *getAdspendCpm({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdspendCpm, payload);
      yield put({
        type: 'saveAdspendCpm',
        payload: { ...response, is_account_first: payload?.is_account_first },
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveAdspendCpm(state, action) {
      const { data = [], accountIds = [], type, is_account_first } = action.payload || {};
      if (type === 'all') {
        return {
          detail: {
            data: [],
            accountIds: [],
          },
          avg: {
            data: [],
            accountIds: [],
          },
        };
      }
      return {
        detail: {
          data: data,
        },
        avg: {
          data: data.map(i => {
            return {
              atc: i.avg_atc,
              cpc: i.avg_cpc,
              cpm: i.avg_cpm,
              ctr: i.avg_ctr,
              roas: i.avg_roas,
              date: i.date,
              hour: i.hour,
            };
          }),
        },
      };
    },
  },
};
