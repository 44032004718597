import React from 'react';
import { Redirect } from 'umi';
import moment from 'moment';
import { notification } from 'antd';
import { stringify } from 'querystring';
import { setAuthorityByToken, setUser } from '@/utils/authority';
import { fetchCurUser, parseToken } from '@/services/api';
import PromiseRender from './PromiseRender';
import { CURRENT } from './renderAuthorize';
/**
 * 通用权限检查方法
 * Common check permissions method
 * @param { 权限判定 | Permission judgment } authority
 * @param { 你的权限 | Your permission description } currentAuthority
 * @param { 通过的组件 | Passing components } target
 * @param { 未通过的组件 | no pass components } Exception
 */
const checkPermissions = (authority, currentAuthority, target, Exception) => {
  const { props = {} } = target || {};
  const { pathname, query } = props.location || {};
  const queryString = stringify({
    redirect: window.location.href,
  });
  if (query && query.access_token) {
    parseToken(query.access_token)
      .then(res => {
        if (res && res.token) {
          const [, temp] = res.token.split('Bearer ');
          const token = { access_token: temp, token_type: 'Bearer' };
          setAuthorityByToken(token, 'hmac');
          return fetchCurUser();
        }
        return null;
      })
      .then(user => {
        if (user && user.id) {
          setUser(user);
          window.location.href = pathname;
        }
      });
  }

  const expires = localStorage.getItem('api-token-expires');
  if (moment(expires).isBefore(moment(new Date()))) {
    notification.error({
      message: '权限失效，请重新登录',
    });
    setUser({});
    setAuthorityByToken();
    return <Redirect to={`/user/login?${queryString}`} />;
  }

  // 没有判定权限.默认查看所有
  // Retirement authority, return target;
  if (!authority) {
    return target;
  } // 数组处理
  if (Array.isArray(authority)) {
    if (Array.isArray(currentAuthority)) {
      if (currentAuthority.some(item => authority.includes(item))) {
        return target;
      }
    } else if (authority.includes(currentAuthority)) {
      return target;
    }

    return Exception;
  } // string 处理

  if (typeof authority === 'string') {
    if (Array.isArray(currentAuthority)) {
      if (currentAuthority.some(item => authority === item)) {
        return target;
      }
    } else if (authority === currentAuthority) {
      return target;
    }

    return Exception;
  } // Promise 处理

  if (authority instanceof Promise) {
    return <PromiseRender ok={target} error={Exception} promise={authority} />;
  } // Function 处理

  if (typeof authority === 'function') {
    // eslint-disable-next-line no-useless-catch
    try {
      const bool = authority(currentAuthority); // 函数执行后返回值是 Promise

      if (bool instanceof Promise) {
        return <PromiseRender ok={target} error={Exception} promise={bool} />;
      }

      if (bool) {
        return target;
      }

      return Exception;
    } catch (error) {
      throw error;
    }
  }

  throw new Error('unsupported parameters');
};

export { checkPermissions };

function check(authority, target, Exception) {
  return checkPermissions(authority, CURRENT, target, Exception);
}

export default check;
