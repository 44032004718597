import { fetchHermesPR, fetchHermesUser } from '@/services/api';
import { fetchUserList } from '@/services/user';

export default {
  namespace: 'hermesUser',

  state: {
    userList: {
      data: [],
      meta: {},
    },
    hermesPR: {
      permissions: {},
      roles: {},
    },
    hermesUserList: [],
  },

  effects: {
    *getHermesUser({ payload, callback }, { call, put }) {
      const response = yield call(fetchHermesUser, payload);
      yield put({
        type: 'saveHermesUser',
        payload: response,
      });
      if (callback) callback();
    },
    *getHermesPR(_, { call, put }) {
      const response = yield call(fetchHermesPR);
      yield put({
        type: 'saveHermesPR',
        payload: response,
      });
    },
    *fetchHermesUserList({ payload }, { call, put }) {
      const response = yield call(fetchUserList, payload);
      yield put({
        type: 'saveHermesUserList',
        payload: response,
      });
    },
  },
  reducers: {
    saveHermesUser(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        userList: {
          data,
          meta,
        },
      };
    },
    saveHermesPR(state, action) {
      const { permissions = {}, roles = {} } = action.payload || {};
      return {
        ...state,
        hermesPR: {
          permissions,
          roles,
        },
      };
    },
    saveHermesUserList(state, action) {
      const { payload } = action;
      return {
        ...state,
        hermesUserList: payload?.data || [],
      };
    },
  },
};
