import { fetchPart, fetchPartType } from '@/services/api';

export default {
  namespace: 'part',

  state: {
    parts: [],
    partType: [],
  },

  effects: {
    *getParts({ payload, callback }, { call, put }) {
      const response = yield call(fetchPart, payload);
      yield put({
        type: 'saveParts',
        payload: response,
      });
      if (callback) callback();
    },

    *getPartType({ payload }, { call, put }) {
      const response = yield call(fetchPartType, payload);
      yield put({
        type: 'savePartType',
        payload: response,
      });
    },
  },
  reducers: {
    saveParts(state, action) {
      const data = action.payload;
      return {
        ...state,
        parts: data,
      };
    },
    savePartType(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        partType: data || [],
      };
    },
  },
};
