import {
  fetchQueryExportTask,
  fetchQueryList,
  fetchQueryLog,
  fetchVirtualRealTrack,
  trackingHubSearch,
} from '@/services/api';
import { generateUuid } from '@/utils/utils';

export default {
  namespace: 'track',

  state: {
    query: {
      data: [],
    },
    logistics: {
      count: 0,
      status: {},
    },
    exportTask: {
      currentPage: 1,
      total: 0,
      data: [],
    },
    track: {},
    trackingHub: {
      data: [],
      meta: {},
    },
  },

  effects: {
    *getQueryList({ payload, callback }, { call, put }) {
      // 物流状态查询由前端过滤
      const { status = [] } = payload;
      delete payload.status;
      const response = yield call(fetchQueryList, payload);
      yield put({
        type: 'saveQueryList',
        payload: {
          ...response,
          track_data: response?.track_data?.filter(i =>
            status.length ? status.includes(i.status) : true,
          ),
        },
      });
      if (callback) callback();
    },
    *getQueryLog({ payload, callback }, { call, put }) {
      const response = yield call(fetchQueryLog, payload);
      yield put({
        type: 'saveQueryLog',
        payload: response,
      });
      if (callback) callback();
    },
    *getExportTask({ payload, callback }, { call, put }) {
      const response = yield call(fetchQueryExportTask, payload);
      yield put({
        type: 'saveExportTask',
        payload: response,
      });
      if (callback) callback();
    },
    *getVirtualRealTrack({ payload, callback }, { call, put }) {
      const response = yield call(fetchVirtualRealTrack, payload);
      yield put({
        type: 'saveVirtualRealTrack',
        payload: response,
      });
      if (callback) callback();
    },
    *getTrackingHub({ payload, callback }, { call, put }) {
      const response = yield call(trackingHubSearch, payload);
      yield put({
        type: 'saveTrackingHub',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveQueryList(state, action) {
      const { track_data: data = [] } = action.payload || {};
      return {
        ...state,
        query: {
          data,
        },
      };
    },
    saveQueryLog(state, action) {
      const { count = 0, status = {} } = action.payload || {};
      return {
        ...state,
        logistics: {
          count,
          status,
        },
      };
    },
    saveExportTask(state, action) {
      const { current_page: currentPage, data = [], total = 0 } = action.payload || {};
      return {
        ...state,
        exportTask: {
          currentPage: currentPage || 1,
          data,
          total,
        },
      };
    },
    saveVirtualRealTrack(state, action) {
      const { data = [], search_num: searchNum = 0 } = action.payload || {};
      const temp = data.map(item => ({ ...item, uuid: generateUuid() }));
      return {
        ...state,
        track: {
          data: temp,
          searchNum,
        },
      };
    },
    clearVirtualRealTrack(state) {
      return {
        ...state,
        track: {},
      };
    },
    saveTrackingHub(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        trackingHub: {
          data,
          meta,
        },
      };
    },
  },
};
