import request from '@/utils/request';
import { dealWithUrl } from './utils';
import { stringify } from 'qs';
// sku销量列表
export async function fetchSkuSales(data) {
  return request('/api/sku_sales', {
    method: 'POST',
    data: data,
    removeBlankProperty: true,
  });
}

// SKU销量导出Excel
export async function exportSkuSales(data) {
  return request('/api/sku_sales/export', {
    method: 'POST',
    data: data,
    removeBlankProperty: true,
  });
}

// 获取产品审核列表
export async function fetchSkuReview(params) {
  const url = dealWithUrl('/api/sku/review', params);
  return request(url);
}

// 获取产品审核列表
export async function fetchSkuReviewL(data) {
  return request('/api/sku/review/search', { method: 'post', data });
}

// 创建产品审核
export async function addSkuReview(params) {
  return request('/api/sku/review', {
    method: 'POST',
    data: params,
  });
}

// 修改产品审核
export async function updateSkuReview(params) {
  return request('/api/sku/review', {
    method: 'PUT',
    data: params,
  });
}

// 删除产品审核
export async function deleteSkuReview(params) {
  return request('/api/sku/review', {
    method: 'DELETE',
    data: params,
  });
}

// 获取产品类型
export async function fetchProductType() {
  return request('/api/sku/product_type');
}

// 创建产品到历史库
export async function addSkuHistory(params) {
  return request('/api/sku/history', {
    method: 'POST',
    data: params,
  });
}

// 修改产品到历史库
export async function updateSkuHistory(params) {
  return request('/api/sku/history', {
    method: 'PUT',
    data: params,
  });
}

// 添加组合SKU
export async function addGroupSku(params) {
  return request('/api/sku/group_sku', {
    method: 'POST',
    data: params,
  });
}

// 生成新主SKU
export async function addTSku(params) {
  return request('/api/sku/generate_uuid', {
    method: 'POST',
    data: params,
  });
}

// 删除历史SKU
export async function deleteListSku(params) {
  return request('/api/sku/delete_history', {
    method: 'POST',
    data: params,
  });
}

// 运营修改产品审核
export async function updateSubmitSkuReview(params) {
  return request('/api/sku/modify_review', {
    method: 'POST',
    data: params,
  });
}

// 下载SKU管理产品审核excel模板
export async function downExcelSkuModel() {
  return request(`/api/sku/review/template`);
}

// 获取SKU组合SKU列表
export async function fetchComplexSkuList(params) {
  const url = dealWithUrl('/api/sku/group_sku', params);
  return request(url);
}

// 添加子SKU
export async function fetchAddChildSku(params) {
  return request('/api/sku/child_sku', {
    method: 'POST',
    data: params,
  });
}

// 提交组合sku
export async function uploadGroupSku(params) {
  return request('/api/sku/group_sku', {
    method: 'POST',
    data: params,
  });
}

// 搜索
export async function searchVsku(params) {
  const url = dealWithUrl('/api/sku/vs_sku', params);
  return request(url);
}

// sku列表导出excel(sku管理)
export async function exportSkuManageList(data) {
  return request('/api/sku/review/search', {
    method: 'post',
    data,
  });
}

// 产品汇总-上传
export async function fetchUploadProductStatistics(data) {
  return request('/api/sku/upload-statistics-dailies/search', {
    method: 'POST',
    data,
  });
}
// 产品汇总-出单
export async function fetchHasOrderProductStatistics(data) {
  return request('/api/sku/has-order-statistics-dailies/search', {
    method: 'POST',
    data,
  });
}
// 产品汇总-出单
export async function fetchReviewProductStatistics(data) {
  return request('/api/sku/review-statistics-dailies/search', {
    method: 'POST',
    data,
  });
}

// 导出产品汇总-上传
export async function exportFetchUploadProductStatistics(data) {
  return request('/api/sku/upload-statistics-dailies/search', {
    method: 'POST',
    data,
    headers: {
      export: 1,
    },
  });
}
// 导出产品汇总-出单
export async function exportFetchHasOrderProductStatistics(data) {
  return request('/api/sku/has-order-statistics-dailies/search', {
    method: 'POST',
    data,
    headers: {
      export: 1,
    },
  });
}
// 导出产品汇总-出单
export async function exportFetchReviewProductStatistics(data) {
  return request('/api/sku/review-statistics-dailies/search', {
    method: 'POST',
    data,
    headers: {
      export: 1,
    },
  });
}

// 产品汇总
export async function fetchTypeProductStatistics(params) {
  return request('/api/sku/category-statistics-dailies', {
    method: 'get',
    params: {
      ...params,
      group_by: params?.group_by ?? 'category_code',
    },
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 导出产品汇总
export async function exportTypeProductStatistics(params) {
  return request('/api/sku/category-statistics-dailies', {
    method: 'get',
    params: {
      ...params,
      group_by: params?.group_by ?? 'category_code',
    },
    headers: {
      export: 'xlsx',
    },
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 统计汇总-审核数汇总
export async function fetchReviewStatistic(params) {
  return request('/api/sku/check/statistics', {
    method: 'get',
    params,
  });
}

// 从wshop店铺获取商品列表
export async function fetchProductList(params) {
  return request('/api/sku/search_wshop_product', {
    method: 'get',
    params,
  });
}

// 获取wshop商品详情
export async function fetchProductDetail(params) {
  return request('/api/sku/wshop_product_detail', {
    method: 'get',
    params,
  });
}

// 获取商品详情
export async function fetchProductUrl(params) {
  return request('/api/sku_sales/ws_product_detail', {
    method: 'POST',
    data: params,
  });
}
// 产品审核获取操作日志
export async function fetchOperateLog(params) {
  return request('/api/sku_operate_log', {
    method: 'get',
    params,
  });
}

// SKU争议
export async function fetchSkuDispute(params) {
  return request('/api/sku/dispute', {
    method: 'GET',
    params,
  });
}

//SKU出单审核汇总

export async function fetchDailyReviewStatistics(params) {
  return request('/api/sku/daily-review-statistics', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export async function fetchDailyReviewStatisticsExpress(params) {
  return request('/api/sku/daily-review-statistics', {
    params,
    headers: {
      export: 'xlsx',
      // select: params.select,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

//SKU出单审核汇总需审核SKU详情
export async function fetchDailyReviewStatisticsDetail(params) {
  return request('/api/sku/daily-review-statistics/details', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

//SKU出单审核汇总需审核SKU详情-导出
export async function exportDailyReviewStatisticsDetail(params) {
  return request('/api/sku/daily-review-statistics/details', {
    method: 'GET',
    params,
    headers: {
      export: 'xlsx',
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 查询上架商品
export async function fetchSkuSearch(data) {
  return request('/api/sku/products/search', {
    method: 'POST',
    data,
  });
}
export async function fetchSkuOnSale(data) {
  return request('/api/sku/on_sale/list', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

//  获取SKU下架日志列表
export async function fetchUnShelveSku(params) {
  return request('/api/sku/products_un_shelve', {
    method: 'get',
    params,
  });
}

// 下架查询结果中的商品
export async function unShelveInSearch(params) {
  return request('/api/sku/products/un_shelve_in_search', {
    method: 'POST',
    data: params,
  });
}

// 产品下架-指定批量下架 (wshop店铺)
export async function unShelveSku(params) {
  return request('/api/sku/products/un_shelve', {
    method: 'POST',
    data: params,
  });
}

// 广告下架
export async function fetchAdOffShelfData(data) {
  return request('/api/sku/product_url', {
    method: 'POST',
    data,
  });
}
// 广告下架页面下架操作
export async function offShelf(params) {
  return request('/api/sku/ad_account', {
    method: 'PUT',
    data: params,
  });
}
// 广告下架日志列表
export async function fetchAdOffShelfLog(data) {
  return request('/api/sku/ad_account_log', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 库存产品
export async function fetchStockProduct(data) {
  return request('/api/sku/stock/index', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 修改库存产品共享部门
export async function updateStockPart(data) {
  return request('/api/sku/stock/part_update', {
    method: 'POST',
    data,
  });
}

// 修改库存产品共享人员
export async function updateStockPeople(data) {
  return request('/api/sku/stock/people_update', {
    method: 'POST',
    data,
  });
}

// SKU争议获取着陆页
export async function fetchSkuDisputeLandingPage(params) {
  return request('/api/sku/correspond/product_url', {
    method: 'GET',
    params,
  });
}
// 批量修改虚拟主SKU
export async function batchUpdateVSku(data) {
  return request('/api/sku/batch_failure', {
    method: 'POST',
    data,
  });
}

// 产品审核数据
export async function fetchProductCheckList(params) {
  return request('/api/sku/product_test/list', {
    method: 'GET',
    params,
  });
}

// 测试产品虚拟sku
export async function fetchTestSku(params) {
  return request('/api/sku/review/search_sku', {
    method: 'GET',
    params,
  });
}
// 创建测试产品
export async function createTestProduct(data) {
  return request('/api/sku/product_test/add', {
    method: 'POST',
    data,
    getResponse: true,
  });
}

// 更新测试产品
export async function updateTestProduct(data) {
  return request('/api/sku/product_test/update', {
    method: 'POST',
    data,
    getResponse: true,
  });
}

// 删除测试产品
export async function deleteTestProduct(id) {
  return request('/api/sku/product_test/del', {
    method: 'POST',
    data: {
      id,
    },
    getResponse: true,
  });
}
// 申请测试
export async function applyProductTest(id) {
  return request('/api/sku/product_test/addApplication', {
    method: 'POST',
    data: {
      products_test_id: id,
    },
    getResponse: true,
  });
}
// 銷量明細
export async function fetchSalesDetail(params) {
  return request('/api/sku/product_test/sku_statistics', {
    params,
  });
}

// 测试产品申请明细
export async function fetchTestSubmitDetail(params) {
  return request('/api/sku/product_test/applicant_sku_statistics', {
    params,
  });
}
// 编辑发帖信息
export async function createPostInfo(data) {
  return request('/api/sku/product_test/updateApplication', {
    method: 'POST',
    data,
    getResponse: true,
  });
}
// 产品材质
export async function fetchProductTexture(sku) {
  return request(`/api/sku/product_test/get_customize_product`, {
    method: 'GET',
    params: { v_sku: sku },
  });
}
// 测试池用户列表
export async function fetchTestUser() {
  return request('/api/sku/product_test/user_list');
}

// 落地页图片
export async function fetchApiImage(id) {
  return request('/api/sku/product_test/getLandingPreview', {
    method: 'GET',
    params: {
      id,
    },
    responseType: 'blob',
  });
}

// 获取上架商品SKU销量
export async function fetchOnOfferSales(data) {
  return request('/api/sku/products/on_offer_sku_sales', {
    method: 'POST',
    data,
  });
}

// SKU销量生成导出任务
export async function addExportJob(data) {
  return request('/api/sku_sales/async/export', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// SKU销量获取导出任务列表
export async function getSkuSaleJob(params) {
  return request('/api/sku_sales/async/list', {
    method: 'get',
    params,
  });
}
// SKU销量任务列表下载
export async function downloadSkusaleJob(params) {
  return request('/api/sku_sales/async/download', {
    method: 'get',
    params,
  });
}
// SKU争议下载列表
export async function downloadSkuDispute(params) {
  return request('/api/sku/dispute/export', {
    method: 'GET',
    params,
  });
}
// SKU争议异步导出
export async function asyncDownloadSkuDisputeJob(data) {
  return request('/api/sku/dispute/async_export', {
    method: 'POST',
    data,
  });
}
// SKU组合批量创建组合SKU模板
export async function complexSkuTemplate() {
  return request('/api/sku/group_sku/template', {
    method: 'get',
  });
}
// 批量添加组合SKU
export async function mutipleAddComplexSku(data) {
  return request('/api/sku/group_sku/import', {
    method: 'POST',
    data,
  });
}

// 获取库存SKu列表
export async function fetchSkuStockManage(params) {
  return request('/api/sku/stock', {
    method: 'get',
    params,
  });
}
// 创建库存Sku
export async function addSkuStock(data) {
  return request('/api/sku/stock', {
    method: 'POST',
    data,
  });
}

// 更新库存Sku
export async function updateSkuStock(data) {
  return request('/api/sku/stock', {
    method: 'PUT',
    data,
  });
}
//  删除库存Sku
export async function deleteSkuStock(data) {
  return request('/api/sku/stock', {
    method: 'DELETE',
    data,
  });
}
// 获取导入库存Sku Excel模板
export async function fetchStockTemplate() {
  return request('/api/sku/stock/import_template', {
    method: 'get',
  });
}

// 导入库存SKu
export async function importSkuStock(data) {
  return request('/api/sku/stock_import', {
    method: 'POST',
    data,
  });
}

// 侵权商品获取列表
export async function fetchInfringement(params) {
  return request('/api/sku/infringement', {
    method: 'get',
    params,
  });
}

export async function asyncSkuDisputeByStore(data) {
  return request('/api/sku/dispute/store_async_export', {
    method: 'POST',
    data,
  });
}
export async function exportSkuDisputeByStore(data) {
  return request('/api/sku/dispute/store_export', {
    method: 'POST',
    data,
  });
}

// 侵权商品更新产品
export async function updateInfringement(params) {
  return request('/api/sku/infringement', {
    method: 'PUT',
    data: params,
  });
}

// 合并sku属性
export async function mergeSkuAttrs(data) {
  return request(`/api/sku/attrs`, {
    method: 'PUT',
    data,
  });
}

export async function downloadskuSalesDaily(data) {
  return request('/api/sku_sales/daily/export', {
    method: 'POST',
    data,
  });
}

export async function fetchDaily(params) {
  return request('/api/sku_dailies', {
    method: 'GET',
    params,
  });
}

export async function createDaily(data) {
  return request('/api/sku_dailies', {
    method: 'POST',
    data,
  });
}
export async function updateDaily(data) {
  const { id } = data;
  return request(`/api/sku_dailies/${id}`, {
    method: 'PUT',
    data,
  });
}

export async function deleteDaily(data) {
  const { id } = data;
  return request(`/api/sku_dailies/${id}`, {
    method: 'DELETE',
  });
}

export async function fetchStockProductType(params) {
  return request('/api/sku/stock/product_type', {
    method: 'GET',
    params,
  });
}
export async function updateStockStatus(data) {
  return request(`/api/sku/stock/status_update`, {
    method: 'POST',
    data,
  });
}
export async function exportStockStatus(data) {
  return request(`/api/sku/stock/export`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

export async function fetchStockProductTemplate() {
  return request(`/api/sku/stock/part_import_template`, {
    method: 'GET',
  });
}
export async function importStockProduct(data) {
  return request('/api/sku/stock/part_import', {
    method: 'POST',
    data,
  });
}

export async function fetchSkuDisputeByStore(data) {
  return request('/api/sku/dispute/store', {
    method: 'POST',
    data,
  });
}

// sku争议
export async function fetchSkuDisputeData(params) {
  return request('/api/sku/dispute', {
    method: 'GET',
    params,
  });
}

// sku争议接回中控
export async function fetchSkuDisputeDataNew(data) {
  return request('/api/dispute-sku', {
    method: 'POST',
    data: {
      ...data,
      sort: data.sort || '-order_cnt',
    },
  });
}

// sku争议接回中控-导出
export async function exportSkuDisputeDataNew(data) {
  return request('/api/dispute-sku', {
    method: 'POST',
    data: {
      ...data,
      is_total: 0,
      sort: data.sort || '-order_cnt',
    },
    headers: {
      export: 'xlsx',
    },
  });
}

// sku争议新异步生成导出记录
export async function createSkuDisputeExcel(params) {
  return request('/api/sku/dispute/async-export', {
    method: 'GET',
    params,
  });
}

export async function batchScore(data) {
  return request('/api/sku_dailies/scoring', {
    method: 'POST',
    data,
  });
}
export async function fetchSkuShipping(data) {
  return request('/api/sku/shipping', {
    method: 'POST',
    data,
  });
}
export async function fetchSkuSpecialTotalByStore(data) {
  return request('/api/sku/dispute/store_special_total', {
    method: 'POST',
    data,
  });
}

export async function fetchSkuPurchaseCostComparison(data) {
  return request('/api/sku/purchase_cost_comparison', {
    method: 'POST',
    data,
  });
}

export async function fetchVsSkuCheckTime(params) {
  return request('/api/sku/vs_sku_check_time', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}

export async function fetchSkuOrderInfo(params) {
  return request('/api/sku/order_info', {
    method: 'GET',
    params,
  });
}

//统计汇总 采购成本对比 批量修改价格
export async function importPriceExcel(data) {
  return request('/api/sku/ts-sku-upload-supply-use-excel', {
    method: 'POST',
    data,
  });
}
//统计汇总 采购成本对比 批量修改价格导入进度
export async function fetchPriceImportProgess(id) {
  return request(`/api/sku/ts-sku-upload-supply-use-excel/${id}`, {
    method: 'GET',
  });
}
// 统计汇总 采购成本对比 单个修改1688价格
export async function update1688Price(data) {
  return request(`/api/sku/ts-sku-upload-supply`, {
    method: 'PUT',
    data,
  });
}
//统计汇总 采购成本对比 批量修改1688价格模板
export async function fetch1688PriceTemplate() {
  return request(`/api/sku/ts-sku-upload-supply-excel-template`, {
    method: 'POST',
    responseType: 'blob',
  });
}

// SKU采购供应链信息 获取
export function fetchProcurementSupplyChain(params) {
  return request(`/api/big-data/proxy/${params.path}`, {
    method: 'POST',
    data: params,
    removeBlankProperty: true,
  });
}

// SKU采购供应链信息 修改子SKU信息
export function updateSonSKUInfo(data) {
  return request('/api/sku/purchase/supply_chain', {
    method: 'POST',
    data,
  });
}

// SKU采购供应链信息 修改子SKU信息
export function updateBatchSonSKUInfo(data) {
  return request('/api/sku/purchase/supply_chain/batch', {
    method: 'POST',
    data,
  });
}

// SKU采购供应链信息 增加导出任务 真实
export function exportRealSku(data) {
  return request('/api/sku/purchase/supply_chain/export', {
    method: 'POST',
    data,
  });
}

// SKU采购供应链信息 增加导出任务 虚拟
export function exportVirtualSku(data) {
  return request('/api/vs_sku/purchase/supply_chain/export', {
    method: 'POST',
    data,
  });
}
// 产品审核SKU复制链接校验
export function checkSkuCopyLink(data) {
  return request('/api/sku/forks/capture', {
    method: 'POST',
    data,
    getResponse: true,
  });
}

// 产品审核SKU复制
export function productReviewSkuCopy(data) {
  return request('/api/sku/forks', {
    method: 'POST',
    data,
  });
}

// SKU采购供应链信息 导入前一日发货数量  excel模板
export function exportExpressCntExcel() {
  return request('/api/sku/purchase/supply_chain/excel');
}

// SKU采购供应链信息 导入前一日发货数量   sku缺货-导入
export function exportExpressCnt(data) {
  return request('/api/sku/purchase/supply_chain/import', {
    method: 'POST',
    data,
  });
}

// 产品审核-更新违规情况
export function updateViolation(data) {
  return request('/api/sku/violation/review', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
// 产品导入日志
export function fetchProductImportLog(params) {
  return request('/api/sku/batch/import/log', {
    method: 'GET',
    params,
  });
}
export function batchImportProduct(data) {
  return request('/api/sku/batch/review', {
    method: 'POST',
    data,
  });
}
export function fetchProductImportLogDetail(params) {
  return request('/api/sku/batch/import/details', {
    method: 'GET',
    params,
  });
}
export function fetchProductImportTemplate(params) {
  return request('/api/sku/batch/review/template', {
    method: 'GET',
    params,
  });
}
export function exportProductImportLog(id) {
  return request(`/api/sku/batch/review/${id}/export`, {
    method: 'GET',
  });
}

// sku缺货-获取列表
export function getOutOfStock(params) {
  return request('/api/big-data/proxy/getTsSkuSupplyChanShortageInfo', {
    params,
    method: 'POST',
  });
}

// sku缺货-获取汇总信息
export function getOutOfStockTotal(params) {
  return request('/api/big-data/proxy/getTsSkuShortageTotal', {
    params,
    method: 'POST',
  });
}

// sku缺货-excel模板
export function exportExpressOutOfStockExcel() {
  return request('/api/sku/purchase/supply_chain/shortage');
}

// sku缺货-新增导出任务
export function addExporOutOfStock(data) {
  return request('/api/sku/purchase/supply_chain/shortage/export', {
    method: 'POST',
    data,
  });
}

// 7天未下采购单SKU统计列表
export function fetchUnShelvePurchase(data) {
  return request('/api/sku/not_placed_purchase/index', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 添加处理结果操作记录
export function updateDealResult(data) {
  return request('/api/sku/not_placed_purchase/add', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 获取操作日志详情
export function getUnShelvePurchase(data) {
  return request('/api/sku/not_placed_purchase/detail', {
    method: 'POST',
    data,
  });
}

// 7天未下采购单SKU统计异步导出
export async function createUnShelvePurchaseExcel(params) {
  return request('/api/sku/not_placed_purchase/async_excel', {
    method: 'POST',
    params,
    removeBlankProperty: true,
  });
}

// 导入7天未下采购单处理结果操作记录
export async function importUnShelvePurchase(data) {
  return request('/api/sku/not_placed_purchase/import_detail', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 获取7天未下采购单操作记录导入模版
export async function exportUnShelvePurchaseTemplateExcel() {
  return request('/api/sku/not_placed_purchase/import_template', {
    removeBlankProperty: true,
  });
}
// sku热销榜 => 列表数据 或 导出
export function getSkuHotList(params) {
  return request('/api/sku/hot_sales', {
    params,
    removeBlankProperty: true,
    headers: params?.isExport ? { export: 'xsl' } : {},
    responseType: params?.isExport ? 'blob' : 'json',
  });
}

// sku热销榜 => 导入文件模板
export function importSkuHotTemplate() {
  return request('/api/sku/hot_sale/excel_template');
}

// sku热销榜 => 导入文件
export function importSkuHot(data) {
  return request('/api/sku/hot_sales', {
    method: 'POST',
    data,
  });
}

// sku热销榜=> 审核记录
export function getSkuHotAuditLog(id) {
  return request(`/api/sku/hot_sales/${id}/reviews`);
}

// sku热销榜=> 审核
export function auditSkuHot(data) {
  return request(`/api/sku/hot_sales/${data.id}/reviews`, {
    method: 'POST',
    data,
  });
}

// sku热销榜=> 导入产品
export function exportProducts(hot_sales_id) {
  return request(`/api/sku/hot_sales/${hot_sales_id}/product_imports`, {
    method: 'POST',
  });
}

// sku热销榜=> 编辑单个SKU
export function updateSKUHot(data) {
  return request(`/api/sku/hot_sales/${data.id}`, {
    method: 'PUT',
    data,
  });
}

// sku热销榜=> 批量编辑SKU
export function batchUpdateSKUHot(data) {
  return request(`/api/sku/hot_sale/batch`, {
    method: 'POST',
    data,
  });
}

// sku热销榜=> 监测日志
export function skuHotMonitoringLog(params) {
  return request('/api/sku/hot_sales/product_imports', {
    params,
  });
}

// sku热销榜=> 监测日志 => 账号
export function skuHotMonitoringUser(params) {
  return request('/api/sku/hot_sale/users', {
    params,
  });
}

// sku热销榜=> 导入产品
export async function skuHotProductByUrl(data) {
  return request('/api/products/crawl-logs', {
    method: 'POST',
    data,
  });
}

export function fetchSonSkuQuantity(data) {
  return request('/api/sku/on_sale/son_sku_quantity', {
    method: 'POST',
    data,
  });
}

// 采购成本对比异步导出
export async function createPurchaseCostComparisonExcel(data) {
  return request('/api/sku/purchase_cost_comparison/async_export', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 虚拟主/子sku请求真实主/子sku
export async function fetchTSku(params) {
  return request('/api/sku/vskus_search_tskus', {
    method: 'GET',
    params,
  });
}
// sku 供应商列表 => 列表数据 (导出)
export function fetchSkuVendorList(params) {
  return request('/api/sku/suppliers', {
    params,
    removeBlankProperty: true,
    headers: params?.isExport ? { export: 'xsl' } : {},
    responseType: params?.isExport ? 'blob' : 'json',
  });
}

// sku 供应商列表 => 单个数据
export function fetchSkuVendor(id) {
  return request(`/api/sku/suppliers/${id}`);
}

// sku 供应商列表 => 修改数据
export function updateSkuVendor(data) {
  return request(`/api/sku/suppliers/${data.id}`, {
    data,
    method: 'PUT',
  });
}

// sku 供应商列表 => 新建数据
export function addSkuVendor(data) {
  return request(`/api/sku/suppliers`, {
    data,
    method: 'POST',
  });
}

// sku 供应商列表 => 批量删除
export function batchDeleteSkuVendor(data) {
  return request(`/api/sku/suppliers`, {
    data,
    method: 'DELETE',
  });
}

// sku 供应商列表 => 批量修改合作
export function batchUpdateCooperate(data) {
  return request(`/api/sku/suppliers`, {
    data,
    method: 'PUT',
  });
}

// sku 供应商列表 => 导入模板
export function importSkuVendorTemplate() {
  return request('/api/sku/supplier/excel_template');
}

// sku 供应商列表 => 导入
export function importSkuVendor(data) {
  return request('/api/sku/supplier/import', {
    data,
    method: 'POST',
  });
}
export function fetchCountries() {
  return request('/api/sku/new-shop-countries');
}
export function addCountryPostalConfigs(data) {
  return request('/api/sku/new-shop-country-postal-configs', {
    data,
    method: 'POST',
  });
}
export function fetchNationalPostalConfiguration(params) {
  return request('/api/sku/new-shop-country-postal-configs', {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
export function deleteCountryPostalConfigs(id) {
  return request(`/api/sku/new-shop-country-postal-configs/${id}`, {
    method: 'DELETE',
  });
}
export function editCountryPostalConfigs(data) {
  const { id } = data;
  delete data?.id;
  return request(`/api/sku/new-shop-country-postal-configs/${id}`, {
    data,
    method: 'PUT',
  });
}

export function addSkuPostalConfigs(data) {
  return request('/api/sku/sku-postal-configs', {
    data,
    method: 'POST',
    removeBlankProperty: true,
  });
}
export function fetchSkuPostalConfigs(data) {
  return request('/api/sku/sku-postal-configs/index', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
export function editSkuPostalConfigs(data) {
  const { id } = data;
  delete data?.id;
  return request(`/api/sku/sku-postal-configs/${id}`, {
    data,
    method: 'PUT',
  });
}
export function deleteSkuPostalConfigs(id) {
  return request(`/api/sku/sku-postal-configs/${id}`, {
    method: 'DELETE',
  });
}
export function fetchSkuPostalTags() {
  return request('/api/sku/sku-postal-tags', {
    method: 'GET',
  });
}
export function exportCountryPostalConfigs(params) {
  return request('/api/sku/new-shop-country-postal-configs/export', {
    params,
    method: 'GET',
    responseType: 'blob',
  });
}
export function exportSkuPostalConfigs(data) {
  return request('/api/sku/sku-postal-configs/index', {
    data,
    method: 'POST',
    headers: {
      export: 'xlsx',
      select: data.select,
    },
    removeBlankProperty: true,
  });
}
export async function importSkuPostalConfigs(data) {
  return request('/api/sku/sku-postal-configs/import', {
    method: 'POST',
    data,
  });
}
export async function fetchSkuPostalConfigsTemplate() {
  return request('/api/sku/sku-postal-configs/import-template', {
    method: 'GET',
  });
}

// 导出定制产品订单信息
export function exportCustomOrder(data) {
  return request('/api/sku/custom_order/export', {
    data,
    method: 'POST',
  });
}

// SKU销量 敏感信息
export function getSensitives(data) {
  return request('/api/sku_sensitives', {
    data,
    method: 'POST',
  });
}

// SKU销量 马帮自定义分类的敏感属性
export function getMaBangSensitives(params) {
  return request('/api/gross-attribute-config-list', {
    params: {
      'filter[attribute_source]': 1,
      ...params,
    },
    method: 'GET',
  });
}

// SKU销量 马帮自定义分类的敏感属性
export function getERPSensitives(params) {
  return request('/api/gross-attribute-config-list', {
    params: {
      'filter[attribute_source]': 1,
      ...params,
    },
    method: 'GET',
  });
}

// 修改汇总统计人员姓名
export function updateUserName(data) {
  return request('/api/sku/statistics/user-name-edit', {
    data,
    method: 'POST',
  });
}

// 删除SKU明细
export function getDeleteSku(data) {
  return request('/api/sku/statistics/delete-sku-detail', {
    data,
    method: 'POST',
    removeBlankProperty: true,
  });
}
// 获取 sku 用户信息
export function getSelectsku(data) {
  return request('/api/user/get-deal-history-sku-user', {
    data,
    method: 'GET',
  });
}
// 导出
export function excelDeleteSku(data) {
  return request('/api/sku/statistics/delete-sku-detail/export', {
    data,
    method: 'POST',
    removeBlankProperty: true,
  });
}

// 替换货源-列表
export function getReplaceSupplies(data) {
  return request('/api/sku/get-sku-replace-supplies', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 替换货源-导出
export function exportReplaceSupplies(data) {
  return request('/api/sku/get-sku-replace-supplies', {
    method: 'POST',
    data,
    headers: {
      export: 'xlsx',
      selects: data.selects,
    },
    removeBlankProperty: true,
    responseType: 'blob',
  });
}

// 新增货源替换-更新
export function updateReplaceSupplies(data) {
  const { id } = data;
  delete data.id;
  return request(`/api/sku/sku-replace-supplies/${id}`, {
    method: 'PUT',
    data,
  });
}

// 新增替换货源-审核
export function checkReplaceSupplies(data) {
  const { id } = data;
  delete data.id;
  return request(`/api/sku/sku-replace-supplies/${id}/check`, {
    method: 'POST',
    data,
  });
}

// 替换货源-删除
export function deleteReplaceSupplies(data) {
  const { id } = data;
  delete data.id;
  return request(`/api/sku/sku-replace-supplies/${id}`, {
    method: 'DELETE',
    data,
  });
}

// 替换货源-新增
export function createReplaceSupplies(data) {
  return request('/api/sku/sku-replace-supplies', {
    method: 'POST',
    data,
  });
}

// 获取替换货源提交人
export async function fetchReplaceSupplySubmitter(params) {
  return request('/api/sku/sku-replace-supply/peoples', {
    params,
  });
}

// SKU异常统一成本价 获取
export function getAbnormalList(data) {
  return request('/api/sku/abnormal-cost-skus', {
    data,
    method: 'POST',
  });
}

// SKU异常统一成本价 导出
export function exportAbnormalList(data) {
  return request('/api/sku/abnormal-cost-skus', {
    data,
    method: 'POST',
    headers: {
      export: 'xlsx',
    },
    responseType: 'blob',
  });
}

//产品审核-批量审核违规情况
export function batchReviewInfraction(data) {
  return request('/api/sku/batch-infraction-check', {
    data,
    method: 'POST',
  });
}

// 更新未采购原因
export async function updateNotPurchaseReason(data) {
  return request('/api/sku/not_placed_purchase/update-not-purchasing-reason', {
    method: 'POST',
    data,
  });
}
//修改订单明细导出
export async function dealOrderExport(data) {
  return request('/api/sku/statistics/delete-sku-detail/deal-order-export', {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// SKU重新修改审核失败的产品
export function updateFailedProduct(data) {
  return request('/api/sku/review/failed-product', {
    data,
    method: 'POST',
  });
}

// 获取马帮自定义分类
export function fetchMbCustomCategory(params) {
  return request('/api/sku/mb-custom-category-list', {
    params,
  });
}

// 获取停复工信息
export function fetchStopSkus(data) {
  return request('/api/sku/stop-sku-select', {
    data,
    method: 'POST',
    removeBlankProperty: true,
  });
}

// 新增停复工信息
export function createStopSkus(data) {
  return request('/api/sku/stop-skus', {
    data,
    method: 'POST',
  });
}

// 编辑停复工信息
export function updateStopSkus(data) {
  const { sku } = data;
  // delete data.sku;
  return request(`/api/sku/stop-skus/${sku}`, {
    data,
    method: 'PUT',
  });
}

// 删除停复工信息
export function deleteStopSkus(sku) {
  return request(`/api/sku/stop-skus/${sku}`, {
    method: 'DELETE',
  });
}

// 导入停复工信息
export function importStopSkus(data) {
  return request('/api/sku/stop-sku-import', {
    data,
    method: 'POST',
  });
}

// 导入停复工信息模板
export function fetchImportStopSkusTemplate(data) {
  return request('/api/sku/stop-sku-template', {
    data,
    method: 'GET',
  });
}

// 导出停复工信息
export function createStopSkusExportTask(data) {
  return request('/api/sku/stop-sku-export', {
    data,
    method: 'POST',
    removeBlankProperty: true,
  });
}

// 产品审核 获取SKU订单数
export function getVSKUOrderNum(params) {
  return request('/api/sku/get-sku-order-cnt', {
    params,
  });
}

// 统计汇总-SKU作废订单汇总-作废SKU-列表
export function getSKUInvalidList(data) {
  return request('/api/abandon/sku-list', {
    method: 'POST',
    data,
  });
}

// 统计汇总-SKU作废订单汇总-作废SKU-恢复SKU
export function resumeOrderBySku(data) {
  return request('/api/abandon/recover-by-sku', {
    method: 'POST',
    data,
  });
}

// 统计汇总-SKU作废订单汇总-作废SKU-恢复订单
export function resumeOrderByOrder(data) {
  return request('/api/abandon/recover-by-data_range', {
    method: 'POST',
    data,
  });
}

// 统计汇总-SKU作废订单汇总-恢复记录
export function resumeRecordList(data) {
  return request('/api/abandon/recover-record-list', {
    method: 'POST',
    data,
  });
}

// 统计汇总-SKU作废订单汇总-操作人列表
export function getOperatorList(data) {
  return request('/api/abandon/get-recover-operator', {
    method: 'POST',
    data,
  });
}

// 统计汇总-SKU作废订单汇总-作废订单列表
export function getOrderInvalidList(data) {
  return request('/api/abandon/order-list', {
    method: 'POST',
    data,
  });
}

// 统计汇总-SKU作废订单汇总-按订单号恢复订单
export function resumeOrderByOrderNumber(data) {
  return request('/api/abandon/recover-by-order_number', {
    method: 'POST',
    data,
  });
}

// 统计汇总-SKU作废订单汇总-作废订单导出
export function exportInvalidOrder(data) {
  return request('/api/abandon/order-export', {
    method: 'POST',
    data,
  });
}

// 统计汇总-SKU作废订单汇总-作废SKU导出
export function exportInvalidSKU(data) {
  return request('/api/abandon/sku-export', {
    method: 'POST',
    data,
  });
}

// 获取超时限整改、超次数整改的不剔除毛利数据部门配置表
export function getNoRemoveProfitPart() {
  return request('/api/get-part-no-need-gross');
}

// 获取审核失败（运营要求审核、违规审核失败、普通审核失败）的不剔除毛利数据配置表
export function getNoRemoveProfitPartFromFail() {
  return request('/api/get-part-no-need-gross', {
    params: {
      type: 2,
    },
  });
}

// 批量获取虚拟SKU相关信息（仅针对批量审核违规情况）
export function getVSKUInfo(data) {
  return request('/api/sku/select-infraction-check', {
    data,
    method: 'POST',
  });
}

// 判断当前用户是否可填写<文本图像定制>和<文本图像定制类型>
export function getSkuTReviewField() {
  return request('/api/sku/review/field', {
    method: 'GET',
  });
}

// 获取仓库匹配规则
export function getWareHouseMatchingRules() {
  return request('/api/sku/warehouse-matching-rules', {
    method: 'GET',
  });
}

// 保存仓库匹配规则
export function saveWareHouseMatchingRules(data) {
  return request('/api/sku/warehouse-matching-rules', {
    method: 'POST',
    data,
  });
}

// 获取仓库匹配规则中的仓库类型
export function getWareHouseMatchingType() {
  return request('/api/sku/warehouse-matching-rules/warehouses', {
    method: 'GET',
  });
}

// 获取虚拟仓库列表
export function getVirtualWareHouses() {
  return request('/api/sku/virtual-warehouses', {
    method: 'GET',
  });
}

// 保存虚拟仓库列表
export function saveVirtualWareHouses(data) {
  return request('/api/sku/virtual-warehouses', {
    method: 'POST',
    data,
  });
}

// 获取仓库调整配置
export function getWareHouseAdjustmentRules() {
  return request('/api/sku/warehouse-adjustment-rules', {
    method: 'GET',
  });
}

// 获取仓库调整配置
export function saveWareHouseAdjustmentRules(data) {
  return request('/api/sku/warehouse-adjustment-rules', {
    method: 'POST',
    data,
  });
}

// 马帮定制化SKU重跑
export function retryMabangCustomSku(data) {
  return request('/api/sku/mabang-custom-sku-retry', {
    method: 'POST',
    data,
  });
}

// 马帮定制化SKU重跑日志查询
export function fetchRetryMabangCustomSkuLogs(params) {
  return request('/api/sku/mabang-custom-sku-retry', {
    params,
  });
}

// 获取货源链接
export function fetchSupplyUrlBySku(sku) {
  return request(`/api/sku/get-sku-supply-url/${sku}`);
}

// SKU获取着陆页链接
export function fetchSkuProductUrl(data) {
  return request('/api/sku/get-sku-product-url', {
    method: 'POST',
    data,
  });
}
export function getDisapprovedAdsPostInfo(params) {
  return request('/api/fb-ad/disapproved-ads-post-info', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

export function fetchSkuProvider(sku) {
  return request(`/api/sku/review/get-sku-provider?skus=${sku}`);
}
// 批量审核失败
export function batchReviewFailed(data) {
  return request('/api/sku/review/batch-failure', {
    method: 'POST',
    data,
  });
}
//SKU出单审核汇总需审核SKU详情
export async function fetchDepartmentReviewStatisticsDaily(params) {
  return request('/api/sku/department-review-statistics/daily', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 导入库存产品
export function importStockSku(data) {
  return request('/api/sku/stock/sku-import', {
    method: 'POST',
    data,
  });
}
//  导入库存产品列表
export function importStockSkuList(params) {
  return request('/api/sku/stock/sku-import-list', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

//导入库存-移除
export function importStockSkudelete(params) {
  return request('/api/sku/stock/sku-import-delete', {
    method: 'DELETE',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

//  导入产品列表-模板
export function importStockSkuTemplate(params) {
  return request('/api/sku/stock/sku-import-template', {
    method: 'GET',
    params,
  });
}
// 产品审核-店铺链接勾选列表
export function fetchStoreLinkCheck(params) {
  return request('/api/sku/store-link-check', {
    method: 'GET',
    params,
  });
}
// 产品审核-勾选店铺链接
export function checkStoreLink(data) {
  return request('/api/sku/store-link-check', {
    method: 'POST',
    data,
  });
}
// 物流国家配置-通邮国家配置列表
export function fetchSkuPostalTagCountries(data) {
  return request('/api/sku/skuPostalTagCountriesIndex', {
    method: 'POST',
    data,
  });
}
// 物流国家配置-通邮国家配置列表
export function exportSkuPostalTagCountries(data) {
  return request('/api/sku/skuPostalTagCountriesIndex', {
    method: 'POST',
    data,
    headers: {
      export: 1,
    },
  });
}
// 物流国家配置-通邮国家配置编辑
export function updateSkuPostalTagCountries(id, data) {
  return request(`/api/sku/setSkuPostalTagCountries/${id}`, {
    method: 'POST',
    data,
  });
}
// 物流国家配置-通邮国家配置导入模板
export function fetchSkuPostalTagCountriesTemplate() {
  return request(`/api/sku/skuPostalTagCountriesTemplate`, {
    method: 'GET',
  });
}
// 物流国家配置-通邮国家配置导入
export function importSkuPostalTagCountries(data) {
  return request(`/api/sku/skuPostalTagCountriesImport`, {
    method: 'POST',
    data,
  });
} // 物流国家配置-重新获取功能
export function runRowSkuPostalConfigs(data) {
  return request(`/api/sku/sku-postal-configs/runNow`, {
    method: 'POST',
    data,
  });
}

// sku发货率通知-配置列表
export function fetchSkuShippingRateConfig(params) {
  return request('/api/sku/shipping-rate/config', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// sku发货率通知-配置添加
export function addSkuShippingRateConfig(data) {
  return request(`/api/sku/shipping-rate/config`, {
    method: 'POST',
    data,
  });
}
// sku发货率通知-配置修改-批量
export function updateSkuShippingRateConfig(data) {
  return request(`/api/sku/shipping-rate/config`, {
    method: 'PUT',
    data,
  });
}

// sku发货率通知-配置修改日志
export function fetchSkuShippingRateConfigLog(params) {
  return request('/api/sku/shipping-rate/config-modify-log', {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// sku发货率通知-配置删除-批量
export function deleteSkuShippingRateConfig(params) {
  return request('/api/sku/shipping-rate/config', {
    method: 'DELETE',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

//  sku发货率通知-阈值配置获取
export function fetchSkuShippingRateThresholdConfig(params) {
  return request('/api/sku/shipping-rate/threshold-config', {
    method: 'GET',
    params,
  });
}

// sku发货率通知-阈值配置修改
export function updateSkuShippingRateThresholdConfig(data) {
  return request(`/api/sku/shipping-rate/threshold-config`, {
    method: 'PUT',
    data,
  });
}
export async function exportSkuDisputeSkuDisput(data) {
  return request('/api/dispute-sku', {
    method: 'POST',
    data,
    headers: {
      export: 'xlsx',
    },
  });
}
// 产品审核导出
export function exportSkuReview(data) {
  return request(`/api/sku/review/export`, {
    method: 'POST',
    data,
  });
}
// 产品审核-未审核阈值展示
export function fetchUnreviewedThreshold() {
  return request(`/api/system/configs?filter[name]=review_uncheck_days_limit`);
}
// 产品审核-编辑阈值
export function updateUnreviewedThreshold(data) {
  return request(`/api/system/configs/review_uncheck_days_limit`, {
    method: 'PUT',
    data,
  });
}

// 产品审核-审核失败关联真实SKU
export function skuReviewError(data) {
  return request(`/api/sku/review-error`, {
    method: 'PUT',
    data,
  });
}
// 产品审核-超期未审核SKU通知
export function fetchOverdueForReview(params) {
  return request(`/api/sku-part-notice-config/index`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
// 产品审核-删除超期未审核SKU
export function deleteOverdueForReview(data) {
  return request(`/api/sku-part-notice-config`, {
    method: 'DELETE',
    data,
  });
}
// 产品审核-编辑超期未审核SKU
export function updateOverdueForReview(data) {
  return request(`/api/sku-part-notice-config`, {
    method: 'PUT',
    data,
  });
}
// 产品审核-新增超期未审核SKU
export function addOverdueForReview(data) {
  return request(`/api/sku-part-notice-config`, {
    method: 'POST',
    data,
  });
}
// 产品审核-超期未审核SKU日志
export function fetchOverdueForReviewLog(params) {
  return request(`/api/sku-part-notice-config/logList`, {
    method: 'GET',
    params,
  });
}
// SKU列表-ERP自定义分类筛选项
export function fetchSkuCategoryTags() {
  return request(`/api/sku/erp/get-sku-category-tags`);
}
// 物流国家配置-SKU通邮配置批量删除
export function batchDeleteSkuPostalConfigs(data) {
  return request('/api/sku/sku-postal-batch-destroy', {
    method: 'DELETE',
    data,
  });
}
// 物流国家配置-国家通邮配置批量删除
export function batchDeleteCountryPostalConfigs(data) {
  return request('/api/sku/new-shop-country-postal-batch-destroy', {
    method: 'DELETE',
    data,
  });
}
// SKU列表-更新国家
export function updateCountry(data) {
  return request(`/api/sku/update-country`, {
    method: 'PUT',
    data,
  });
}
// 产品审核-获取最新配置的可投放国家
export function fetchLastCountry(t_sku) {
  return request(`/api/sku/last-country?t_sku=${t_sku}`);
}

// 产品审核-累计销售数量详情
export function fetchSKUOrderSales(data) {
  return request('/api/sku/get-sku-order-sales', {
    method: 'POST',
    data,
  });
}
// 产品审核-SKU出单链接详情
export function fetchSKULandingSales(data) {
  return request('/api/sku/get-sku-landing-sales', {
    method: 'POST',
    data,
  });
}
// 删除SKU列表
export async function deleteSkuList(data) {
  return request('/api/sku/delete-list-sku', {
    method: 'POST',
    data,
  });
}
// 产品审核-获取自定义分类
export function fetchErpSkuCategoryTags() {
  return request('/api/sku/erp/get-sku-category-tags');
}
