import request from '@/utils/request';
import { stringify } from 'qs';

//风控列表
export async function fetchDailyCheckList(data) {
  return request(`/api/asset/daily/check`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 风控批量审核
export async function batchReviewDailyCheck(data) {
  return request(`/api/batch/asset/daily/check`, {
    method: 'POST',
    data,
  });
}
// 复审
export async function batchDailyCheckRehear(data) {
  return request(`/api/asset/daily/rehear`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
//风控审核日志状态分布
export async function fetchDailyCheckReviewLogDistribution(check_asset_id) {
  return request(`/api/asset/daily/${check_asset_id}/logs/status`, {
    method: 'GET',
    removeBlankProperty: true,
  });
}
//风控审核日志记录
export async function fetchDailyCheckReviewLogList(check_asset_id, params = {}) {
  return request(`/api/asset/daily/${check_asset_id}/logs`, {
    method: 'GET',
    removeBlankProperty: true,
    params,
  });
}
//风控审核日志详情
export async function fetchDailyCheckReviewLogDetail(check_asset_log_id) {
  return request(`/api/asset/daily/log/${check_asset_log_id}/content`, {
    method: 'GET',
    removeBlankProperty: true,
  });
}

// 最新一次审核日志
export async function fetchDailyCheckReviewLogLatest(check_asset_id) {
  return request(`/api/asset/daily/log/${check_asset_id}/last`, {
    method: 'GET',
    removeBlankProperty: true,
  });
}
// 获取虚拟子sku
export async function fetchDailyCheckVirtualSku(check_asset_id) {
  return request(`/api/asset/daily/${check_asset_id}/son_skus`, {
    method: 'GET',
    removeBlankProperty: true,
  });
}

//风控审核导出
export async function exportDailyCheckList(data) {
  return request(`/api/asset/daily/check/export`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
    headers: {
      export: 'xlsx',
    },
  });
}

// 获取风控审核审核人员列表
export async function fetchReviewPeopleList(data) {
  return request('/api/asset/daily/check/assigners', {
    method: 'GET',
    data,
    removeBlankProperty: true,
  });
}

//资产审核page审核获取日志
export async function fetchAssetPageRviewLog(data) {
  return request(`/api/asset-review/pages/log`, {
    method: 'POST',
    data,
  });
}

// 资产审核page导出excel
export async function exportAssetPageReview(data) {
  return request(`/api/asset-review/pages/export`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

//资产审核着陆页审核获取日志
export async function fetchAssetLandingRviewLog(data) {
  return request(`/api/asset-review/landings/log`, {
    method: 'POST',
    data,
  });
}
// 资产审核着陆页导出excel
export async function exportAssetLandingReview(data) {
  return request(`/api/asset-review/landings/export`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}
//资产审核网站审核获取日志
export async function fetchAssetDomainRviewLog(data) {
  return request(`/api/asset-review/domains/log`, {
    method: 'POST',
    data,
  });
}

// 资产审核网站审核导出excel
export async function exportAssetDomainReview(data) {
  return request(`/api/asset-review/domains/export`, {
    method: 'POST',
    data,
    removeBlankProperty: true,
  });
}

// 获取账号类型
export async function fetchOldBMCategories() {
  return request('/api/asset/old_bm_change_email_log_categories');
}

// 添加账号类型
export async function AddOldBMCategories(data) {
  return request('/api/asset/old_bm_change_email_log_categories', {
    method: 'POST',
    data,
  });
}
//获取风控所有审核人员
export async function fetchDailyCheckReviewPeople() {
  return request(`/api/asset/daily/check/reviewer`, {
    method: 'GET',
  });
}

//风控汇总小红点
export async function fetchDailyCheckTotal(data) {
  return request(`/api/asset/daily/check-status-count`, {
    method: 'POST',
    removeBlankProperty: true,
    data,
  });
}

//获取所有主体
export async function fetchEntityList() {
  return request(`/api/asset/entity-list`, {
    removeBlankProperty: true,
  });
}

//获取未分配账号列表
export async function fetchEntityAccounts(params) {
  return request(`/api/asset/entity-accounts`, {
    removeBlankProperty: true,
    params,
  });
}

//编辑未分配账号
export async function UpdateEntityAccounts(data) {
  return request(`/api/asset/ready-act-assign`, {
    method: 'POST',
    removeBlankProperty: true,
    data,
  });
}

// 导出主体未分配账号
export async function exportEntityAccounts(data, select) {
  return request('/api/asset/entity-accounts-export', {
    method: 'POST',
    data,
    headers: {
      export: 'xlsx',
      select,
    },
    removeBlankProperty: true,
  });
}

// TT个人号
export async function fetchTikTokPersonalAccounts(params) {
  return request(`/api/asset/tik-tok/personal-accounts`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 新增个人号
export async function createTikTokPersonalAccounts(data) {
  return request(`/api/asset/tik-tok/personal-accounts`, {
    method: 'POST',
    data,
  });
}

// 修改个人号
export async function updateTikTokPersonalAccounts(id, data) {
  return request(`/api/asset/tik-tok/personal-accounts/${id}`, {
    method: 'PUT',
    data,
  });
}

//删除个人号
export async function deleteTikTokPersonalAccounts(id) {
  return request(`/api/asset/tik-tok/personal-accounts/${id}`, {
    method: 'DELETE',
    getResponse: true,
  });
}
// 导出个人号
export async function exportTikTokPersonalAccounts(params, select) {
  return request(`/api/asset/tik-tok/personal-accounts`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    headers: {
      export: 'xlsx',
      select,
    },
  });
}

// TT素材试投放记录列表
export async function fetchTikTokMateroaPutRecords(params) {
  return request(`/api/asset/tik-tok/material-put-records`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// TT素材试投放记录 导入模板下载
export async function fetchTikTokMateroaPutRecordsTemplate() {
  return request(`/api/asset/tik-tok/material-put-records/import-template`);
}

// TT素材试投放记录 导入
export async function importTikTokMateroaPutRecords(data) {
  return request(`/api/asset/tik-tok/material-put-records/import`, {
    method: 'POST',
    data,
  });
}

// TT素材试投放记录 删除
export async function deleteTikTokMateroaPutRecords(data) {
  return request(`/api/asset/tik-tok/material-put-records`, {
    method: 'DELETE',
    data,
  });
}

// TT素材试投放记录 修改
export async function updateTikTokMateroaPutRecords(data) {
  return request(`/api/asset/tik-tok/material-put-records`, {
    method: 'PUT',
    data,
    getResponse: true,
  });
}

// TT素材试投放记录列表 导出
export async function exportTikTokMateroaPutRecords(params) {
  return request(`/api/asset/tik-tok/material-put-records`, {
    method: 'GET',
    params,
    headers: {
      export: 'xlsx',
    },
    removeBlankProperty: true,
  });
}

// TT广告户管理
export async function fetchTikTokAdvertisers(params) {
  return request(`/api/asset/tik-tok/advertisers`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// TT广告户管理更新关联日志
export async function updateTikTokAdvertisersLog(id, data) {
  return request(`/api/asset/tik-tok/advertisers/logs/${id}`, {
    method: 'PUT',
    data,
  });
}

// TT广告户管理导出
export async function exportTikTokAdvertisers(params, select) {
  return request(`/api/asset/tik-tok/advertisers`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
    headers: {
      export: 'xlsx',
      select,
    },
  });
}

// ?type=timezone
export async function fetchTikTokAdvertisersLists(params) {
  return request(`/api/asset/tik-tok/advertisers/lists`, {
    method: 'GET',
    params,
  });
}

// 获取帖子广告系列账号数据
export async function fetchAdsStructure(params) {
  return request(`/api/review/risk/post-id/ads-structure`, {
    method: 'GET',
    params,
  });
}

// 导出帖子广告系列账号数据
export async function exportAdsStructure(params) {
  return request(`/api/review/risk/post-id/ads-structure`, {
    method: 'GET',
    params,
    data: params,
    headers: {
      export: 'xlsx',
    },
  });
}

// 批量分配审核人
export async function updateRiskControlAuditReviewer(data) {
  return request(`/api/asset/daily/assigners`, {
    method: 'PATCH',
    data,
  });
}
export async function fetchAssetAssignedLogs(type, id) {
  return request(`/api/asset/${type}/${id}/assigned/logs`, {
    method: 'GET',
  });
}
export async function retryFailedApplies(type, data) {
  return request(`/api/asset/${type}/retry-failed-applies`, {
    method: 'POST',
    data,
  });
}

// 获取指定广告户
export async function fetchTikTokAdvertisersId(params) {
  return request(`/api/tiktok/advertisers`, {
    method: 'GET',
    params,
    removeBlankProperty: true,
  });
}
// TT广告户管理-修改域名
export async function updateTikTokAdvertisers(data) {
  return request(`/api/asset/tik-tok/advertisers`, {
    method: 'PUT',
    data,
  });
}

export async function createAssetBmShare(data) {
  return request(`/api/asset/bm/share`, {
    method: 'POST',
    data,
  });
}
// 取消资产共享
export async function cancelAssetBmShare(id) {
  return request(`/api/asset/bm/share/${id}`, {
    method: 'DELETE',
  });
}

// 更新资产共享
export async function updateAssetBmShare(data) {
  return request(`/api/asset/bm/share`, {
    method: 'PUT',
    data,
  });
}
export async function fetchTikTokAdvertisersIdomainsChangeLog(params) {
  return request(`/api/asset/tik-tok/advertisers-domains-change-log`, {
    method: 'GET',
    params,
  });
}

// 共享日志
export async function fetchAssetBmShareLogs(params) {
  return request(`/api/asset/bm/share-logs`, {
    method: 'GET',
    params,
  });
}
// 广告户开户申请统计
export async function fetchAdAccountApply(data) {
  return request(`/api/asset/ad-account-apply/list`, {
    method: 'POST',
    data,
  });
}
// 广告户开户申请统计-申请单审核
export async function reviewAdAccountApply(data) {
  return request(`/api/asset/ad-account-apply/review`, {
    method: 'POST',
    data,
  });
}
// 账号已分配BM
export async function fetchAccountSharedBM(data) {
  return request(`/api/asset/account/shared/bm/search`, {
    method: 'POST',
    data,
  });
}
// 账号已分配BM-导出
export async function exportAccountSharedBM(data) {
  return request(`/api/asset/account/shared/bm/search`, {
    method: 'POST',
    headers: {
      export: 1,
    },
    data,
  });
}
// 资产关系-广告账号回收申请
export async function fetchAccountRecycleForOperation(data) {
  return request('/api/asset/account/recycle-for-operation', {
    method: 'POST',
    data,
  });
}
// 资产关系-广告账户回收日志
export async function fetchAssetRecycleLogForOperation(params) {
  return request('/api/asset/account/recycle-log-for-operation', {
    method: 'GET',
    params,
  });
}
