const Model = {
  namespace: 'imageView',
  state: {},
  effects: {},
  reducers: {
    update(state, { payload }) {
      return payload;
    },
    addIndex(state) {
      return { ...state, select: state.select + 1 };
    },
    reduceIndex(state) {
      return { ...state, select: state.select - 1 };
    },
  },
};

export default Model;
