import { fetchArticleList, fetchArticleSub, articleType } from '@/services/api';

export default {
  namespace: 'faq',
  state: {
    list: {},
    subType: [],
  },

  effects: {
    *getFaqSub(_, { call, put }) {
      const response = yield call(fetchArticleSub, articleType.FAQ);
      yield put({
        type: 'saveFaqSub',
        payload: response,
      });
    },
    *getFaqList({ payload }, { call, put }) {
      const response = yield call(fetchArticleList, { id: payload, type: articleType.FAQ });
      yield put({
        type: 'saveFaqList',
        payload: response,
      });
    },
  },
  reducers: {
    saveFaqSub(state, action) {
      return {
        ...state,
        subType: action.payload || [],
      };
    },
    saveFaqList(state, action) {
      return {
        ...state,
        list: action.payload || {},
      };
    },
  },
};
