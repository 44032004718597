import { fetchDashROI } from '@/services/api';
import { fetchStoreBigData, fetchDashTotalOrder, fetchDashBoardBigData } from '@/services/bigData';

const initState = {
  totalOrder: {},
  trendByPartOrStore: [],
  acttrend: [],
  topdata: {},
  roiTrendByPartOrStore: {},
  orderTrendWithUser: {},
  roiTrendWithUser: {},
  orderByPartOrStore: [],
  orderByStore: [],
  totalOrderTrend: [],
  topTen: [],
  storeStatus: [],
  roiByPartOrStore: [],
  roiByPart: [],
};

const handleParams = params => {
  const payload = {
    ...params,
    filter: {},
  };
  const filterArray = ['department', 'people', 'timezone', 'date_at'];
  filterArray.forEach(item => {
    if (payload[item]) {
      if (item === 'date_at') {
        Object.assign(payload.filter, {
          date: {
            gte: payload.date_at.split(',')[0],
            lte: payload.date_at.split(',')[1],
          },
        });
      } else if (item === 'timezone') {
        Object.assign(payload.filter, {
          [item]: {
            bj: 'BeiJing',
            tz: 'System',
          }[payload[item]],
        });
      } else {
        Object.assign(payload.filter, {
          [item]: payload[item],
        });
      }

      delete payload[item];
    }
  });

  return payload;
};

export default {
  namespace: 'dashboard',

  state: initState,

  effects: {
    *getDashTotalOrder({ payload, callback }, { call, put }) {
      const response = yield call(fetchDashTotalOrder, handleParams(payload));
      yield put({
        type: 'saveDashTotalOrder',
        payload: response,
      });
      if (callback) callback();
    },

    *getTrendByStore({ payload, callback }, { call, put }) {
      const response = yield call(fetchStoreBigData, payload);
      yield put({
        type: 'saveTrendByStore',
        payload: response,
      });
      if (callback) callback();
    },
    *getTrendByPart({ payload, callback }, { call, put }) {
      function* fetchAll(prevData, currentPayload) {
        const response = yield fetchDashBoardBigData(handleParams(currentPayload));
        const { page, per_page, total } = response.meta;
        if (total > page * Number(per_page)) {
          return yield fetchAll([...prevData, ...response.data], {
            ...currentPayload,
            page: page + 1,
          });
        } else return [...prevData, ...response.data];
      }
      const response = yield call(fetchAll, [], payload);
      yield put({
        type: 'saveTrendByPart',
        payload: response,
      });
      if (callback) callback();
    },
    *getDashActTrend({ payload, callback }, { call, put }) {
      const response = yield call(fetchDashBoardBigData, handleParams(payload));
      yield put({
        type: 'saveDashActTrend',
        payload: response,
      });
      if (callback) callback();
    },
    *getRoiTrendByPartOrStore({ payload }, { call, put }) {
      const response = yield call(fetchDashROI, payload);
      yield put({
        type: 'saveRoiTrendByPartOrStore',
        payload: response,
      });
    },
    *getRoiByPartOrStore({ payload }, { call, put }) {
      const response = yield call(fetchDashROI, payload);
      yield put({
        type: 'saveRoiByPartOrStore',
        payload: response,
      });
    },
    *getOrderTrendWithUser({ payload }, { call, put }) {
      const response = yield call(fetchDashBoardBigData, handleParams(payload));
      yield put({
        type: 'saveOrderTrend',
        payload: response,
      });
    },
    *getRioTrendWithUser({ payload, callback }, { call, put }) {
      const response = yield call(fetchDashBoardBigData, handleParams(payload));
      yield put({
        type: 'saveRoiTrend',
        payload: response,
      });
      if (callback) callback();
    },
    *getOrderByPartOrStore({ payload }, { call, put }) {
      const response = yield call(fetchDashBoardBigData, handleParams(payload));
      yield put({
        type: 'saveOrderByPartOrStore',
        payload: response,
      });
    },
    *getOrderByStore({ payload }, { call, put }) {
      const response = yield call(fetchStoreBigData, handleParams(payload));
      yield put({
        type: 'saveOrderByStore',
        payload: response,
      });
    },
    *getTotalOrderTrend({ payload }, { call, put }) {
      const response = yield call(fetchDashBoardBigData, handleParams(payload));
      yield put({
        type: 'saveTotalOrderTrend',
        payload: response,
      });
    },
    *getTopTenOrder({ payload }, { call, put }) {
      const response = yield call(fetchDashBoardBigData, handleParams(payload));
      yield put({
        type: 'saveTopTenOrder',
        payload: response,
      });
    },
  },
  reducers: {
    saveDashTotalOrder(state, action) {
      return {
        ...state,
        totalOrder: action.payload.data?.[0] || {},
      };
    },
    saveTrendByStore(state, action) {
      return {
        ...state,
        trendByStore: action.payload.data,
      };
    },
    saveTrendByPart(state, action) {
      return {
        ...state,
        trendByPart: action.payload,
      };
    },
    saveDashActTrend(state, action) {
      return {
        ...state,
        acttrend: action.payload.data,
      };
    },
    saveRoiTrendByPartOrStore(state, action) {
      return {
        ...state,
        roiTrendByPartOrStore: action.payload,
      };
    },
    saveOrderTrend(state, action) {
      return {
        ...state,
        orderTrendWithUser: action.payload,
      };
    },
    saveRoiTrend(state, action) {
      return {
        ...state,
        roiTrendWithUser: action.payload,
      };
    },
    saveOrderByPartOrStore(state, action) {
      return {
        ...state,
        orderByPartOrStore: action.payload.data,
      };
    },
    saveOrderByStore(state, action) {
      return {
        ...state,
        orderByStore: action.payload.data,
      };
    },
    saveTotalOrderTrend(state, action) {
      return {
        ...state,
        totalOrderTrend: action.payload?.data,
      };
    },
    saveTopTenOrder(state, action) {
      return {
        ...state,
        topTen: action.payload.data,
      };
    },
    saveRoiByPartOrStore(state, action) {
      return {
        ...state,
        roiByPartOrStore: action.payload,
      };
    },
    saveRoiByPart(state, action) {
      return {
        ...state,
        roiByPart: action.payload.data,
      };
    },

    clear() {
      return { ...initState };
    },
  },
};
