import moment from 'moment';
import {
  fetchAdMaterial,
  fetchDealAdReviews,
  updateAdPostInfo,
  fetchMaterialExportJob,
} from '@/services/api';

export default {
  namespace: 'adMaterial',

  state: {
    adMaterialList: [],
    meta: {
      currentPage: 1,
      total: 1,
    },
    materialUserlist: [],
    exportData: {},
  },

  effects: {
    *getAdMaterialList({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdMaterial, payload);
      yield put({
        type: 'saveAdMaterialList',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getAdMaterialUser({ payload, callback }, { call, put }) {
      const response = yield call(fetchDealAdReviews, payload);
      yield put({
        type: 'saveAdMateriaUser',
        payload: response,
      });
      if (callback) callback();
    },
    *updatetAdMaterialStatus({ payload: { data, currentUser }, callback }, { call, put }) {
      const response = yield call(updateAdPostInfo, data);
      yield put({
        type: 'saveAdMaterialStatus',
        payload: {
          response,
          data,
          currentUser,
        },
      });
      if (callback) callback(response);
    },
    *getMaterialExportJob({ payload }, { call, put }) {
      const response = yield call(fetchMaterialExportJob, payload);
      yield put({
        type: 'saveMaterialExportJob',
        payload: response,
      });
    },
  },
  reducers: {
    saveAdMaterialList(state, action) {
      const { data = [], meta = {} } = action.payload;
      return {
        ...state,
        adMaterialList: data,
        meta: {
          currentPage: meta.current_page,
          total: meta.total,
        },
      };
    },
    saveAdMateriaUser(state, action) {
      return {
        ...state,
        materialUserlist: action?.payload || [],
      };
    },
    saveAdMaterialStatus(state, action) {
      const { response, data: { status, story_ids: storyIds = [], note } = {}, currentUser } =
        action.payload || {}; // eslint-disable-line
      if (response?.status === 0) {
        const newData = state.adMaterialList?.map?.(item => {
          const tempLogs = [
            ...item.review_logs,
            {
              created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              status,
              note: `${note}`,
              operation_user: currentUser,
            },
          ];
          if (storyIds.includes(item.story_id))
            return { ...item, status, note, review_logs: tempLogs };
          return { ...item };
        });
        return { ...state, adMaterialList: newData };
      }
      return state;
    },
    saveMaterialExportJob(state, action) {
      const { meta: { total } = {}, data = [] } = action.payload || {};
      return {
        ...state,
        exportData: {
          total,
          data,
        },
      };
    },
  },
};
