import {
  downExcelModel,
  exportSkuCalc,
  fetchSkuCalc,
  updateCostHeight,
  fetchSkuCalcCountry,
  fetchCountries,
  exportCountryExcel,
  fetchOrderSkuCalc,
  fetchMaterialExportJob,
  fetchIgnorePartList,
} from '@/services/api';

export default {
  namespace: 'skucalc',
  state: {
    totalCount: 0,
    resData: [],
    updateMessage: '',
    skuCalcCountry: {},
    countries: [],
    exportData: {
      meta: {},
      data: [],
    },
    orderSkuTotal: 0,
    orderSkuData: [],
    ignorePartList: [],
  },

  effects: {
    *getSkuCalc({ payload }, { call, put }) {
      const response = yield call(fetchSkuCalc, payload);
      yield put({
        type: 'saveSkuCalc',
        payload: response,
      });
    },
    *getOrderSkuCalc({ payload }, { call, put }) {
      const response = yield call(fetchOrderSkuCalc, payload);
      yield put({
        type: 'saveOrderSkuCalc',
        payload: response,
      });
    },
    *skuCalcUpdate({ payload, callback }, { call, put }) {
      const response = yield call(updateCostHeight, payload);
      yield put({
        type: 'updateRes',
        payload: response.message,
      });
      if (callback) callback(response.message);
    },
    *downExcel({ callback }, { call }) {
      const response = yield call(downExcelModel);
      if (callback) callback(response);
    },
    // 导出Excel
    *exportExcel({ payload, callback }, { call }) {
      const response = yield call(exportSkuCalc, payload);
      if (callback) callback(response.uri);
    },
    *exportCountriesExcel({ payload, callback }, { call }) {
      const response = yield call(exportCountryExcel, payload);
      if (callback) callback(response.uri);
    },
    *getSkuCalcCountry({ payload }, { call, put }) {
      const response = yield call(fetchSkuCalcCountry, payload);
      yield put({
        type: 'saveSkuCalcCountry',
        payload: response,
      });
    },
    *getCountries(_, { call, put }) {
      const response = yield call(fetchCountries);
      yield put({
        type: 'saveCountry',
        payload: response,
      });
    },
    *getExport({ payload, callback }, { call, put }) {
      const response = yield call(fetchMaterialExportJob, payload);
      yield put({
        type: 'saveExport',
        payload: response,
      });
      if (callback) callback();
    },
    *getIgnorePartList({ payload }, { call, put }) {
      const response = yield call(fetchIgnorePartList, payload);
      yield put({
        type: 'saveIgnorePartList',
        payload: response,
      });
    },
  },
  reducers: {
    saveSkuCalc(state, action) {
      return {
        ...state,
        totalCount: action.payload.total_count || 0,
        resData: action.payload.data || [],
      };
    },
    saveOrderSkuCalc(state, action) {
      return {
        ...state,
        orderSkuTotal: action.payload?.meta?.total || 0,
        orderSkuData: action.payload.data || [],
      };
    },
    saveSkuCalcCountry(state, action) {
      return {
        ...state,
        skuCalcCountry: action.payload,
      };
    },
    saveCountry(state, action) {
      return {
        ...state,
        countries: action.payload,
      };
    },
    saveExport(state, action) {
      const { meta: { current_page: currentPage, total } = {}, data = [] } = action.payload || {};
      return {
        ...state,
        exportData: {
          meta: {
            currentPage,
            total,
          },
          data,
        },
      };
    },
    saveIgnorePartList(state, action) {
      return {
        ...state,
        ignorePartList: action?.payload?.data || [],
      };
    },
  },
};
