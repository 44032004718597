import request from '@/utils/request';

// PP 参数 => 列表数据, 可获取搜索账户邮箱
export function fetchPPParams(params) {
  return request('/api/paypal/config_records', {
    params,
    removeBlankProperty: true,
  });
}

// PP 参数 => 导入模板
export function exportPPParamsExcel() {
  return request('/api/paypal/config_record/excel_template');
}

// PP 参数 => 导入
export function importPPParams(data) {
  return request('/api/paypal/config_records', {
    method: 'POST',
    data,
  });
}

// PP 参数 => 批量修改
export function batchUpdatePPParams(data) {
  return request('/api/paypal/config_record/batch_update', {
    method: 'POST',
    data,
  });
}

// PP 参数 => 查看日志
export function getPPParamsLog({ id, ...params }) {
  return request(`/api/paypal/config_records/${id}/change_log`, {
    params,
  });
}

// PP 参数 => 导出
export function exportPPParams(params) {
  return request('/api/paypal/config_record/export', {
    params,
    removeBlankProperty: true,
  });
}

// 获取PP家族
export function fetchPPFamily() {
  return request('/api/paypal/family', {
    method: 'POST',
  });
}
