import { fetchPPReceive, fetchPPBalance, fetchPPAmount } from '@/services/api';

export default {
  namespace: 'paypal',

  state: {
    receive: {
      data: [],
    },
    balance: {
      data: [],
    },
    ppAmount: [],
    ppEmial: [],
  },

  effects: {
    *getPPReceive({ callback }, { call, put }) {
      const response = yield call(fetchPPReceive);
      yield put({
        type: 'savePPReceive',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getPPBalance({ payload }, { call, put }) {
      const response = yield call(fetchPPBalance, payload);
      yield put({
        type: 'savePPBalance',
        payload: response,
      });
    },
    *getPPAmount({ payload, callback }, { call, put }) {
      const response = yield call(fetchPPAmount, payload);
      yield put({
        type: 'savePPAmount',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getPpEmailOptions({ payload }, { call, put }) {
      const response = yield call(fetchPPBalance, payload);
      yield put({
        type: 'savePpEmailOptions',
        payload: response,
      });
    },
  },
  reducers: {
    savePPReceive(state, action) {
      const { data = [] } = action.payload || {};
      return {
        ...state,
        receive: {
          data,
        },
      };
    },
    savePPBalance(state, action) {
      const {
        data = [],
        meta: { total },
      } = action.payload || {};
      return {
        ...state,
        balance: {
          data,
          total,
        },
      };
    },
    savePPAmount(state, action) {
      return {
        ...state,
        ppAmount: action.payload,
      };
    },
    savePpEmailOptions(state, action) {
      return {
        ...state,
        ppEmial: action?.payload?.data || [],
      };
    },
  },
};
