import {
  fetchCustomizeCategory,
  fetchCustomizeCategoryOperator,
  fetchCustomizeCategoryName,
} from '@/services/api';

export default {
  namespace: 'classification',
  state: {
    data: [],
    total: 0,
    categoryOperators: [],
    categoryNames: [],
  },

  effects: {
    *getClassification({ payload }, { call, put }) {
      const response = yield call(fetchCustomizeCategory, payload);
      yield put({
        type: 'saveClassification',
        payload: response,
      });
    },
    *getClassificationOperator({ payload }, { call, put }) {
      const response = yield call(fetchCustomizeCategoryOperator, payload);
      yield put({
        type: 'saveClassificationOperator',
        payload: response,
      });
    },
    *getClassificationName({ payload, callback }, { call, put }) {
      const response = yield call(fetchCustomizeCategoryName, payload);
      yield put({
        type: 'saveClassificationName',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveClassification(state, action) {
      const { data, meta = {} } = action.payload;
      return {
        ...state,
        data: data?.map((i, index) => ({ ...i, rowkey: index + 1 })),
        total: meta?.total || 0,
      };
    },
    saveClassificationOperator(state, action) {
      return {
        ...state,
        categoryOperators: action?.payload?.data || [],
      };
    },
    saveClassificationName(state, action) {
      return {
        ...state,
        categoryNames: action?.payload?.data || [],
      };
    },
  },
};
