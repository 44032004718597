import { fetchBmToken, fetchUserAppTokenList } from '@/services/api';

export default {
  namespace: 'bmToken',

  state: {
    data: [],
    total: 0,
  },

  effects: {
    *getBmToken({ payload }, { call, put }) {
      const response = yield call(fetchBmToken, payload);
      yield put({
        type: 'saveBmToken',
        payload: { response, bmType: payload.bm_type },
      });
    },
    *getUserAppTokenList({ payload }, { call, put }) {
      const response = yield call(fetchUserAppTokenList, payload);
      yield put({
        type: 'saveUserAppTokenList',
        payload: response,
      });
    },
  },
  reducers: {
    saveBmToken(
      state,
      {
        payload: {
          response: { data, meta },
          bmType,
        },
      },
    ) {
      return {
        ...state,
        data: data?.map(i => ({ ...i, uuid: `${i.bm_id}-${bmType}` })),
        total: meta?.total || 0,
      };
    },
    saveUserAppTokenList(state, { payload }) {
      return {
        ...state,
        data: payload?.data?.map(i => ({ ...i, uuid: i.bm_id })),
        total: payload?.meta?.total || 0,
      };
    },
  },
};
