import { fetchCampaignSpendDay } from '@/services/api';

export default {
  namespace: 'adData',

  state: {
    adSetSpendDayList: [],
    meta: {
      currentPage: 1,
      lastPage: 1,
      total: 1,
      totalData: {},
    },
  },

  effects: {
    *getAdSetSpendDayList({ payload, callback }, { call, put }) {
      const response = yield call(fetchCampaignSpendDay, payload);
      yield put({
        type: 'saveAdSetSpendDayList',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveAdSetSpendDayList(state, action) {
      const {
        data,
        meta: { current_page: currentPage, last_page: lastPage, total, total_data: totalData },
      } = action.payload;
      return {
        ...state,
        adSetSpendDayList: data,
        meta: {
          currentPage,
          lastPage,
          total,
          totalData,
        },
      };
    },
  },
};
