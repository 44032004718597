import request from '@/utils/request';
import { stringify, parse } from 'qs';

// 首页店铺订单数据详情
export async function fetchStoreBigData(params) {
  return request(`/api/big_data/roi_shop_order`, {
    method: 'GET',
    params: {
      is_sku_quantity: 1,
      ...params,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 获取Dashboard总的单个数据
export async function fetchDashTotalOrder(params) {
  return request('/api/big_data/roi_day_total', {
    params: {
      is_sku_quantity: 0,
      ...params,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 首页订单数/销售额/销售量
export async function fetchDashBoardBigData(params) {
  return request(`/api/big_data/roi_day_detail`, {
    method: 'GET',
    params: {
      is_sku_quantity: 1,
      sort: params.order_by ? undefined : 1,
      ...params,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 指标分析均值
export async function fetchAdspendCpmAvg(data) {
  return request('/api/big-data/proxy/getAdsAccountAnalysisAvg', {
    data,
    method: 'POST',
  });
}

// 指标分析
export async function fetchAdspendCpm(data) {
  return request('/api/advertise-analysis/indicator-analysis', {
    data: parse(data, { arrayFormat: 'brackets' }),
    method: 'post',
    removeBlankProperty: true,
    // method: 'POST',
  });
}

// 团队roi按天导出
export async function exportRoiExcelByDay(params) {
  return request(`/api/big_data/roi_day_detail/daily/export`, {
    method: 'GET',
    params: {
      ...params,
      is_sku_quantity: 0,
    },
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 运营报表 => 品类发货率
export function getCategoryList(params) {
  return request('/api/big-data/proxy/getCategoryExpressRate', {
    params,
    method: 'POST',
  });
}

// 运营报表 => 品类发货率(无异常)
export function getRealCategoryList(params) {
  return request('/api/big-data/proxy/getRealCategoryExpressRate', {
    params,
    method: 'POST',
  });
}

// 运营报表 => 品类发货率 => 品类
export function getCategoryName(params) {
  return request('/api/big-data/proxy/getNewMabanProductCategory', {
    params,
    method: 'POST',
  });
}

// 统计报表-广告账号异常情况统计-查看广告账号情况分析
export function fetchAccountStatistics(params) {
  return request('/api/ad-account/statistics', {
    params,
    method: 'GET',
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// 统计报表-广告账号异常情况统计-查看广告账号异常情况
export function fetchAccountStatisticsAbnormal(params) {
  return request('/api/ad-account/statistics-abnormal', {
    params,
    method: 'GET',
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// 统计报表-广告账号异常情况统计-查看广告账号异常处理情况
export function fetchAccountStatisticsExceptionHandel(params) {
  return request('/api/ad-account/statistics-exception-handel', {
    params,
    method: 'GET',
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export function fetchAccountStatisticsReviewInfo(params) {
  return request('/api/ad-account/statistics-review-info', {
    params,
    method: 'GET',
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
export function fetchAccountStatisticsAccountInfo(params) {
  return request('/api/ad-account/statistics-account-info', {
    params,
    method: 'GET',
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// 获取定制产品订单信息
export function fetchCustomOrder(data) {
  return request('/api/big-data/proxy/getCustomOrderInfo', {
    data,
    method: 'POST',
  });
}
// 团队ROI-按月tab
export function fetchRoiMonthDetail(params) {
  return request('/api/big_data/roi_month_detail', {
    params,
    method: 'GET',
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}
// 团队ROI-按月tab导出
export function exportRoiMonthDetail(params) {
  return request('/api/big_data/roi_month_detail/export', {
    params,
    method: 'GET',
    removeBlankProperty: true,
    paramsSerializer: data => stringify(data, { arrayFormat: 'brackets' }),
  });
}

// sku审核时长明细
export function fetchOperationTimeDetail(data) {
  return request('/api/big-data/proxy/getSkuCheckTime', {
    data,
    method: 'POST',
  });
}

// 统计汇总-操作时长合计
export function fetchOperationTimeTotal(data) {
  return request('/api/big-data/proxy/getPeopleCheckTime', {
    data,
    method: 'POST',
  });
}

// 运营报表 代发仓发货统计 统计
export const fetchIssuingWarehouseDeliverySummary = data => {
  return request('/api/order/delivery-warehouse-statistics/total', {
    data,
    method: 'POST',
    removeBlankProperty: true,
  });
};

// 运营报表 代发仓发货统计
export const fetchIssuingWarehouseDelivery = data => {
  return request('/api/order/delivery-warehouse-statistics', {
    data,
    method: 'POST',
    removeBlankProperty: true,
  });
};

// 运营报表 代发仓发货统计 店铺名称
export const getMabangStoreName = () => {
  return request(`/api/order/delivery-warehouse-statistics/get-mabang-df-stop-name`);
};
