// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import ContainerOutlined from '@ant-design/icons/ContainerOutlined';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import BarsOutlined from '@ant-design/icons/BarsOutlined';
import FundOutlined from '@ant-design/icons/FundOutlined';
import PayCircleOutlined from '@ant-design/icons/PayCircleOutlined';
import GoldOutlined from '@ant-design/icons/GoldOutlined';
import CarryOutOutlined from '@ant-design/icons/CarryOutOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import AccountBookOutlined from '@ant-design/icons/AccountBookOutlined';
import SelectOutlined from '@ant-design/icons/SelectOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import MedicineBoxOutlined from '@ant-design/icons/MedicineBoxOutlined';
import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import WalletOutlined from '@ant-design/icons/WalletOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import CalculatorOutlined from '@ant-design/icons/CalculatorOutlined';
import ExceptionOutlined from '@ant-design/icons/ExceptionOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import BookOutlined from '@ant-design/icons/BookOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import BankOutlined from '@ant-design/icons/BankOutlined';
import ArrowDownOutlined from '@ant-design/icons/ArrowDownOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import ToolOutlined from '@ant-design/icons/ToolOutlined';
import FileImageOutlined from '@ant-design/icons/FileImageOutlined'

export default {
  SmileOutlined,
ContainerOutlined,
UnorderedListOutlined,
BarChartOutlined,
ControlOutlined,
SearchOutlined,
BarsOutlined,
FundOutlined,
PayCircleOutlined,
GoldOutlined,
CarryOutOutlined,
ShopOutlined,
CalendarOutlined,
ProfileOutlined,
AccountBookOutlined,
SelectOutlined,
EditOutlined,
MedicineBoxOutlined,
AppstoreOutlined,
TeamOutlined,
ApartmentOutlined,
FormOutlined,
WalletOutlined,
FileTextOutlined,
CalculatorOutlined,
ExceptionOutlined,
VideoCameraOutlined,
BookOutlined,
NotificationOutlined,
UserOutlined,
BankOutlined,
ArrowDownOutlined,
MessageOutlined,
ToolOutlined,
FileImageOutlined
}
    