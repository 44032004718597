import {
  fetchadAaccountStatistics,
  fetchStatisticsDetails,
  fetchStatisticsTimezone,
} from '@/services/api';

export default {
  namespace: 'advertisingAccount',
  state: {
    data: [],
    detailData: [],
    timezoneTrend: [],
    detailTotal: 0,
  },

  effects: {
    *getAdvertisingAccount({ payload }, { call, put }) {
      const response = yield call(fetchadAaccountStatistics, payload);
      yield put({
        type: 'saveAdvertisingAccount',
        payload: response,
      });
    },
    *getStatisticsDetails({ payload, callback }, { call, put }) {
      const response = yield call(fetchStatisticsDetails, payload);
      yield put({
        type: 'saveStatisticsDetails',
        payload: response,
      });
      if (callback) callback(response);
    },
    *getStatisticsTimezone({ payload, callback }, { call, put }) {
      const response = yield call(fetchStatisticsTimezone, payload);
      yield put({
        type: 'saveStatisticsTimezone',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveAdvertisingAccount(state, action) {
      const { data = [] } = action?.payload;
      return {
        ...state,
        data,
      };
    },
    saveStatisticsDetails(state, action) {
      const { data = [], meta = {} } = action?.payload;
      return {
        ...state,
        detailData: data || [],
        detailTotal: meta?.total || 0,
      };
    },
    saveStatisticsTimezone(state, action) {
      return {
        ...state,
        timezoneTrend: action?.payload?.data || [],
      };
    },
  },
};
