import { fetchProductCheckList, fetchTestUser } from '@/services/sku';

export default {
  namespace: 'productPool',
  state: {
    data: [],
    total: 0,
    alarmData: {},
    userList: [],
  },
  effects: {
    *getProductPoolList({ payload }, { call, put }) {
      const response = yield call(fetchProductCheckList, payload);
      yield put({
        type: 'saveProductPoolList',
        payload: response,
      });
    },
    *fetchTestUser(_, { call, put }) {
      const response = yield call(fetchTestUser);
      yield put({
        type: 'saveUserList',
        payload: response,
      });
    },
  },
  reducers: {
    saveProductPoolList(state, action) {
      const { data = [], total } = action.payload;
      return {
        ...state,
        data,
        total: total || 0,
      };
    },
    saveUserList(state, action) {
      return {
        ...state,
        userList: action.payload,
      };
    },
  },
};
