import request from '@/utils/request';

//产品导入入库人员列表
export async function fetchProductsUserList() {
  return request(`/api/products/user-list`, {
    method: 'GET',
    removeBlankProperty: true,
  });
}

//产品运费试算
export async function calcProductFreight(data) {
  return request(`/api/products/trial/product-freight`, {
    method: 'POST',
    removeBlankProperty: true,
    data,
  });
}

//重量运费试算
export async function calcWeightFreight(params) {
  return request(`/api/products/trial/weight-freight`, {
    removeBlankProperty: true,
    params,
  });
}

// 产品Roi试算
export async function calcProductRoi(data) {
  return request(`/api/products/trial/product-roi`, {
    method: 'POST',
    removeBlankProperty: true,
    data,
  });
}

// 商品运费试算
export async function calcProductCommodityFreight(data) {
  return request(`/api/products/trial/commodity-freight`, {
    method: 'POST',
    removeBlankProperty: true,
    data,
    getResponse: true,
  });
}

// 商品运费试算日志
export async function fetchProductsTrialLogs(params) {
  return request(`/api/products/trial/commodity-freight-logs`, {
    removeBlankProperty: true,
    params,
  });
}
// 下载商品运费试算日志
export async function downloadProductsTrialLogsById(id) {
  return request(`/api/products/trial/commodity-freight-logs/download/${id}`, {
    removeBlankProperty: true,
  });
}

// 获取sku属性
export async function fetchTrialSkuAttribute(data) {
  return request(`/api/products/trial/get-sku-attribute`, {
    method: 'POST',
    removeBlankProperty: true,
    data,
  });
}

// 获取配置更新时间
export async function fetchTrialConfigDate() {
  return request(`/api/products/trial/get-last-config-date`, {});
}

//  获取特殊sku配置
export async function fetchProductsTrialSpecialSkuCongfig() {
  return request(`/api/products/trial/special_sku_config`, {
    removeBlankProperty: true,
  });
}

// 修改特殊sku配置
export async function updateProductsTrialSpecialSkuCongfig(id, data) {
  return request(`/api/products/trial/special_sku_config/${id}`, {
    method: 'PUT',
    data,
  });
}

// 新增特殊sku配置
export async function addProductsTrialSpecialSkuCongfig(data) {
  return request(`/api/products/trial/special_sku_config`, {
    method: 'POST',
    data,
  });
}

// 删除特殊sku配置
export async function deleteProductsTrialSpecialSkuCongfig(id) {
  return request(`/api/products/trial/special_sku_config/${id}`, {
    method: 'DELETE',
  });
}

// 获取SKU成本重量
export function productsTrialSkuInfo(params) {
  return request(`/api/products/trial/sku_info`, {
    params,
  });
}

// 获取SKU的运费配置
export function productsFreightConfig(params) {
  return request(`/api/products/trial/freight-config`, {
    params,
  });
}

// 提交快速上架任务
export async function submitProductFlashLogs(data) {
  return request(`/api/products/flash-logs`, {
    method: 'POST',
    data,
  });
}
//快速上架任务列表
export function fetchProductFlashLogs(params) {
  return request(`/api/products/flash-logs`, {
    params,
  });
}

//快速上架任务详情
export function fetchProductFlashLogsDetail(log) {
  return request(`/api/products/flash-logs/${log}`, {});
}
