/* eslint-disable no-useless-escape */
import moment from 'moment';
import { parse } from 'querystring';
import pathRegexp from 'path-to-regexp';
import { message } from 'antd';
import React from 'react';
import { fetchOssFile } from '@/services/api';
import CustomPagination from '@/components/CustomPagination/index.jsx';
import Enum from './enum';

// 审核异常次数上限
export const auditExceptionTimesMax = 2;

let isResetAndDisabledBlur = false;
const BADE_FORMAT = 'YYYY-MM-DD';
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = path => reg.test(path);
/* export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性 */

export const isAntDesignPro = () => window.location.hostname === 'preview.pro.ant.design';

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};
export const getPageQuery = () => parse(window.location.href.split('?')[1]);
/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */

export const getAuthorityFromRouter = (router = [], pathname) => {
  // const authority = router.find(({ path }) => path && pathRegexp(path).exec(pathname));
  // if (authority) return authority;
  // return undefined;
  // 修复在路由设置权限后，无权限用户仍可通过路由地址访问页面问题
  // 参考https://github.com/ant-design/ant-design-pro/issues/5771
  let authority;
  (function getAuthority(route) {
    route.forEach(item => {
      const { path, routes } = item;
      if (path === pathname) authority = item;
      else if (path && routes && routes.length) {
        getAuthority(routes);
      }
    });
  })(router);

  if (authority) return authority;
  return undefined;
};
export const getRouteAuthority = (path, routeData) => {
  let authorities;
  routeData.forEach(route => {
    // match prefix
    if (pathRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
      if (route.authority) {
        authorities = route.authority;
      } // exact match

      if (route.path === path) {
        authorities = route.authority || authorities;
      } // get children authority recursively

      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });
  return authorities;
};

// 获取过去某一天的日期
export const getDate = day => {
  const date = moment()
    .startOf('day')
    .subtract(day, 'days')
    .format('YYYY-MM-DD');
  return date;
};

// 日期选择器预设选择范围
export const dateRanges = {
  今日: [moment().startOf('day'), moment().endOf('day')],
  昨日: [
    moment()
      .subtract(1, 'days')
      .startOf('day'),
    moment()
      .subtract(1, 'days')
      .endOf('day'),
  ],
  最近5天: [
    moment()
      .startOf('day')
      .subtract(4, 'days'),
    moment().endOf('day'),
  ],
  最近7天: [
    moment()
      .startOf('day')
      .subtract(6, 'days'),
    moment().endOf('day'),
  ],
  最近15天: [
    moment()
      .startOf('day')
      .subtract(14, 'days'),
    moment().endOf('day'),
  ],
  最近30天: [
    moment()
      .startOf('day')
      .subtract(29, 'days'),
    moment().endOf('day'),
  ],
};

export const dateTimeRanges = {
  近1小时: [
    moment()
      .subtract(1, 'hours')
      .startOf('hour'),
    moment().startOf('hour'),
  ],
  近3小时: [
    moment()
      .subtract(3, 'hours')
      .startOf('hour'),
    moment().startOf('hour'),
  ],
  近6小时: [
    moment()
      .subtract(6, 'hours')
      .startOf('hour'),
    moment().startOf('hour'),
  ],
  近12小时: [
    moment()
      .subtract(12, 'hours')
      .startOf('hour'),
    moment().endOf('hour'),
  ],
  近24小时: [
    moment()
      .subtract(24, 'hours')
      .startOf('hour'),
    moment().startOf('hour'),
  ],
  近48小时: [
    moment()
      .subtract(48, 'hours')
      .startOf('hour'),
    moment().startOf('hour'),
  ],
};

/**
 * [过滤对象]
 * @param  obj [过滤前数据]
 * @param  arr [过滤条件，要求为数组]
 */
export const filterObj = (obj, arr) => {
  if (typeof obj !== 'object' || !Array.isArray(arr)) {
    throw new Error('参数格式不正确');
  }
  const result = {};
  Object.keys(obj)
    .filter(key => arr.includes(key))
    .forEach(key => {
      result[key] = obj[key];
    });
  return result;
};
/**
 * 时间范围选择工具
 * @param {*} date 日期
 * @param {*} type 类型 start 或者 end
 * @param {*} format 格式化
 * @param {*} dateType 选择类型 day week hour ...
 */
export const getDateStartOrEnd = (date, type, format, dateType) => {
  if (type === 'end') {
    return moment(date)
      .endOf(dateType || 'day')
      .format(format || 'YYYY-MM-DD HH:mm:ss');
  }

  return moment(date)
    .startOf(dateType || 'day')
    .format(format || 'YYYY-MM-DD HH:mm:ss');
};

// 为filter的select下拉框填充options
export const initFilterOptions = (items, accountOptions) => {
  const filter = [...items];
  filter.map((current, index) => {
    if (current.key === 'account_status' && accountOptions.account_status) {
      const map = { 1: '正常', 2: '禁用', 3: 'UNSETTLED', 999: '未授权' };
      filter[index].options = accountOptions.account_status.map(item => ({
        key: item,
        title: map[item],
      }));
    } else if (current.key === 'currency' && accountOptions.currency_list) {
      filter[index].options = accountOptions.currency_list.map(item => ({
        key: item,
        title: item,
      }));
    } else if (current.key === 'timezone_name' && accountOptions.timezone_list) {
      filter[index].options = accountOptions.timezone_list.map(item => ({
        key: item,
        title: item,
      }));
    } else if (current.key === 'tz_utc_offset' && accountOptions.utc_offset_list) {
      filter[index].options = accountOptions.utc_offset_list.map(item => ({
        key: `${item}`,
        title: item,
      }));
    } else if (current.key === 'part_id' && accountOptions.part_list) {
      accountOptions.part_list.map(item =>
        filter[index].options.push({
          key: item.id,
          title: item.name,
        }),
      );
    } else if (current.key === 'store_id' && accountOptions.store_list) {
      filter[index].options = accountOptions.store_list.map(item => ({
        key: `${item.id}`,
        title: item.domain.replace('.myshopify.com', ''),
      }));
    } else if (current.key === 'username' && accountOptions.username_list) {
      if (current.moreOptions) {
        filter[index].options.push({
          key: -1,
          title: '已分配',
        });
        filter[index].options.push({
          key: -2,
          title: '未分配',
        });
      }
      accountOptions.username_list.forEach(item =>
        filter[index].options.push({
          key: item,
          title: item,
        }),
      );
    } else if (current.key === 'provider' && accountOptions.provider_list) {
      filter[index].options = accountOptions.provider_list.map(item => ({
        key: `${item}`,
        title: item,
      }));
    } else if (current.key === 'plan' && accountOptions.plan_list) {
      filter[index].options = accountOptions.plan_list.map(item => ({
        key: `${item}`,
        title: item,
      }));
    }
    return true;
  });
  return filter;
};

// 从tags中取出筛选条件
export const initCondition = (tags, prePayload, accountOptions) => {
  const format = 'YYYY-MM-DD';
  const payload = { ...prePayload };
  tags.map(item => {
    // 1.autoComplete
    if (['autoComplete', 'input', 'select', 'autoComplete&select'].indexOf(item.type) !== -1) {
      if (item.key === 'account_status') {
        payload[`filter[${item.key}]`] = item.value;
      } else if (item.key === 'part_id') {
        const result =
          accountOptions && accountOptions.part_list
            ? accountOptions.part_list.find(part => item.value === part.name)
            : undefined;
        payload[`filter[${item.key}]`] = result ? result.id : item.value;
      } else {
        payload[`filter[${item.key}]`] = item.value;
      }
    } else if (item.type === 'numRangeSelector') {
      // 区间类型
      payload[`filter[${item.key}]`] = [item.start, item.end];
    } else if (item.type === 'dateRangeSelector') {
      payload[`filter[${item.key}]`] = [
        moment(item.start).format(format),
        moment(item.end).format(format),
      ];
    } else if (item.type === 'radioGroup') {
      payload[`filter[${item.key}]`] = item.value.value;
    }
    return true;
  });
  return payload;
};
/**
 * 判断选择的时间是否在当前时间范围内
 * @param {*} beginDateStr  开始时间
 * @param {*} endDateStr  结束时间
 * @returns
 */
export const isDuringDate = (beginDateStr, endDateStr) => {
  if (!beginDateStr || !endDateStr) {
    throw new Error('参数格式不正确');
  }
  let curDate = moment();
  let beginDate = moment(beginDateStr)
    .startOf('day')
    .isBefore(curDate);
  let endDate = moment(endDateStr)
    .endOf('day')
    .isAfter(curDate);
  if (beginDate && endDate) {
    return true;
  }
  return false;
};
export const roles = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  GROUP_LEADER: 'group_leader',
  GROUP_MEMBER: 'group_member',
  OFFICER: 'officer',
  BC_USER: 'bc_user',
  ASSISTANT: 'assistant',
  VERIFY: 'verify',
  BC_OFFICER: 'bc_officer',
  MANAGER_ADMIN: 'manager_admin',
  MANAGER_PURCHASE: 'manager_purchase',
  MANAGER_ADS_REVIEW: 'manager_ads_review',
  MANAGER_ADMIN_ASSISTANT: 'manager_admin_assistant',
  MANAGER_ADS_REVIEW_PRIVATE: 'manager_ads_review_private',
  MANAGER_ORDERS_LIST: 'manager_orders_list',
  MANAGER_DATA_CHECK: 'manager_data_check',
  GROUP_MEMBER_PRE: 'group_member_pre',
};

// 随机生成指定长度的字符串（大小写字母）
export const generatePwd = length => {
  const arr = [];
  // A-Z 65-90
  // a-z 97-122
  for (let i = 65; i <= 122; i += 1) {
    if (i <= 90 || i >= 97) arr.push(String.fromCharCode(i));
  }
  let pwd = '';
  for (let i = 0; i < length; i += 1) {
    pwd += arr[Math.floor(Math.random() * arr.length)];
  }
  return pwd;
};

// antd 搭配 xlsx 将 excel 解析为 json 字串
export const fileToJson = (fileList, callback, beforeCallback = null) => {
  const rABS = true;
  const f = fileList[0];
  const reader = new FileReader();
  reader.onload = function async(e) {
    let data = e.target.result;
    if (!rABS) data = new Uint8Array(data);
    // 这玩意儿太大了，400kb。改为动态引入
    import('xlsx')
      .then(xlsx => {
        const workbook = xlsx.read(data, {
          type: rABS ? 'binary' : 'array',
        });
        if (beforeCallback) {
          return beforeCallback(workbook);
        }

        const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonArr = xlsx.utils.sheet_to_json(firstWorksheet, { header: 1 });
        callback(jsonArr);
      })
      .catch(err => {
        console.log(err);
      });
  };
  if (rABS) reader.readAsBinaryString(f);
  else reader.readAsArrayBuffer(f);
};
// SKU管理的组合sku中，查找和添加子SKU调用真实sku接口处理返回的数据
export const exportTskuToVsku = tSkus => {
  const vSku = [];
  tSkus.forEach(item => {
    const obj = {};
    obj.t_sku = item.sku;
    obj.title = item.title;
    obj.supply_image = item.supply_image;
    obj.local_image = item.local_image;
    const vskuli = [];
    item.sku_info.forEach(ele => {
      const vObj = {};
      vObj.ts_sku = ele.sku_attr;
      vObj.check_stat = 1;
      vskuli.push(vObj);
    });
    obj.vs_sku_li = vskuli;
    vSku.push(obj);
  });
  return vSku;
};

// 生成uuid
export const generateUuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
//
export const handleDomainName = domain => {
  if (!domain) return '';
  return domain
    .replace('.myshopify.com', '')
    .replace('.wshopon.com', '')
    .replace('.xshoppy.shop', '')
    .replace('.onshopbase.com', '')
    .replace('.frp.codefriend.top', '')
    .replace('.mynodeshop.com', '')
    .replace('.hotishop.com', '')
    .replace('.shopnova.top', '')
    .replace('.funvalue.top', '');
};

export const handleDomainNameThin = domain => {
  if (!domain) return '';
  return domain
    .replace('.myshopify.com', '')
    .replace('.wshopon.com', '')
    .replace('.xshoppy.shop', '')
    .replace('.onshopbase.com', '')
    .replace('.frp.codefriend.top', '')
    .replace('.mynodeshop.com', '')
    .replace('.hotishop.com', '')
    .replace('.shopnova.top', '')
    .replace('.funvalue.top', '')
    .replace('.myshopify', '')
    .replace('.wshopon', '')
    .replace('.xshoppy', '')
    .replace('.onshopbase', '')
    .replace('.frp.codefriend', '')
    .replace('.mynodeshop', '')
    .replace('.hotishop', '')
    .replace('.shopnova', '')
    .replace('.funvalue', '')
    .replace('.mshop', '')
    .replace('.nodeshop', '')
    .replace('.shopbase', '')
    .replace('.shopify', '')
    .replace('.wshop', '');
};

export const storeDomainMap = {
  shopify: 'SP',
  wshop: 'WS',
  shopbase: 'SB',
  xshoppy: 'XS',
  myshopify: 'SP',
  wshopon: 'WS',
  onshopbase: 'SB',
  mynodeshop: 'NS',
  nodeshop: 'NS',
  hotishop: 'MS',
  mshop: 'MS',
  shopnova: 'SN',
  funvalue: 'FV',
};

export const handlePlatform = domain => {
  if (!domain) return '';
  if (domain.includes('.funvalue')) {
    domain = domain
      .replace('.com', '')
      .replace('.shop', '')
      .replace('.top', ''); //eslint-disable-line
  } else {
    domain = domain.replace('.com', '').replace('.top', ''); //eslint-disable-lin
  }
  const index = domain.indexOf('.');
  if (index !== -1) return domain.substring(index + 1, domain.length);
  return '';
};

// 按字符串排序
export const sortByStr = name => (a, b) => {
  const stringA = a[name] && a[name].toUpperCase();
  const stringB = b[name] && b[name].toUpperCase();
  if (stringA < stringB) {
    return -1;
  }
  if (stringA > stringB) {
    return 1;
  }
  return '';
};

// 下载excel
export function saveAs(obj, fileName) {
  const tmpa = document.createElement('a');
  tmpa.download = fileName || '下载';
  tmpa.href = URL.createObjectURL(obj);
  tmpa.click();
  setTimeout(() => {
    URL.revokeObjectURL(obj);
  }, 100);
}
// 转换文件
export function s2ab(s) {
  if (typeof ArrayBuffer !== 'undefined') {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i !== s.length; ++i) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }
  const buf = new Array(s.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i !== s.length; ++i) {
    // eslint-disable-next-line no-bitwise
    buf[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}

// 洗牌算法，打乱数组
Array.prototype.shuffle = function() {
  //eslint-disable-line
  const arr = this;
  let endIndex = arr.length;
  while (endIndex) {
    endIndex -= 1;
    const randomIndex = (Math.random() * endIndex) >> 0; //eslint-disable-line
    // const  randomIndex =  Math.floor(Math.random()*endIndex) //eslint-disable-line
    [arr[endIndex], arr[randomIndex]] = [arr[randomIndex], arr[endIndex]]; //eslint-disable-line
  }
  return arr;
};

// find key by value
// Object.prototype.findKeyByValue = function(value) {
//   return Object.entries(this).find(i => i[1] === value)?.[0];
// };
export const findKeyByValue = (obj, value) => {
  return Object.entries(obj || {}).find(i => i[1] === value)?.[0];
};

/**
 * params: number - 需要的颜色数量（最大120）
 * return: 返回符合antd设计语言的随机颜色列表
 */
export const generateColorList = number => {
  // 1. 随机且不重复
  // 2. 符合Antd 设计语言
  const colorsArr = [
    '#fff1f0',
    '#ffccc7',
    '#ffa39e',
    '#ff7875',
    'var(--ant-error-color)',
    '#f5222d',
    '#cf1322',
    '#a8071a',
    '#820014',
    '#5c0011',
    '#fff2e8',
    '#ffd8bf',
    '#ffbb96',
    '#ff9c6e',
    '#fa541c',
    '#d4380d',
    '#ad2102',
    '#871400',
    '#610b00',
    '#fff7e6',
    '#ffe7ba',
    '#ffd591',
    '#ffc069',
    '#ffa940',
    '#fa8c16',
    '#d46b08',
    '#ad4e00',
    '#873800',
    '#612500',
    '#fffbe6',
    '#fff1b8',
    '#ffe58f',
    '#ffd666',
    '#ffc53d',
    '#faad14',
    '#d48806',
    '#ad6800',
    '#874d00',
    '#613400',
  ];
  if (number > colorsArr.length) return;
  const capturedColors = colorsArr.shuffle().slice(0, number);
  return capturedColors; //eslint-disable-line
};

export const downloadFileByALink = async (url, filename = false) => {
  const executeDownload = aLink => {
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink);
  };

  const aLink = document.createElement('a');
  aLink.style.display = 'none';
  aLink.href = url;

  if (filename) {
    const res = await fetchOssFile(url);
    if (res?.size) {
      aLink.href = window.URL.createObjectURL(res);
      aLink.download = filename;
    }
  }
  executeDownload(aLink);
};

export const ErrorType = [
  {
    permission: 'view_store_change_payment_issues',
    value: '调整店铺收款方式',
    mag: '',
  },
  // {
  //   permission: 'sub_ad_account_or_data_diff',
  //   value: '广告账号/数据差异',
  //   msg:
  //     "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li>运营ID:</li><li>差异广告账号ID:</li><li>差异时间:</li><li>系统花费:</li><li>实际花费:</li><li>问题描述:</li></ul></p>",
  // },
  // {
  //   permission: 'sub_store_account_or_data_diff',
  //   value: '店铺账号/数据差异',
  //   msg:
  //     "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li>运营ID:</li><li>差异店铺名称:</li><li>差异时间:</li><li>核对时区:</li><li>系统出单:</li><li>实际出单:</li><li>问题描述:</li></ul></p>",
  // },
  {
    permission: 'sub_order_or_data_diff',
    value: '订单详情/数据差异',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li>差异（订单ID,邮箱,交易号,账单号）:</li><li>问题描述（请详细描述差异点）:</li></ul></p>",
  },
  {
    permission: 'sub_bm_is_blocked',
    value: 'BM被封',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'bm_application',
    value: 'BM申请',
    msg: '',
  },
  {
    permission: 'sub_page_rating_appeal',
    value: 'page评分申诉',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID:</li><li>Page ID:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_ad_disapproval_appeal',
    value: '广告问题处理',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID:</li><li>Account ID:</li><li>拒登帖子id（如有多个相同素材被拒，写一个帖子id即可，并在问题描述里说明下是什么产品）</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_ad_account_appeal',
    value: '广告账号申诉',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID:</li><li>Account ID:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_homepage_ban_appeal',
    value: '主页问题处理',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_account_allocation_abnormal',
    value: '账号分配异常',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID:</li><li>FB个人号邮箱:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_page_allocation_exception',
    value: 'page分配异常',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID：</li><li>FB个人号邮箱:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_pixel_allocation_abnormal',
    value: '像素分配异常',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID:</li><li>FB个人号邮箱:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_pixel_data_abnormal',
    value: '广告账户/像素数据异常',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >店铺名称:</li><li>像素ID:</li><li>广告账号:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_pixel_data_abnormal',
    value: '广告花费异常问题',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >店铺名称:</li><li>像素ID:</li><li>广告账号:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'sub_browser_tool_exception',
    value: '浏览器工具异常',
    msg: '<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p>问题描述:</p>',
  },

  {
    permission: 'sub_system_exception',
    value: '系统异常',
    msg: '<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p>问题描述</p>',
  },
  {
    permission: 'sub_change_authority',
    value: '资产授权管理',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>变更权限:</li><li>期望行为:</li><li>变更原因:</li></ul></p>",
  },

  {
    permission: 'sub_shop_problem',
    value: '店铺问题',
    msg: '',
  },

  {
    permission: 'sub_personal_account_ban_replacement',
    value: '个人号问题处理',
    msg: '<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p>问题描述</p>',
  },

  {
    permission: 'sub_ins_binding',
    value: 'INS绑定',
    msg: '',
  },
  {
    permission: 'sub_asset_transfer',
    value: '资产转移',
    msg: '',
  },
  {
    permission: 'sub_asset_auth_failed',
    value: '资产授权失败',
    msg: '',
  },
  {
    permission: 'sub_pixel_domain_settings',
    value: '像素设置处理',
    msg: '',
  },
  {
    permission: 'sub_suggestion',
    value: '建议反馈',
    msg: '',
  },
  {
    permission: 'apply_new_store_processing',
    value: '新店铺申请',
    msg: '',
  },
  {
    permission: 'domain_bind_processing',
    value: '域名绑定',
    msg: '',
  },
  {
    permission: 'credit_card_audit_processing',
    value: '信用卡审核',
    msg: '',
  },
  {
    permission: 'oldstore_trans_authorize_processing',
    value: '老店铺转移/店铺授权',
    msg: '',
  },
  {
    permission: 'sub_pixel_token',
    value: '获取像素token',
    msg: '',
  },
  {
    permission: 'sub_other',
    value: '其他',
    msg:
      "<p><p>以下信息请尽可能补充完整，有助于快速处理工单。</p><ul style='list-style:none;padding:20px'><li >运营ID:</li><li>BM ID:</li><li>VPS ID:</li><li>问题描述:</li></ul></p>",
  },
  {
    permission: 'network_issues_processing',
    value: '网络异常',
    msg: '',
  },
  {
    permission: 'tt_issues_processing',
    value: 'TT问题',
    msg: '',
  },
  {
    permission: 'klarna_pay_apply_processing',
    value: '本地支付申请',
    msg: '',
  },
  {
    permission: 'store_sharing_processing',
    value: '店铺共享',
    msg: '',
  },
];

export const limitDateWithin31Days = (start, end) => {
  if (!start || !end) return true;
  const dateRange = moment(end.format('YYYY-MM-DD')).diff(start.format('YYYY-MM-DD'), 'day', true);
  if (Math.abs(dateRange) > 31) {
    return false;
  }
  return true;
};

export const limitDateWithinDays = (start, end, day) => {
  if (!start || !end) return true;
  const dateRange = moment(end.format('YYYY-MM-DD')).diff(start.format('YYYY-MM-DD'), 'day', true);
  if (Math.abs(dateRange) > day) {
    return false;
  }
  return true;
};

export const limitDateWithin4Month = (start, end) => {
  if (!start || !end) return true;
  const dateRange = moment(end.format('YYYY-MM-DD')).diff(
    start.format('YYYY-MM-DD'),
    'months',
    true,
  );
  if (Math.abs(dateRange) > 4) {
    return false;
  }
  return true;
};

export const limitDateWithinYear = (start, end) => {
  if (!start || !end) return true;
  const dateRange = moment(end.format('YYYY-MM-DD')).diff(
    start.format('YYYY-MM-DD'),
    'months',
    true,
  );
  if (Math.abs(dateRange) > 12) {
    return false;
  }
  return true;
};

const isDateRangeInMonth = (dates = [], month) => {
  const [start, end] = dates;
  const monthOffset = Math.abs(
    moment(start.format('YYYY-MM-DD')).diff(end.format('YYYY-MM-DD'), 'months', true),
  );
  return monthOffset <= month;
};

const isDateRangeInDay = (dates = [], day = 7) => {
  const [start, end] = dates;
  const monthOffset = Math.abs(
    moment(start.format('YYYY-MM-DD'))
      .startOf('day')
      .diff(moment(end.format('YYYY-MM-DD')).startOf('day'), 'days', true),
  );
  return monthOffset <= day - 1;
};

export const disabledDateController = {
  dates: [],
  disabledDate: (current, month) => {
    const isDisableTo4Months = () => {
      const { dates } = disabledDateController;
      if (!dates || dates.length === 0) {
        return false;
      }
      const tooLate = dates[0] && !isDateRangeInMonth([dates[0], current], month || 4);
      const tooEarly = dates[1] && !isDateRangeInMonth([dates[1], current], month || 4);
      return tooEarly || tooLate;
    };
    const isDisableToAfterToday = current && current > moment().endOf('day');
    if (!current) {
      return isDisableToAfterToday;
    }
    return isDisableTo4Months() || isDisableToAfterToday;
  },
  disabledDateForDay: (current, day) => {
    const isDisableTo4Months = () => {
      const { dates } = disabledDateController;
      if (!dates || dates.length === 0) {
        return false;
      }
      const tooLate = dates[0] && !isDateRangeInDay([dates[0], current], day || 7);
      const tooEarly = dates[1] && !isDateRangeInDay([dates[1], current], day || 7);
      return tooEarly || tooLate;
    };
    const isDisableToAfterToday = current && current > moment().endOf('day');
    if (!current) {
      return isDisableToAfterToday;
    }
    return isDisableTo4Months() || isDisableToAfterToday;
  },
  disabledDateForYear: (current, month) => {
    const isDisableToYear = () => {
      const { dates } = disabledDateController;
      if (!dates || dates.length === 0) {
        return false;
      }
      const tooLate = dates[0] && !isDateRangeInMonth([dates[0], current], month || 12);
      const tooEarly = dates[1] && !isDateRangeInMonth([dates[1], current], month || 12);
      return tooEarly || tooLate;
    };
    const isDisableToAfterToday = current && current > moment().endOf('day');
    if (!current) {
      return isDisableToAfterToday;
    }
    return isDisableToYear() || isDisableToAfterToday;
  },
  onCalendarChange: dates => {
    disabledDateController.dates = dates;
  },
  onOpenChange: () => {
    disabledDateController.dates = [];
  },
};

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledDateController24 = {
  dates: [],
  disabledTime24: (current, b, c) => {
    const { dates = [] } = disabledDateController24;
    const timetooLate =
      dates?.[0] &&
      moment(dates?.[0])
        .endOf('day')
        .isBefore(moment(current).endOf('day'));
    const timetooEarly =
      dates?.[1] &&
      moment(dates?.[1])
        .endOf('day')
        .isAfter(moment(current).endOf('day'));
    if (timetooLate) {
      const currentHour = Number(moment(dates?.[0]).format('HH'));
      return {
        disabledHours: () => range(0, 24).splice(currentHour, 24 - currentHour),
      };
    }

    if (timetooEarly) {
      const currentHour = Number(moment(dates?.[1]).format('HH'));
      return {
        disabledHours: () => range(0, 24).splice(0, currentHour + 1),
      };
    }
  },
  disabledDate24: current => {
    const isDisableTo24Hours = () => {
      const { dates } = disabledDateController24;
      if (!dates || dates.length === 0) {
        return false;
      }
      const tooLate =
        dates[0] &&
        moment(dates[0])
          .subtract(-1, 'day')
          .endOf('day')
          .isBefore(moment(current).endOf('day'));
      const tooEarly =
        dates[1] &&
        moment(dates[1])
          .subtract(1, 'day')
          .endOf('day')
          .isAfter(moment(current).endOf('day'));
      return tooEarly || tooLate;
    };
    const isDisableToAfterToday = current && current > moment().endOf('day');

    if (!current) {
      return isDisableToAfterToday;
    }
    return isDisableTo24Hours() || isDisableToAfterToday;
  },
  onCalendarChange24: (dates, dateStrings, info) => {
    if (info.range === 'start') disabledDateController24.dates = [dates[0], null];
    else if (info.range === 'end') disabledDateController24.dates = [null, dates[1]];
  },
  onOpenChange24: () => {
    disabledDateController24.dates = [];
  },
};

export const disabledDateController48 = {
  dates: [],
  disabledDate48: current => {
    const isDisableTo48Hours = () => {
      const { dates } = disabledDateController48;
      if (!dates || dates.length === 0) {
        return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], 'hours') > 48;
      const tooEarly = dates[1] && dates[1]?.diff(current, 'hours') > 48;
      return tooEarly || tooLate;
    };
    const isDisableToAfterToday = current && current > moment().endOf('day');

    if (!current) {
      return isDisableToAfterToday;
    }
    return isDisableTo48Hours() || isDisableToAfterToday;
  },
  onCalendarChange48: dates => {
    disabledDateController48.dates = dates;
  },
  onOpenChange48: () => {
    disabledDateController48.dates = [];
  },
};

export const handleCopy = (val, tip = '') => {
  const createInput = document.createElement('input');
  createInput.value = val || '';
  document.body.appendChild(createInput);
  createInput.select();
  document.execCommand('Copy');
  createInput.style.display = 'none';
  message.success(tip || '复制成功');
};

export const getFileType = filename => {
  const startIndex = filename.lastIndexOf('.');
  if (startIndex !== -1) return filename.substring(startIndex + 1, filename.length).toLowerCase();
  return '';
};

// 解决失去焦点和重置事件先后触发导致页面出现抖动
// 由重置事件调用
export const actionSender = () => {
  isResetAndDisabledBlur = true;
};
// 由失去焦点事件调用
export const actionListener = async () => {
  const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));
  await sleep(100);
  const result = isResetAndDisabledBlur;
  isResetAndDisabledBlur = false;
  return !result;
};

// 关于时间筛选框，用户只选择一个时间（直接点击筛选框外），且和原来的时间的范围可能超过规定的时间范围时，校正时间
export const norDate = (value, oldValue) => {
  const dates = value.map((v, i) => {
    if (i < 1) {
      return v ? v.startOf('day') : v;
    }
    return v ? v.endOf('day') : v;
  });
  if (dates) {
    if (moment(dates[0].format(BADE_FORMAT)).isSame(oldValue[0].format(BADE_FORMAT))) {
      dates[0] = moment(dates[1].format(BADE_FORMAT)).subtract(4, 'months');
    } else {
      dates[1] = moment(dates[0].format(BADE_FORMAT)).subtract(-4, 'months');
    }
  }
  return dates;
};

export const norDateWithYear = (value, oldValue) => {
  const dates = value.map((v, i) => {
    if (i < 1) {
      return v ? v.startOf('day') : v;
    }
    return v ? v.endOf('day') : v;
  });
  if (dates) {
    if (moment(dates[0].format(BADE_FORMAT)).isSame(oldValue[0].format(BADE_FORMAT))) {
      dates[0] = moment(dates[1].format(BADE_FORMAT)).subtract(12, 'months');
    } else {
      dates[1] = moment(dates[0].format(BADE_FORMAT)).subtract(-12, 'months');
    }
  }
  return dates;
};

// 数据格式化

export const formatValue = (value, type) => {
  // 保留两位小数并添加千位符
  if (type === 'money') {
    return (
      Number(value || 0)?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || 0
    );
  }
  // 只添加千位符
  return Number(value || 0)?.toLocaleString() || 0;
};

export const AdviceType = [
  {
    permission: 'view_suggest_bi_system',
    key: 1,
    value: 'bi系统',
  },
  // {
  //   permission: 'view_suggest_website',
  //   key: 2,
  //   value: '网站',
  // },
  {
    permission: 'view_suggest_purchase',
    key: 3,
    value: '采购',
  },
  {
    permission: 'view_suggest_account',
    key: 4,
    value: '账户',
  },
  {
    permission: 'view_suggest_logistics',
    key: 5,
    value: '物流',
  },
  {
    permission: 'view_suggest_product',
    key: 6,
    value: '产品',
  },
  {
    permission: 'view_suggest_daily_affairs',
    key: 7,
    value: '日常事务',
  },
  {
    permission: 'view_suggest_customer_service',
    key: 8,
    value: '客服',
  },
];

export const symbolConversion = value => {
  if (value) return encodeURIComponent(value);
  return '';
};

export const dateFunc = (value, format) => {
  let date = '';
  let [start, end] = value ?? [];
  if (start && end) {
    start = moment(start).format(format ?? 'YYYY-MM-DD');
    end = moment(end).format(format ?? 'YYYY-MM-DD');
    date = `${start},${end}`;
  }
  return date;
};
export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

// 给表格顶部增加滚动条，解决列数过多需要滑动到底部查看全部列的限制
// 顶部的滚动条滚到到最右边与底下的滚动条相差17px
export const bindScroll = dom => {
  const OFFSET = 800;
  const scroller = document.createElement('div');
  scroller.appendChild(document.createElement('div'));

  scroller.children[0].style.width = `${OFFSET +
    Number(dom.children[0].style.width.replace('px', ''))}px`;
  scroller.children[0].style.height = '1px';
  scroller.style['overflow-x'] = 'scroll';
  // scroller.style.position = 'relative';
  // scroller.style.top = `-${dom.children[0].offsetHeight}px`;
  // scroller.style.zIndex = 999;
  dom.parentElement.insertBefore(scroller, dom);
  // dom.parentNode.insertBefore(scroller, dom.nextSibling)
  dom.addEventListener('scroll', function() {
    scroller.scrollLeft = dom.scrollLeft;
  });
  scroller.addEventListener('scroll', function() {
    dom.scrollLeft = scroller.scrollLeft; // eslint-disable-line
  });
};

// 生成至少含有一个大写，一个小写及数字的范围位数的随机密码
export const randomPwd = (min, max) => {
  const passArrItem = [];

  // 定义获取密码成员的方法
  const getNumber = () => Math.floor(Math.random() * 10); // 0~9的数字
  const getUpLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65); // A-Z
  const getLowLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 97); // a-z

  // 将获取成员的方法保存在一个数组中方便用后面生成的随机index取用
  const passMethodArr = [getNumber, getUpLetter, getLowLetter];

  // 随机index
  const getIndex = () => Math.floor(Math.random() * 3);

  // 从0-9，a-z，A-Z中随机获取一项
  const getPassItem = () => passMethodArr[getIndex()]();

  Array(Math.round(Math.random() * (max - min)) + min - 3)
    .fill('')
    .forEach(() => {
      passArrItem.push(getPassItem());
    });

  const confirmItem = [getNumber(), getUpLetter(), getLowLetter()];

  // 加上我们确认的三项，从而使生成的密码，大写字母、小写字母和数字至少各包含一个
  passArrItem.push(...confirmItem);

  // 转为字符串返回
  return passArrItem.join('');
};

// 去重去空
export const filterItem = (arr, key) => {
  const obj = {};
  const newArr =
    arr?.reduce?.((item, next) => {
      if (!obj[next[key]]) {
        obj[next[key]] = true;
        item.push(next);
      }

      return item;
    }, []) ?? [];
  return newArr.filter(i => i[key]?.trim?.());
};

export const arrayUnion = (arr, key) => {
  const obj = {};
  const newArr = arr.reduce((item, next) => {
    if (!obj[next[key]]) {
      obj[next[key]] = true;
      item.push(next);
    }

    return item;
  }, []);
  return newArr;
};

export const getRandomPwd = (min, max) => {
  const passArrItem = [];

  // 定义获取密码成员的方法
  const code = [
    '!',
    '@',
    '#',
    '$',
    '%',
    '&',
    '*',
    '(',
    ')',
    '+',
    '`',
    '~',
    '^',
    '_',
    '-',
    '=',
    '[',
    ']',
    '{',
    '}',
    '|',
    ':',
    ';',
    '"',
    "'",
    '<',
    '>',
    ',',
    '.',
    '?',
    '/',
  ];
  const getNumber = () => Math.floor(Math.random() * 10); // 0~9的数字
  const getLowLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 97); // a-z
  const getUpLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65); // A-Z
  const getSymbol = () => {
    let newCode = '';
    for (let i = 0; i < 1; i += 1) {
      const x = Math.floor(Math.random() * code.length);
      newCode += code[x];
    }
    return newCode;
  };

  // 将获取成员的方法保存在一个数组中方便用后面生成的随机index取用
  const passMethodArr = [getNumber, getUpLetter, getLowLetter, getSymbol()];

  // 随机index
  const getIndex = () => Math.floor(Math.random() * 3);

  // 从0-9，a-z，A-Z中随机获取一项
  const getPassItem = () => passMethodArr[getIndex()]();

  Array(Math.round(Math.random() * (max - min)) + min - 3)
    .fill('')
    .forEach(() => {
      passArrItem.push(getPassItem());
    });

  const confirmItem = [getNumber(), getUpLetter(), getLowLetter(), getSymbol()];

  // 加上我们确认的三项，从而使生成的密码，大写字母、小写字母和数字,符号至少各包含一个
  passArrItem.push(...confirmItem);

  // 转为字符串返回
  return passArrItem.join('');
};

// export const setPoster = (video, scale) => {
//   const realScale = scale || 0.8
//   // 设置poster属性：（非本地视频资源会有跨域截图问题）
//   // video.setAttribute('crossOrigin', 'anonymous');//处理跨域
//   video.addEventListener('loadeddata', function (e) {
//     // 截取视频第一帧
//     const canvas = document.createElement('canvas');
//     canvas.width = video.videoWidth * realScale;
//     canvas.height = video.videoHeight * realScale;
//     canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
//     const src = canvas.toDataURL('image/png');
//     // 设置属性
//     video.setAttribute('poster', src);
//   });
// };

export const getVideoBase64 = (ele, url) => {
  return new Promise(function(resolve) {
    let dataURL = '';
    // const video = document.createElement("video");
    const video = ele;
    video.setAttribute('crossOrigin', 'anonymous'); // 处理跨域
    video.setAttribute('src', url);
    video.setAttribute('width', 400);
    video.setAttribute('height', 240);
    video.setAttribute('preload', 'auto');
    video.addEventListener('loadeddata', function() {
      const canvas = document.createElement('canvas');
      const { width } = video; // canvas的尺寸和图片一样
      const { height } = video;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(video, 0, 0, width, height); // 绘制canvas
      dataURL = canvas.toDataURL('image/jpeg'); // 转换为base64
      resolve(dataURL);
    });
  });
};

// 前端导出处理column关联表格列
export const getColumnChartCode = item => {
  const sheetCode = [];

  for (let j = 0; j < item.length; j += 1) {
    const str = 'A';
    sheetCode.push({
      code:
        j > 25
          ? `${str}${String.fromCharCode(str.charCodeAt() + (j - 26))}`
          : String.fromCharCode(str.charCodeAt() + j),
      value: item[j].key,
      label: item[j]?.label || item[j]?.title,
    });
  }

  return sheetCode;
};
export const countBonus = orderNum => {
  let bonus = 0;
  if (orderNum >= 100 && orderNum < 200) {
    bonus = orderNum * 0.5;
  } else if (orderNum >= 200 && orderNum < 500) {
    bonus = orderNum * 1;
  } else if (orderNum >= 500 && orderNum < 1000) {
    bonus = orderNum * 2;
  } else if (orderNum >= 1000) {
    bonus = orderNum * 3;
  } else {
    bonus = 0;
  }
  return bonus;
};

export const getFullDomainNode = (domain, displayDomain, isToFront) => {
  const suffix = isToFront ? '' : '/admin';
  const realUrlDomain = domain
    ? `//${domain.replace('https://', '').replace('http://', '')}${suffix}`
    : '';
  return (
    <a type="link" href={realUrlDomain} target="_blank" rel="noopener noreferrer">
      {displayDomain}
    </a>
  );
};
export const getPagination = (
  totalCount,
  pageLoading,
  currentPage,
  pageNumber,
  handlePageChange,
) => {
  const pagination = () => (
    <CustomPagination
      total={totalCount}
      loading={pageLoading}
      currentPage={Number(currentPage)}
      pageSize={pageNumber}
      options={['10', '30', '50', '100', '200', '500']}
      handlePageChange={handlePageChange}
      handleSizeChange={handlePageChange}
    />
  );
  return pagination();
};

export const calculateShowWeekNumber = (data, x, isGroupByDay) => {
  const firstDayOfWeek = moment(data[0]?.[x]).day();
  const tempDays = data.reduce((prev, current) => ({ ...prev, [current[x]]: true }), {});
  const days = Object.keys(tempDays);
  if (days.length > 10 && days.length < 13 && firstDayOfWeek === 3) return 0;

  const daysOfWeek = data.reduce((prev, current) => {
    if (!isGroupByDay) return prev;
    const dayNumber = moment(current[x]).day();
    return { ...prev, [dayNumber]: true };
  }, {});
  const tempDayArray = Object.keys(daysOfWeek).map(i => Number(i));
  return tempDayArray.find(i => i === 1) || tempDayArray.shift();
};

// 日期选择器预设选择范围
export const dateRangesWeek = {
  最近2周: [
    moment()
      .subtract(7, 'days')
      .weekday(1),
    moment().endOf('day'),
  ],
  最近4周: [
    moment()
      .subtract(21, 'days')
      .weekday(1),
    moment().endOf('day'),
  ],
  最近8周: [
    moment()
      .subtract(49, 'days')
      .weekday(1),
    moment().endOf('day'),
  ],
  最近10周: [
    moment()
      .subtract(63, 'days')
      .weekday(1),
    moment().endOf('day'),
  ],
  最近12周: [
    moment()
      .subtract(77, 'days')
      .weekday(1),
    moment().endOf('day'),
  ],
  最近14周: [
    moment()
      .subtract(91, 'days')
      .weekday(1),
    moment().endOf('day'),
  ],
};

export const dateRangesMonth = {
  最近1月: [moment().startOf('month'), moment().endOf('day')],
  最近2月: [
    moment()
      .subtract(1, 'months')
      .startOf('month'),
    moment().endOf('day'),
  ],
  最近4月: [
    moment()
      .subtract(3, 'months')
      .startOf('month'),
    moment().endOf('day'),
  ],
  最近6月: [
    moment()
      .subtract(5, 'months')
      .startOf('month'),
    moment().endOf('day'),
  ],
  最近8月: [
    moment()
      .subtract(7, 'months')
      .startOf('month'),
    moment().endOf('day'),
  ],
};

export const dateRangesWeeks = Object.entries({
  本周: [moment().startOf('week'), moment().endOf('week')],
  上周: [
    moment()
      .subtract(1, 'weeks')
      .startOf('week'),
    moment()
      .subtract(1, 'weeks')
      .endOf('week'),
  ],
  过去4周: [
    moment()
      .subtract(3, 'weeks')
      .startOf('week'),
    moment().endOf('week'),
  ],
  过去8周: [
    moment()
      .subtract(7, 'weeks')
      .startOf('week'),
    moment().endOf('week'),
  ],
  过去12周: [
    moment()
      .subtract(11, 'weeks')
      .startOf('week'),
    moment().endOf('week'),
  ],
}).reduce((pre, [key, value]) => {
  return { ...pre, [key]: [value[0].add(1, 'day'), value[1].add(1, 'day')] };
}, {});

//下载blob
export const downloadBlobToFile = (fileBlob, fileName) => {
  const aLink = document.createElement('a');
  aLink.setAttribute('download', fileName || '中控-文件');
  aLink.style.display = 'none';
  aLink.href = window.URL.createObjectURL(fileBlob);
  document.body.appendChild(aLink);
  aLink.click();
  document.body.removeChild(aLink);
};
// 判断日期是否在一定范围内
export function dateBetween(needle, [start, end], dateFormat = 'YYYY-MM-DD') {
  const new_date = new Date(needle);
  if (
    new_date >= new Date(moment(start).format(dateFormat)) &&
    new_date <= new Date(moment(end).format(dateFormat))
  )
    return true;
  return false;
}

export const arrayUniqueBy = (arr, key) =>
  [...new Set(arr.map(i => i[key]))].map(i => ({ label: i, value: i }));

export const removeBlankProperty = obj => {
  Object.keys(obj).forEach(item => {
    if (obj[item]?.constructor === Object) {
      removeBlankProperty(obj[item]);
    }
    if (
      ['', undefined, null].includes(obj[item]) ||
      ['{}', '[]'].includes(JSON.stringify(obj[item]))
    ) {
      delete obj[item];
    }
  });

  return obj;
};

/**
 * INPUT: {
    order: 'descend',
    columnKey: 'today_order_quantity',
  }
  OUTPUT: -today_order_quantity
 * @param {*} sort
 * @returns string | undefined
 */
export const getSortQueryString = sort => {
  const SortEnum = new Enum({
    descend: '-',
    ascend: '',
  });
  if (!sort || !sort.order || !sort.columnKey) return;
  return SortEnum[sort.order] + sort.columnKey;
};

/**
 * INPUT: [{title: 'A', dataIndex: 'a'}], [a:{show: false}]
 * OUTPUT []
 */
export const getCurrentVisibleFields = (columns, columnsState) => {
  if (columnsState.length === 0) return columns.map(i => i.dataIndex ?? i.key);
  return columns
    .filter(i => {
      if (!columnsState?.[i.dataIndex]) return true;
      return columnsState[i.dataIndex]?.show !== false;
    })
    .map(i => i.dataIndex ?? i.key);
};

/**
 * Starting with two slashes when it's missing protocol.
 *
 * 为缺失协议的Url拼接前缀: '//'
 *
 * @see https://stackoverflow.com/questions/4071117/uri-starting-with-two-slashes-how-do-they-behave
 * @example
 * // return '//a.test'
 * addTwoSlashesForMissingProtocolUrl('a.test')
 * @example
 * // return 'http://a.test'
 * addTwoSlashesForMissingProtocolUrl('http://a.test')
 * @example
 * // return 'https://a.test'
 * addTwoSlashesForMissingProtocolUrl('https://a.test')
 *
 * @param {string} url
 * @returns {string} url
 */
export const addTwoSlashesForMissingProtocolUrl = url => {
  return url.replace(/^(http(|s):\/\/|.)/, (match, _, p2) => {
    if (p2 === undefined) return '//' + match;
    return match;
  });
};

export const convertArrayParamsToString = params => {
  const result = Object.entries(params).reduce((pre, [key, value]) => {
    Array.isArray(value) ? (pre[key] = value.toString()) : (pre[key] = value);
    return pre;
  }, {});
  return result;
};

// 导出字段select[]
export const getCurrentVisibleFieldsWithOrder = (tableColumns, _columnsState) => {
  let afterSortColumnKeys = [];
  let orderList = [];
  let normal;
  let isSort = false;
  Object.entries(_columnsState).forEach(([key, column], index) => {
    isSort = !isNaN(Number(column?.order)) || isSort;
    if (isNaN(Number(key)))
      orderList.push({
        key,
        show: column?.show,
        order: isNaN(Number(column?.order))
          ? tableColumns.findIndex(i => i.dataIndex === key)
          : column?.order,
        fixed: column?.fixed,
      });
  });
  const sortColumn = arr => arr.sort((a, b) => a.order - b.order);

  const sortKeys = [...orderList.map(i => i.key)];
  // 某些情况(仅排序操作)后，新增字段不会记入columnsState，做特殊处理
  const unSortColumns = tableColumns.filter(
    i => !sortKeys.includes(i.key) && !sortKeys.includes(i.dataIndex),
  );

  orderList = orderList
    .filter(order => order?.show !== false)
    .filter(i => tableColumns.find(j => j.dataIndex === i.key || j.key === i.key));

  const fixedLeft = sortColumn(orderList.filter(i => i.fixed === 'left'));
  const fixedRight = sortColumn(orderList.filter(i => i.fixed === 'right'));
  const fixedList = [].concat(fixedLeft, fixedRight);

  const columnKey = getCurrentVisibleFields(tableColumns, _columnsState); // ['a','b']
  if (isSort) {
    normal = sortColumn(orderList.filter(i => !fixedList.includes(i)));
    // antd ProTable，为排序字段会插在这个位置
    normal = [normal[0], ...unSortColumns.map(i => i.key || i.dataIndex), ...normal.splice(1)];
  } else {
    const filterArr = fixedList.map(i => i.key);
    normal = sortColumn(columnKey.filter(i => !filterArr.includes(i)));
  }

  afterSortColumnKeys = [...fixedLeft, ...normal, ...fixedRight].map(i => i.key || i);

  return afterSortColumnKeys;
};

/**
 * 缓存函数: 用来跟dva搭配做数据缓存
 *
 * @params
 * callable api接口函数
 * @params
 * options 配置 {expired_at:缓存时间 秒为单位 }
 * @params
 * memorizeFn.refresh 强制刷新缓存
 *
 * @example
 * returen function(){}
 * memorizeFn('store',()=>[1,2,3],{expired_at:60*30})
 *
 */
export const memorizeFn = (callable, { expired_at }) => {
  let cache = {};
  const newFunction = async (...args) => {
    let cacheKey = args.filter(i => i).length > 0 ? JSON.stringify(args) : 'default';
    const cacheExpired =
      Object.keys(cache).length === 0 ||
      cache[cacheKey]?.updated_at?.getTime() < new Date().getTime() - expired_at * 1000;
    if (cacheExpired || !cache[cacheKey]) {
      // refresh data
      const data = await callable(...args);
      cache[cacheKey] = {
        updated_at: new Date(),
        expired_at,
        data,
      };
      return data;
    }
    return cache[cacheKey].data;
  };
  newFunction.refresh = () => {
    cache = {};
  };
  return newFunction;
};

export function arrayUniqueByKey(array, key) {
  return [...new Map(array.map(item => [item[key], item])).values()];
}

/**
 * @params
 * arr 源数据组
 * @params
 * values 要查找的数组 ex[xql] or [[xql,xqp1],[011]]
 * @params
 * key 某个key
 *
 */
export function findIdsByKey(arr, values = [], key) {
  const ids = values
    ?.reduce((total, current) => {
      let p = [];
      if (current.split(',').length > 1) {
        current.split(',').forEach(el => {
          p.push(findIdsByKey(arr, [el], key));
        });
      } else if (arr.findIndex(a => a[key] === current) > -1) {
        p = [arr.find(a => a[key] === current).id];
      }
      total.push(...p);
      return total;
    }, [])
    .map(id => (Array.isArray(id) ? id[0] : id))
    ?.filter(f => !!f);

  return ids;
}

export function findValueByKey(arr, values = [], key, value = 'id') {
  const ids = values
    ?.reduce((total, current) => {
      let p = [];
      if (current.split(',').length > 1) {
        current.split(',').forEach(el => {
          p.push(findIdsByKey(arr, [el], key));
        });
      } else if (arr.findIndex(a => a[key] === current) > -1) {
        p = [arr.find(a => a[key] === current)[value]];
      }
      total.push(...p);
      return total;
    }, [])
    .map(id => (Array.isArray(id) ? id[0] : id))
    ?.filter(f => !!f);

  return ids;
}
// 去除 *xlo*
export function removeInvaildValue(arr) {
  const ids = arr?.reduce((total, current) => {
    const list = current.split(',');
    if (list.length > 1) {
      list.pop();
    }
    total.push(...list);
    return total;
  }, []);
  return ids;
}

export const handleDownLoadImage = file => {
  const image = new Image();
  image.src = file?.url || file?.response?.src;
  image.setAttribute('crossOrigin', 'anonymous');
  image.onload = function() {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, image.width, image.height);
    const url = canvas.toDataURL('image/png');
    const a = document.createElement('a');
    a.download = file.name || '中控-图片';
    a.href = url;
    a.click();
  };
};
/**
 * 随机生成十六进制颜色
 */
export const randomHexColor = () => {
  var hex = Math.floor(Math.random() * 16777216).toString(16);
  //生成ffffff以内16进制数
  while (hex.length < 6) {
    //while循环判断hex位数，少于6位前面加0凑够6位
    hex = '0' + hex;
  }
  return '#' + hex; //返回‘#'开头16进制颜色
};

export default class OperatorEnum {
  static EQUAL = '=';
  static GREATER_OR_EQUAL_TO = '>=';
  static LESS_THAN_OR_EQUAL_TO = '<=';
  static INTERVAL = '区间';
  static GREATER = '>';
  static LESS = '<';
}

export const getOperatorEnumValueByMinMaxStandard = (item, minimumUnit) => {
  if ([OperatorEnum.INTERVAL].includes(item.operation)) {
    return {
      gte: item.start,
      lte: item?.end || item?.start,
    };
  } else if ([OperatorEnum.EQUAL].includes(item.operation)) {
    return {
      gte: item.start,
      lte: item.start,
    };
  } else if ([OperatorEnum.GREATER, OperatorEnum.GREATER_OR_EQUAL_TO].includes(item.operation)) {
    return {
      gte: item.operation === OperatorEnum.GREATER ? item.start + minimumUnit : item.start,
    };
  } else {
    return {
      lte: item.operation === OperatorEnum.LESS ? item?.start - minimumUnit : item?.start,
    };
  }
};
// 富文本转纯文本
export const convertToPlainText = html => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || '';
};
