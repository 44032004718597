import { fetchPPFamily } from '@/services/paypal';
import { sortByStr } from '@/utils/utils';

export default {
  namespace: 'ppFamily',
  state: {
    ppFamilyList: [],
  },

  effects: {
    *getPPFamily(_, { call, put }) {
      const response = yield call(fetchPPFamily);
      yield put({
        type: 'savePPFamily',
        payload: response?.data ?? [],
      });
    },
  },
  reducers: {
    savePPFamily(state, action) {
      return {
        ...state,
        ppFamilyList: action.payload
          ?.sort?.(sortByStr('family'))
          ?.map?.(i => ({ value: i.family })),
      };
    },
  },
};
