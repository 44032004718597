import { fetchAssetsPixel, fetchAssetsPixelTrend } from '@/services/api';

export default {
  namespace: 'pixel',

  state: {
    list: [],
    totalList: {},
    totalCount: 0,
    dataDetail: {
      data: [],
      trendData: [],
    },
  },

  effects: {
    *getAssetsPixel({ payload }, { call, put }) {
      const response = yield call(fetchAssetsPixel, payload);
      yield put({
        type: 'saveAssetsPixel',
        payload: response,
      });
    },
    *getAssetsPixelTrend({ payload }, { call, put }) {
      const response = yield call(fetchAssetsPixelTrend, payload);
      yield put({
        type: 'saveAssetsPixelTrend',
        payload: response,
      });
    },
  },
  reducers: {
    saveAssetsPixel(state, action) {
      const { data = [], total = [], meta } = action.payload || {};
      return {
        ...state,
        list: data,
        totalList: total,
        totalCount: meta?.total || 0,
      };
    },
    saveAssetsPixelTrend(state, action) {
      const {
        data: { info = [], trend = [] },
      } = action.payload || {};
      return {
        ...state,
        dataDetail: {
          data: info,
          trendData: trend,
        },
      };
    },
  },
};
