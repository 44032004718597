import { fetchUnshippedOrders } from '@/services/api';

export default {
  namespace: 'unshipped',
  state: {
    data: [],
    total: 0,
    unfulfilledSum: 0,
  },
  effects: {
    *getUnshippedOrders({ payload }, { call, put }) {
      const response = yield call(fetchUnshippedOrders, payload);
      yield put({
        type: 'saveUnshippedOrders',
        payload: response,
      });
    },
  },
  reducers: {
    saveUnshippedOrders(state, { payload }) {
      const { meta = {}, data, unfulfilledSum } = payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
        unfulfilledSum,
      };
    },
  },
};
