import {
  fetchThirdCreditCardList,
  fetchFourthCreditCardList,
  fetchCreaditCardEntity,
  fetchKlarnaOceanPayConfigList,
  fetchKlarnaSproutPayConfigList,
} from '@/services/payments';
import { fetchAlreadyBindGatewayUrlList, fetchAlreadyBindConfigNameList } from '@/services/stores';

export default {
  namespace: 'wsAccount',
  state: {
    wsList: [],
    srList: [],
    entityList: [],
    gatewayUrl: [],
    configName: [],
    oceanPayConfigList: [],
    sproutPayConfigList: [],
  },

  effects: {
    *getWsList({ payload }, { call, put }) {
      const response = yield call(fetchThirdCreditCardList, payload);
      yield put({
        type: 'saveWsList',
        payload: response,
      });
      return { wsList: response?.data };
    },
    *getSrList({ payload }, { call, put }) {
      const response = yield call(fetchFourthCreditCardList, payload);
      yield put({
        type: 'saveSrList',
        payload: response,
      });
      return { srList: response?.data };
    },
    *getEntityList({ payload }, { call, put }) {
      const response = yield call(fetchCreaditCardEntity, payload);
      yield put({
        type: 'saveEntityList',
        payload: response,
      });
      return { entityList: response?.data };
    },
    *getGatewayUrlList({ payload }, { call, put }) {
      const response = yield call(fetchAlreadyBindGatewayUrlList, payload);
      yield put({
        type: 'saveGatewayUrlList',
        payload: response,
      });
      return { gatewayUrl: response?.data?.list };
    },
    *getConfigNameList({ payload }, { call, put }) {
      const response = yield call(fetchAlreadyBindConfigNameList, payload);
      yield put({
        type: 'saveConfigNameList',
        payload: response,
      });
      return { configName: response?.data?.list };
    },
    *getOceanPayConfigList({ payload }, { call, put }) {
      const response = yield call(fetchKlarnaOceanPayConfigList, payload);
      yield put({
        type: 'saveOceanPayConfigList',
        payload: response,
      });
    },
    *getSproutPayConfigList({ payload }, { call, put }) {
      const response = yield call(fetchKlarnaSproutPayConfigList, payload);
      yield put({
        type: 'saveSproutPayConfigList',
        payload: response,
      });
    },
  },
  reducers: {
    saveWsList(state, action) {
      const { data = [] } = action.payload;
      return {
        ...state,
        wsList: data,
      };
    },
    saveSrList(state, action) {
      const { data = [] } = action.payload;
      return {
        ...state,
        srList: data,
      };
    },
    saveEntityList(state, action) {
      const { data = [] } = action.payload;
      return {
        ...state,
        entityList: data,
      };
    },
    saveGatewayUrlList(state, action) {
      return {
        ...state,
        gatewayUrl: action.payload?.data?.list || [],
      };
    },
    saveConfigNameList(state, action) {
      return {
        ...state,
        configName: action.payload?.data?.list || [],
      };
    },
    saveOceanPayConfigList(state, action) {
      return {
        ...state,
        oceanPayConfigList: action.payload?.data || [],
      };
    },
    saveSproutPayConfigList(state, action) {
      return {
        ...state,
        sproutPayConfigList: action.payload?.data || [],
      };
    },
  },
};
