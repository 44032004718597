import {
  addAdAccount,
  addAdPage,
  deleteAdPage,
  fetchActidnameList,
  fetchAdAccountOptions,
  fetchAdAccounts,
  fetchAssets,
  fetchPartConfig,
  fetchStoreDomain,
  fetchThirdDomain,
  fetchVpsList,
  updateAdAccount,
  fetchAdPeopleLog,
  changePersonAccount,
  fetchProxyLogs,
  fetchBiCountries,
} from '@/services/api';

export default {
  namespace: 'account',

  state: {
    accounts: [],
    accountOptions: {},
    thirdDomains: [],
    storeDomains: [],
    actIdNameList: {},
    partConfig: {
      meta: {},
      data: [],
    },
    assets: {
      meta: {},
      data: [],
    },
    vpsList: [],
    allVpsList: [],
    adPeopleLog: [],
    proxyLogs: {
      data: [],
      meta: {},
    },
  },

  effects: {
    *getAccounts({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdAccounts, payload);
      yield put({
        type: 'saveAccounts',
        payload: response,
      });
      if (callback) callback();
    },
    *getAccountOptions({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdAccountOptions, payload);
      yield put({
        type: 'saveAccountOptions',
        payload: response?.part_list ? response : {},
      });
      if (callback) callback();
    },
    *getActIdNameList({ payload, callback }, { call, put }) {
      const response = yield call(fetchActidnameList, payload);
      yield put({
        type: 'saveActIdNameList',
        payload: { response, type: payload.type },
      });
      if (callback) callback();
    },
    *updateAdAccount({ payload, callback }, { call }) {
      const response = yield call(updateAdAccount, payload);
      if (callback) callback(response);
    },
    *updatePersonAccount({ payload, callback }, { call }) {
      const response = yield call(changePersonAccount, payload);
      if (callback) callback(response);
    },
    *addAdAccount({ payload, callback }, { call }) {
      const response = yield call(addAdAccount, payload);
      if (callback) callback(response);
    },
    *addAdPage({ payload, callback }, { call }) {
      const response = yield call(addAdPage, payload);
      if (callback) callback(response);
    },
    *deleteAdPage({ payload, callback }, { call }) {
      const response = yield call(deleteAdPage, payload);
      if (callback) callback(response);
    },
    *getPartConfig({ payload, callback }, { call, put }) {
      const response = yield call(fetchPartConfig, payload);
      yield put({
        type: 'savePartConfig',
        payload: response,
      });
      if (callback) callback();
    },
    *getAssets({ payload, callback }, { call, put }) {
      const response = yield call(fetchAssets, payload);
      yield put({
        type: 'saveAssets',
        payload: response,
      });
      if (callback) callback();
    },
    *getThirdDomain({ payload }, { call, put }) {
      const response = yield call(fetchThirdDomain, payload);
      yield put({
        type: 'saveThirdDomain',
        payload: response,
      });
    },
    *getStoreDomain(_, { call, put }) {
      const response = yield call(fetchStoreDomain);
      yield put({
        type: 'saveStoreDomain',
        payload: response,
      });
    },
    *getVpsList({ payload }, { call, put }) {
      const response = yield call(fetchVpsList, payload);
      yield put({
        type: 'saveVpsList',
        payload: response,
      });
    },
    *getAllVpsList(_, { call, put }) {
      const response = yield call(fetchVpsList);
      yield put({
        type: 'saveAllVpsList',
        payload: response,
      });
    },
    *getAdPeopleLog({ payload, callback }, { call, put }) {
      const response = yield call(fetchAdPeopleLog, payload);
      yield put({
        type: 'saveAdPeopleLog',
        payload: response,
      });
      if (callback) callback();
    },
    *getProxyLogs({ payload, callback }, { call, put }) {
      const response = yield call(fetchProxyLogs, payload);
      yield put({
        type: 'saveProxyLogs',
        payload: response,
      });
      if (callback) callback();
    },
    *getBiCountries({ payload, callback }, { call, put }) {
      const response = yield call(fetchBiCountries, payload);
      yield put({
        type: 'saveBiCountries',
        payload: response,
      });
      if (callback) callback();
    },
  },
  reducers: {
    saveAccounts(state, action) {
      const data = action.payload;
      return {
        ...state,
        accounts: data,
      };
    },
    saveAccountOptions(state, action) {
      const data = action.payload;
      return {
        ...state,
        accountOptions: data,
      };
    },
    saveActIdNameList(state, action) {
      const { response, type } = action.payload;
      return {
        ...state,
        actIdNameList: { type, data: response },
      };
    },
    savePartConfig(state, action) {
      return {
        ...state,
        partConfig: action.payload || {
          meta: {},
          data: [],
        },
      };
    },
    saveAssets(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        assets: {
          data,
          meta,
        },
      };
    },
    saveThirdDomain(state, action) {
      return {
        ...state,
        thirdDomains: action.payload && Array.isArray(action.payload) ? action.payload : [],
      };
    },
    saveStoreDomain(state, action) {
      return {
        ...state,
        storeDomains: action.payload && Array.isArray(action.payload) ? action.payload : [],
      };
    },
    saveVpsList(state, action) {
      const { data } = action.payload || {};
      return {
        ...state,
        vpsList: data,
      };
    },
    saveAllVpsList(state, action) {
      const { data } = action.payload || {};
      return {
        ...state,
        allVpsList: data,
      };
    },
    saveAdPeopleLog(state, action) {
      const { data = [] } = action.payload || {};
      return {
        ...state,
        adPeopleLog: data,
      };
    },
    saveProxyLogs(state, action) {
      const { data = [], meta = {} } = action.payload || {};
      return {
        ...state,
        proxyLogs: {
          data,
          meta,
        },
      };
    },
    clearProxyLogs(state) {
      return {
        ...state,
        proxyLogs: {
          data: [],
          meta: {},
        },
      };
    },
    saveBiCountries(state, action) {
      return {
        ...state,
        countriesList: action?.payload || [],
      };
    },
  },
};
