export default {
  namespace: 'commons',
  state: {
    selectPartId: {},
    selectPeopleId: {},
  },

  reducers: {
    saveCurrentSelectPartId(state, action) {
      return {
        ...state,
        selectPartId: { ...state?.selectPartId, ...action.payload },
      };
    },
    saveCurrentSelectPeopleId(state, action) {
      return {
        ...state,
        selectPeopleId: { ...state?.selectPeopleId, ...action.payload },
      };
    },
  },
};
