import {
  fetchGooleAds,
  fetchAccountName,
  fetchGooglePeopleEmails,
  fetchRemoteAdAccounts,
} from '@/services/api';

export default {
  namespace: 'gooleAds',
  state: {
    data: [],
    total: 0,
    accountName: [],
    googlePeopleEmails: [],
    googleAdAccounts: [],
  },

  effects: {
    *getGooleAds({ payload }, { call, put }) {
      const response = yield call(fetchGooleAds, payload);
      yield put({
        type: 'saveGooleAds',
        payload: response,
      });
    },
    *getAccountName({ payload }, { call, put }) {
      const response = yield call(fetchAccountName, payload);
      yield put({
        type: 'saveAccountName',
        payload: response,
      });
    },
    *getGooglePeopleEmails({ payload }, { call, put }) {
      const response = yield call(fetchGooglePeopleEmails, payload);
      yield put({
        type: 'saveGooglePeopleEmails',
        payload: response,
      });
    },
    *getRemoteAdAccounts({ payload }, { call, put }) {
      const response = yield call(fetchRemoteAdAccounts, payload);
      yield put({
        type: 'saveRemoteAdAccounts',
        payload: response,
      });
    },
  },
  reducers: {
    saveGooleAds(state, action) {
      const { data, meta } = action.payload;
      return {
        ...state,
        data,
        total: meta?.total || 0,
      };
    },
    saveAccountName(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        accountName: data,
      };
    },
    saveGooglePeopleEmails(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        googlePeopleEmails: data,
      };
    },
    saveRemoteAdAccounts(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        googleAdAccounts: data || [],
      };
    },
  },
};
