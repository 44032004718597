import { fetchTSkuCustomized } from '@/services/api';

export default {
  namespace: 'tSkuCustomized',

  state: {
    data: [],
  },

  effects: {
    *getTSkuCustomized({ payload, callback }, { call, put }) {
      const response = yield call(fetchTSkuCustomized, payload);
      yield put({
        type: 'saveTSkuCustomized',
        payload: response,
      });
      if (callback) callback(response);
    },
  },
  reducers: {
    saveTSkuCustomized(state, action) {
      const { data = [] } = action.payload || {};
      return {
        ...state,
        data,
      };
    },
  },
};
